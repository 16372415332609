import React from "react";
import Select2 from "react-select2-wrapper";

export default function EditMode({ ...props }) {
    return (
        <div
            className="modal modal-metric fade"
            id="addMetric"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addMetric"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container-fluid">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            />
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="mb-4 font-weight-700">Add Metric</h5>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-5 align-self-center">
                                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                                        Metric
                                    </p>
                                </div>
                                <div className="col-sm-7 pl-sm-0">
                                    <div className="section z-bigger-20">
                                        <Select2
                                            className="input-select-style no-search bigger-text"
                                            data-placeholder="Select Metric"
                                            data-allow-clear="1"
                                            data={props.getWidgetTypesForAdd()}
                                            defaultValue={props.singleWidgetTypeId}
                                            options={{
                                                placeholder: "Select a widget type",
                                                language: {
                                                    noResults: function () {
                                                        return "No additional metrics available";
                                                    }
                                                },
                                                theme: "bootstrap4",
                                                minimumResultsForSearch: -1,
                                            }}
                                            onChange={(event) =>
                                                props.setSingleWidgetTypeId(event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-5">
                                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                                        Type
                                    </p>
                                </div>
                                <div className="col-sm-7 pl-sm-0 img-wrap">
                                    <div className="form-group mb-2">
                                        <input
                                            type="radio"
                                            id="type-1"
                                            checked={!props.singleWidgetIsExpanded}
                                            name="type"
                                            onChange={() => props.setSingleWidgetIsExpanded(false)}
                                        />
                                        <label
                                            className="radio radio-gray mb-0"
                                            htmlFor="type-1"
                                        >
                                            Show the current value
                                        </label>
                                    </div>
                                    <img src={require("../../../img/type-1.svg")} alt="type-1" />
                                    <div className="form-group mb-2 mt-2 pt-1">
                                        <input
                                            type="radio"
                                            id="type-2"
                                            name="type"
                                            checked={props.singleWidgetIsExpanded}
                                            onChange={() => props.setSingleWidgetIsExpanded(true)}
                                        />
                                        <label
                                            className="radio radio-gray mb-0"
                                            htmlFor="type-2"
                                        >
                                            Show the current value + mini chart
                                        </label>
                                    </div>
                                    <img src={require("../../../img/type-2.svg")} alt="type-2" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-5">
                                    <p className="mb-2 mb-sm-0 size-14 font-weight-700 pt-fix-12">
                                        Date range
                                    </p>
                                </div>
                                <div className="col-sm-7 pl-sm-0">
                                    <div className="row">
                                        <div className="col-auto pr-2 align-self-center">
                                            <p className="mb-0 size-14">Previous</p>
                                        </div>
                                        <div className="col-auto px-0 align-self-center">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    name="number-period"
                                                    id="number-period"
                                                    className="form-style number-box"
                                                    htmlFor="1"
                                                    min="1"
                                                    max="99"
                                                    value={props.singleWidgetLastPeriodRange}
                                                    onChange={(event) =>
                                                        props.setSingleWidgetLastPeriodRange(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col pl-1">
                                            <div className="section z-bigger-20">
                                                <Select2
                                                    className="input-select-style no-search bigger-text"
                                                    data-placeholder="period"
                                                    data-allow-clear="1"
                                                    data={[
                                                        { id: 1, text: "Days" },
                                                        { id: 2, text: "Weeks" },
                                                        { id: 3, text: "Months" },
                                                        { id: 4, text: "Quarters" },
                                                        { id: 5, text: "Years" },
                                                    ]}
                                                    value={props.singleWidgetPeriodType || null}
                                                    options={{
                                                        placeholder: "period",
                                                        theme: "bootstrap4",
                                                        minimumResultsForSearch: -1,
                                                    }}
                                                    onChange={(event) => {
                                                        props.setSingleWidgetPeriodType(event.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-sm-5">
                                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                                        Chart type
                                    </p>
                                </div>
                                <div className="col-sm-7 pl-sm-0">
                                    <div className="htmlForm-group">
                                        <input
                                            type="radio"
                                            id="charttype-1"
                                            name="charttype"
                                            checked={props.singleWidgetIsLineChart}
                                            onChange={() => props.setSingleWidgetIsLineChart(true)}
                                        />
                                        <label
                                            className="radio radio-gray mb-0"
                                            htmlFor="charttype-1"
                                        >
                                            Line
                                        </label>
                                    </div>
                                    <div className="htmlForm-group mt-2">
                                        <input
                                            type="radio"
                                            id="charttype-2"
                                            name="charttype"
                                            checked={!props.singleWidgetIsLineChart}
                                            onChange={() => props.setSingleWidgetIsLineChart(false)}
                                        />
                                        <label
                                            className="radio radio-gray mb-0"
                                            htmlFor="charttype-2"
                                        >
                                            Bar
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-sm-5">
                                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">Show Axis Labels</p>
                                </div>
                                <div className="col-sm-3 pl-sm-0">
                                    <div className="htmlForm-group">
                                        <input
                                            type="checkbox"
                                            id="chartxlabels"
                                            name="chartlabels-edit-x"
                                            checked={props.showXLabels}
                                            onChange={() => props.setShowXLabels(!props.showXLabels)}
                                        />
                                        <label className="checkbox checkbox-gray mb-0" htmlFor="chartxlabels">
                                            X-Axis
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-3 pl-sm-0">
                                    <div className="htmlForm-group">
                                        <input
                                            type="checkbox"
                                            id="chartylabels"
                                            name="chartlabels-edit-y"
                                            checked={props.showYLabels}
                                            onChange={() => props.setShowYLabels(!props.showYLabels)}
                                        />
                                        <label className="checkbox checkbox-gray mb-0" htmlFor="chartylabels">
                                            Y-Axis
                                        </label>
                                    </div>
                                </div>
                            </div>                           
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                        onClick={() => props.clearSingleWidgetFields()}
                                    >
                                        CANCEL
                                    </button>
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        disabled={props.saveDisabled()}
                                        onClick={() => props.saveSingleWidget()}
                                        className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                    >
                                        ADD METRIC
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
