import React, { Component } from "react";

export default class Error extends Component {
    constructor(props) {
      super(props);
      this.state = {
        errorMessage : props.errorMessage
      };
    }
  
    render() {
      const errorMessage = this.state.errorMessage;
      return (
        <div style={{textAlign: "center"  }}>
          <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      </div>
      );
    }
  }
  