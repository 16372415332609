import React, { useEffect, useState } from "react";
import { getCurrentUserSecretKey } from "../authentification";

const AppointmentDirect = (props) => {

  const [currentUserSecretKey] = useState(getCurrentUserSecretKey());

  useEffect(() => {
    const globalSpinner = document.getElementById("globalSpinner");
    globalSpinner.style.display = "none";
  });

  return (
    <iframe
      title="AppointmentDirect"
      src={"https://scheduledev.getkontak.com?secretKey=" + currentUserSecretKey}
      //src={"https://scheduleprod.getkontak.com?secretKey=" + currentUserSecretKey} 
      width="100%"
      style={{ marginTop: "50px", paddingLeft: "58px", height: "calc(100vh - 60px)" }}>
    </iframe>
  );
};

export default AppointmentDirect;
