import Widget from "../widget.component";
import React from "react";
import { ajaxCaller } from "../../../ajaxCaller";
import { getAccessToken } from "../../../authentification";

export default function ChartsArea({ ...props }) {
    const removeWidget = (id) => {
        ajaxCaller("Widget/" + id, {
            method: "delete",
            headers: new Headers({
                Authorization: "Bearer " + getAccessToken(),
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        }).then((response) => {
            if (!response.isError) {
                let removedElement = document.getElementById("widget_" + id);
                removedElement.style.display = "none";
            } else {
            }
        });
    };

    return (
        <div className="row pt-2 mt-1" >
            <div
                id="leftContainer"
                className="col-lg-6 edit-box-column-1"
            >
                {props.allWidgets && props.allWidgets.filter(x => x.columnIndex !== 1).map((item, index) =>
                    <Widget
                        key={index}
                        editMode={props.editMode}
                        selectForEdit={() => props.selectForEdit(item)}
                        removeWidget={() => removeWidget(item.id)}
                        withTrendLine={props.withTrendLine}
                        withAfterHours={props.withAfterHours}
                        withAfterHoursDiff={props.withAfterHoursDiff}
                        showPEPeriod={props.showPEPeriod}
                        widget={item}
                        groupIds={props.groupIds}
                        changePercentage={Math.random().toFixed(2) * 100}
                        totalValue={Math.random().toFixed(1) * 100}
                        allWidgets = {props.allWidgets}
                        widgetTypes = {props.widgetTypes}
                        showAdSeries={props.showAdSeries}
                    />
                )}
            </div>
            <div
                id="rightContainer"
                className="col-lg-6 edit-box-column-2"
            >
                {props.allWidgets && props.allWidgets.filter(x => x.columnIndex !== 0).map((item, index) =>
                    <Widget
                        key={index}
                        editMode={props.editMode}
                        selectForEdit={() => props.selectForEdit(item)}
                        removeWidget={() => removeWidget(item.id)}
                        withTrendLine={props.withTrendLine}
                        withAfterHours={props.withAfterHours}
                        withAfterHoursDiff={props.withAfterHoursDiff}
                        showPEPeriod={props.showPEPeriod}
                        widget={item}
                        groupIds={props.groupIds}
                        changePercentage={Math.random().toFixed(2) * 100}
                        totalValue={Math.random().toFixed(1) * 100}
                        allWidgets = {props.allWidgets}
                        widgetTypes = {props.widgetTypes}
                        showAdSeries={props.showAdSeries}
                    />
                )}
            </div>
        </div>
    );
}