// Default to production for safety.
import * as env from './env'
import _ from 'lodash'

let apiUrltemp = "https://kontakprod.azurewebsites.net/api/";

// USE URL BY DEFAULT
let localPath = window.location.host;

// USE SESSION SETTING
if (!_.isNil(sessionStorage.getItem('apiLocalPath'))) {
    localPath = sessionStorage.getItem('apiLocalPath')
}

if (localPath === env.local) {
    apiUrltemp = "https://localhost:5001/api/";
}

if (localPath === env.dev || localPath === "kontakuidev.azurewebsites.net") {
    apiUrltemp = "https://kontakdev.azurewebsites.net/api/";
}

if (localPath === env.staging) {
    apiUrltemp = "https://kontakprod-stage.azurewebsites.net/api/";
}

if (localPath === env.staging2) {
    apiUrltemp = "https://kontakprod-stage2.azurewebsites.net/api/";
}

// runtime configuration...
let apiUrl = window.API_URL || apiUrltemp

export const imageContainerUrl = "https://kontakstorage.blob.core.windows.net/"; // TODO: Add this blob to azure for the future images

export {
    apiUrl
};

