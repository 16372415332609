import { apiUrl } from "./apiServer";
import { getAccessToken } from "./authentification";

export const ajaxCaller = (url, parameters) => {
  return ajaxNonJsonCaller(url, parameters).then(response => {
    return response.json();
  });
};

export const ajaxNonJsonCaller = (url, parameters) => {
  const globalSpinner = document.getElementById("globalSpinner");
  if (globalSpinner != null) {
    globalSpinner.style.display = "block";
  }

  return fetch(apiUrl + url, parameters).then(response => {
    if (globalSpinner != null) {
      globalSpinner.style.display = "none";
    }
    return response;
  });
};

export const authHeaders = new Headers({
  Authorization: "Bearer " + getAccessToken(),
  "Content-Type": "application/json",
  Accept: "application/json"
});

export const authHeadersForFiles = new Headers({
  Authorization: "Bearer " + getAccessToken()
}
);
