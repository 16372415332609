/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../authentification";
import Select2 from "react-select2-wrapper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SettingsPageNavBar from "./components/settingsPageNavBar";

const Locations = (props) => {
  const [newGroupRegionId, setNewGroupRegionId] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupLVCId, setNewGroupLVCId] = useState("");
  const [isKontakAdminHere] = useState(isKontakAdmin());
  const clearFields = () => {
    setNewGroupName("");
    setNewGroupRegionId(null);
    setNewGroupLVCId("");
  }
  const createGroup = () => {
    const createGroupJson = {
      name: newGroupName,
      liveChatId: newGroupLVCId,
      regionId: newGroupRegionId
    };

    ajaxCaller("Group/InsertGroup", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "post",
      body: JSON.stringify(createGroupJson),
    }).then((response) => {
      if (!response.isError) {
        clearFields();
        setGroups((prev) => [...prev].concat(response));
      } else {
      }
    });
  };

  const [typeformId, setTypeformId] = useState(null);
  const [companyKey, setCompanyKey] = useState(null);
  const getCompanyByGroupId = (groupId) => {
    ajaxCaller("Company/GetCompanyByGroup/" + groupId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      })
    }).then(company => {
      setCompanyKey(company.companyKey && company.companyKey)
      setTypeformId(company.companyUnion && company.companyUnion.typeformId)

    });
  }

  const [groups, setGroups] = useState(null);
  const getGroups = () => {
    ajaxCaller("Group/GetGroupsRet/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      console.log('GetGroups response');
      console.log(response);
      if (!response.isError) {
        if (response && response.length >= 1) {
          setGroups(response);
          getCompanyByGroupId(response && response[0].id)
        }
        else {
          console.log('no errors');
          setGroups([]);
        }
      }
    });
  };

  const [regions, setRegions] = useState(null);
  const getRegions = () => {
    ajaxCaller("Region/GetRegions", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((regions) => {
      if (regions.isError) {
      } else {
        setRegions(regions);
      }
    });
  };
  useEffect(() => {
    getRegions();
  }, []);

  const getGroupsCallback = () => {
    if (groups === null) {
      getGroups();
    }
  }
  useEffect(getGroupsCallback, [groups]);


  const removeGroup = (id) => {
    ajaxCaller("Group/Remove/" + id, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "delete",
    }).then((response) => {
      if (!response.isError) {
        getGroups();
      } else {
      }
    });
  }

  const [appointmentsPercentage, setAppointmentsPercentage] = useState("");
  const [averageClientValue, setAverageClientValue] = useState("");
  const changeClientValues = (groupId) => {
    ajaxCaller("Group/ChangeClientValues/" + groupId, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "put",
      body: JSON.stringify({
        appointmentsPercentage: appointmentsPercentage,
        averageClientValue: averageClientValue
      }),
    }).then((response) => {
      if (!response.isError) {
        getGroups();
      }
      else {
      }
    });
  }



  return (
    <>
      <SettingsPageNavBar pageName="locations" />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">Locations</h5>
                </div>
              </div>
              {
                isKontakAdminHere && (<div className="row">
                  <div className="col-12">
                    <div className="section background-white p-3 p-lg-4">
                      <div className="row">
                        <div className="col-md-auto align-self-center">
                          <p className="mb-2 mb-md-0 font-weight-500">
                            Add a new location
                        </p>
                        </div>
                        <div className="col-md-4 col-xl-3 mb-3 mb-md-0 align-self-center pr-md-0">
                          <div className="form-group">
                            <input
                              type="text"
                              value={newGroupName}
                              onChange={(event) =>
                                setNewGroupName(event.target.value)
                              }
                              className="form-style border-0 small-40"
                              placeholder="Name your location..."
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-3 mb-3 mb-md-0 align-self-center pr-md-0">
                          <div className="form-group">
                            <input
                              type="number"
                              value={newGroupLVCId}
                              onChange={(event) =>
                                setNewGroupLVCId(event.target.value)
                              }
                              className="form-style border-0 small-40"
                              placeholder="LivechatId for your location"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-3 mb-3 mb-md-0 align-self-center pr-md-0 pl-md-2">
                          <div className="section z-bigger-20 height-40-style light-style">
                            {regions && (
                              <Select2
                                className="input-select-style no-search bigger-text show-category-details"
                                data={
                                  regions &&
                                  regions.map((x) => ({ id: x.id, text: x.name }))
                                }
                                defaultValue={newGroupRegionId}
                                options={{
                                  placeholder: "Select a region",
                                  theme: "bootstrap4",
                                  minimumResultsForSearch: -1,
                                }}
                                onChange={(event) =>
                                  setNewGroupRegionId(event.target.value)
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-auto align-self-center pl-md-2">
                          <button
                            onClick={() => createGroup()}
                            className="btn btn-blue btn-width-80 btn-height-40 border-0 font-size-16"
                          >
                            Add
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
              }

              <div className="row mt-2 pt-1">
                <div className="col-12 chat-archive-table">
                  <div className="section background-white">
                    <div className="row py-2">
                      <div className="col align-self-center">
                        <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                          Locations
                        </p>
                      </div>
                      <div className="col-auto">
                        {/* <span
                          className="link-download font-size-13 mr-2 pr-1"
                        >
                          <img
                            src={require("../img/download.svg")}
                            alt=""
                            className="mr-1"
                          />
                          Export (CSV)
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className={isKontakAdminHere ? "col-lg-3 pr-0" : "col-lg-3 pr-0"}>
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Location name
                          </p>
                        </div>
                      </div>
                      {isKontakAdminHere && (<div className="col-xl-3 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Typeform URL
                          </p>
                        </div>
                      </div>)}
                      <div className={isKontakAdminHere ? "col-2 px-0" : "col-2 px-0"}>
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Region
                          </p>
                        </div>
                      </div>
                      <div className={isKontakAdminHere ? "col-lg-1 px-0" : "col-lg-3 px-0"}>
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">New appt. requests converted to appt.</p>
                        </div>
                      </div>
                      <div className={isKontakAdminHere ? "col-lg-1 px-0" : "col-lg-2 px-0"}>
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Average CLV</p>
                        </div>
                      </div>
                      {/* <div className="col-2 px-0">	
											<div className="profile-box-blue profile-box-blue-bor-right justify-content-center">	
												<p className="mb-0 font-size-14 font-weight-500">Client Value</p>
											</div>
										</div> */}
                    </div>
                  </div>
                </div>
                <div id="container" className="col-12 profile-category-wrap-move">
                  {groups &&
                    groups.map((group) => (
                      <div key={group.id}>
                        <div className="modal fade" id="modalClientValue" tabIndex="-1" role="dialog" aria-labelledby="modalClientValue" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body py-3">
                                <div className="container-fluid">
                                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                                  <div className="row">
                                    <div className="col-12">
                                      <h5 className="mb-4 font-weight-700">
                                        Client Value
									</h5>
                                    </div>
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label className="mb-1 font-weight-700">
                                          % of new appt. requests converted to appt.
										</label>
                                        <input type="text" onChange={(event) => setAppointmentsPercentage(event.target.value)} className="form-style border-0 small-40" placeholder="% of new appt. requests converted to appt..." autoComplete="off" value={appointmentsPercentage} />
                                      </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <div className="form-group">
                                        <label className="mb-1 font-weight-700">
                                          Average client lifetime value (CLV)
										</label>
                                        <input type="text" onChange={(event) => setAverageClientValue(event.target.value)} className="form-style border-0 small-40" placeholder="Average client lifetime value (CLV)..." autoComplete="off" value={averageClientValue} />
                                      </div>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1">CANCEL</button>
                                      <button type="button" data-dismiss="modal" aria-label="Close" onClick={() => changeClientValues(group.id)} className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1">SAVE</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id={"modalDelete_" + group.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalDelete"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                      <h5 className="mb-2 pb-1">Delete location</h5>
                                    </div>
                                    <div className="col-12 text-center">
                                      <p className="mb-0">
                                        You are about to delete a location:{" "}
                                        <strong>{group.name}</strong>
                                      </p>
                                      <p className="mb-0">
                                        Are you sure you wish to continue?
                                        </p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                      >
                                        CANCEL
                                        </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => removeGroup(group.id)}
                                        className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                      >
                                        DELETE
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={"group_" + group.id}
                          style={{cursor:"auto"}}
                          className="section profile-category-wrap"
                        >
                          <div className="row">
                            <div className={isKontakAdminHere ? "col-lg-3 pr-xl-0" : "col-lg-3 pr-xl-0"}>
                              <div className="profile-box-white profile-box-white-bor-right" >
                                <p className="mb-0 font-size-15">
                                  {
                                    group.name + 
                                   (Boolean(group.hasCalls) === true ? " " + "\u{1f4f1}" : "") +
                                   (Boolean(group.hasLiveChats) === true ? " " + "\u{2328}" : "") +
                                   (Boolean(group.isUncategorized) === true ? " " + "\u{2753}": "")
                                  }
                                </p>
                              </div>
                            </div>
                            {isKontakAdminHere && (<div className="col-xl-3 pr-xl-0">
                              <div style={{ overflowWrap: "anywhere" }} className="profile-box-white profile-box-white-bor-right">
                                <CopyToClipboard
                                  text={
                                    "https://profileskontak.typeform.com/to/" + typeformId + "#companykey=" + companyKey + "&groupkey=" + group.groupKey
                                  }
                                >
                                  <button
                                    type="submit"
                                    style={{ cursor: "pointer", marginRight: "5px" }}
                                    className="btn btn-regions "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-trigger="hover"
                                    title="Copy"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      width="18px"
                                      height="18px"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                    </svg>
                                  </button>
                                </CopyToClipboard>
                                {/* <p className="mb-0 font-size-15">{"https://profileskontak.typeform.com/to/" + typeformId + "#companykey=" + companyKey + "&groupkey=" + group.groupKey}</p> */}
                                <p className="mb-0 font-size-15">{"https://profileskontak.typeform.com/to/..."}</p>

                              </div>
                            </div>)}
                            <div className={isKontakAdminHere ? "col-2 px-xl-0" : "col-xl-2 px-xl-0"}>
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15">
                                  {regions && (() => {
                                    const region = regions.find((region) => region.id === group.regionId);
                                    return region ? region.name : "Unassigned";
                                  })()}
                                </p>
                              </div>
                            </div>
                            {/* <div className="col-2 px-xl-0">	
											<div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">	
												<p className="mb-0 font-size-15 mr-3">{group.appointmentsPercentage} %  / {group.averageClientValue} $</p>	
												<button className="btn btn-blue btn-box-36 border-0" onClick={() => {
                          setAppointmentsPercentage(group.appointmentsPercentage);
                          setAverageClientValue(group.averageClientValue);
                        }} data-toggle="modal" data-target="#modalClientValue">
													<img src={require("../img/edit.svg")} alt=""/>
												</button>
											</div>
										</div> */}
                            <div className={isKontakAdminHere ? "col-xl-1 px-xl-0" : "col-xl-3 px-xl-0"}>
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15 mr-3">{group.appointmentsPercentage}%</p>
                              </div>
                            </div>
                            <div className={isKontakAdminHere ? "col-xl-1 px-xl-0" : "col-xl-2 px-xl-0"}>
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15 mr-3">${group.averageClientValue}</p>
                              </div>
                            </div>
                            <div className="col-2 pl-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-end">
                                <a href={"/settings/locations/" + group.id} className="btn btn-blue btn-width-80 btn-height-36 border-0 font-size-14 mr-1 mt-2 mt-xl-0 animsition-link">Edit</a>
                                {isKontakAdminHere && (
                                  <button type="button"

                                    data-toggle="modal"
                                    data-target={"#modalDelete_" + group.id}
                                    className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0">
                                    <img src={require("../img/bin.svg")} alt="" />
                                  </button>)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
