import React from "react";
import { ajaxNonJsonCaller, authHeaders } from "../../ajaxCaller";
import { isKontakAdmin } from "../../authentification";

const DashboardReports = (props) => {
  const dowloadChatSummary = () => {
    ajaxNonJsonCaller("Excel/ExportSummary", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify({
        dateFrom: props.startDate,
        dateTo: props.endDate,
        groupIds: props.groupIds,
      }),
    }).then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "SummaryReport.xlsx";
          a.click();
        });
      }
    });
  };

  const downloadThreadReport = () => {
    ajaxNonJsonCaller("Excel/ExportThreadReport", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify({
        dateFrom: props.startDate,
        dateTo: props.endDate
      }),
    }).then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Thread Report.xlsx";
          a.click();
        });
      }
    });
  };

  return (
    <>
      <div onClick={() => dowloadChatSummary()}>
        <div className="card mb-3">
          <div className="card-header" id="headingOne-1">
            <h5
              className="accordion-link download sub-menu mb-0"
            >
              Chat Summary Report
                </h5>
          </div>
        </div>
      </div>
      {isKontakAdmin() && (<div onClick={() => downloadThreadReport()}>
        <div className="card mb-3">
          <div className="card-header" id="headingOne-1">
            <h5
              className="accordion-link download sub-menu mb-0"
            >
              Thread Report
                </h5>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default DashboardReports;
