import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import SettingsPageNavBar from "./components/settingsPageNavBar";
import $ from "jquery";
import ErrorModal from "./components/errorModal";

const Regions = (props) => {
  const [regions, setRegions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const getRegions = () => {
    clearError();
    ajaxCaller("Region/GetRegionsWithGroupCount", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((regions) => {
      if (regions.isError) {
      } else {
        setRegions(regions);
      }
    });
  };

  const getRegionsCallback = () => {
    if (regions == null) {
      getRegions();
    }
  }
  useEffect(getRegionsCallback, [regions]);

  const [newRegionName, setNewRegionName] = useState("");
  const createRegion = () => {
    clearError();
    ajaxCaller("Region/AddRegion", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        name: newRegionName,
      }),
    }).then((region) => {
      if (!region.isError) {
        setNewRegionName("");
        setRegions((prev) => [...prev].concat(region));
      }
      else {
        setNewRegionName("");
        setErrorMessage(region.message);
      }
    });
  };

  const [editingRegionName, setEditingRegionName] = useState("");
  const updateRegion = (id) => {
    clearError();
    ajaxCaller("Region/EditRegion", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: id,
        name: editingRegionName,
      }),
    }).then((region) => {
      if (!region.isError) {
        getRegions();
      }
      else {
        setErrorMessage(region.message)
      }
    });
  };

  const clearError = () => {
    setErrorMessage(null)
  }

  const removeRegion = (id) => {
    clearError();
    ajaxCaller("Region/RemoveRegion/" + id, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((region) => {
      if (!region.isError) {
        getRegions();
      }
    });
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  return (
    <>

      <SettingsPageNavBar pageName="regions" />
      <ErrorModal errorMessage={errorMessage} />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">Regions</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-md-auto align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">
                          Add a new region
                        </p>
                      </div>
                      <div className="col-md-5 col-lg-4 col-xl-3 mb-3 mb-md-0 align-self-center pr-md-0">
                        <div className="form-group">
                          <input
                            type="text"
                            value={newRegionName}
                            onChange={(event) =>
                              setNewRegionName(event.target.value)
                            }
                            className="form-style border-0 small-40"
                            placeholder="Name your region..."
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-md-auto align-self-center pl-md-2">
                        <button
                          onClick={() => createRegion()}
                          disabled={newRegionName == null || newRegionName === "" || newRegionName === " "}
                          className="btn btn-blue btn-width-80 btn-height-40 border-0 font-size-16"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 pt-1">
                <div className="col-12 chat-archive-table">
                  <div className="section background-white">
                    <div className="row py-2">
                      <div className="col align-self-center">
                        <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                          Regions
                        </p>
                      </div>
                      <div className="col-auto">
                        {/* <span
                          className="link-download font-size-13 mr-2 pr-1"
                        >
                          <img
                            src={require("../img/download.svg")}
                            alt=""
                            className="mr-1"
                          />
                          Export (CSV)
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className="col-lg-3 pr-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Region name
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-2 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Locations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="container" className="col-12 profile-category-wrap-move">
                  {regions &&
                    regions.map((region) => (
                      <div key={region.id}>
                        <div
                          className="modal fade"
                          id={"modalDelete_" + region.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalDelete"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                      <h5 className="mb-2 pb-1">Delete region</h5>
                                    </div>
                                    <div className="col-12 text-center">
                                      <p className="mb-0">
                                        You are about to delete a region:{" "}
                                        <strong>{region.name}</strong>
                                      </p>
                                      <p className="mb-0">
                                        Are you sure you wish to continue?
                                        </p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                      >
                                        CANCEL
                                        </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => removeRegion(region.id)}
                                        className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                      >
                                        DELETE
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal modal-metric bigger fade"
                          id={"modalEdit_" + region.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalEdit"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                  <div className="row">
                                    <div className="col-12">
                                      <h5 className="mb-4 font-weight-700">
                                        Edit Region
                                    </h5>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-3 align-self-center">
                                      <p className="mb-2 mb-sm-0 font-weight-500">
                                        Name:
                                    </p>
                                    </div>
                                    <div className="col-sm-9">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-style border-0 small-40"
                                          placeholder="Add a question..."
                                          value={editingRegionName}
                                          onChange={(event) => {
                                            setEditingRegionName(
                                              event.target.value
                                            );
                                          }}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                      >
                                        CANCEL
                                    </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                        onClick={() =>
                                          updateRegion(region.id)
                                        }
                                      >
                                        UPDATE
                                    </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={"region_" + region.id}
                          className="section profile-category-wrap"
                          style={{cursor: "auto"}}
                        >
                          <div className="row">
                            <div className="col-xl-3 pr-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right">
                                <p className="mb-0 font-size-15">{region.name}</p>
                              </div>
                            </div>
                            <div className="col-xl-2 px-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                <p className="mb-0 font-size-15 mr-2"><span className="font-weight-500 d-xl-none">Locations: </span> {region.groupCount}</p>
                                <div className="q-mark relative-pos" data-toggle="tooltip" data-offset="0 5" data-placement="top" title={region.groupNames.join(", ")}>?</div>
                              </div>
                            </div>

                            <div className="col-xl-7 pl-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-end">
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target={"#modalEdit_" + region.id}
                                  onClick={() => setEditingRegionName(region.name)}
                                  className="btn btn-blue btn-width-80 btn-height-36 border-0 font-size-14 mr-1 mt-2 mt-xl-0"
                                >
                                  Edit Region
                              </button>
                                <button
                                  type="button"
                                  disabled={region.groupCount > 0}
                                  data-toggle="modal"
                                  data-target={"#modalDelete_" + region.id}
                                  className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0"
                                >
                                  <img src={require("../img/bin.svg")} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regions;
