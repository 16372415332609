export const afterLogin = (response)=>{
    const items = window.location.search.slice(1, window.location.search.length).split("&")
    const params = items.reduce((acc, row)=>{
        const [key, ...args] =row.split("=")
        acc[key]=args.join("=")
        return acc
    }, {})
    
    if(params.redirectURL && (params.redirectURL.match(/(.*)\.lvh\.me:3000$/g) || params.redirectURL.match(/(.*)\.getkontak\.com$/g)).length > 0){
        const form = document.createElement("form")
        form.action=params.redirectURL
        form.method="POST"
        const input = document.createElement("input")
        input.value = JSON.stringify(response)
        input.name="user"
        input.type="hidden"
        form.appendChild(input)
        document.body.appendChild(form)
        form.submit()
        return true
    }
}