import React, { useState, useEffect } from "react";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import { CopyToClipboard } from "react-copy-to-clipboard";
import SettingsPageNavBar from "./components/settingsPageNavBar";


const AdminPanel = (props) => {

  const [companies, setCompanies] = useState(null);
  const getAllCompanies = () => {
    ajaxCaller("Company/GetAllIncludeArchived/", {
      headers: authHeaders,
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setCompanies(response);
      }
    });
  }

  useEffect(() => {
    if (companies == null) {
      getAllCompanies();
    }
  });


  const [companyUnions, setCompanyUnions] = useState(null);
  const getCompanyUnions = () => {
    ajaxCaller("Company/GetCompanyUnions/", {
      headers: authHeaders,
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setCompanyUnions(response);
      }
    });
  }

  useEffect(() => {
    if (companyUnions == null) {
      getCompanyUnions();
    }
  });

  const getTypeformIdForCompany = (companyUnionId) => {
    if (companyUnionId === null || companyUnionId === undefined) {
      return "oOwPWYIk"
    } else {
      let companyUnion = companyUnions && companyUnions.find(x => x.id.toString() === companyUnionId.toString());
      if (companyUnion === null || companyUnion === undefined) {
        return "";
      } else {
        return companyUnion.typeformId;
      }
    }
  }

  const assignGroupToCompanyUnion = (groupId, companyUnionId) => {
    ajaxCaller("Group/AssignGroupToCompanyUnion/", {
      headers: authHeaders,
      method: "put",
      body: JSON.stringify({
        groupId: groupId,
        companyUnionId: companyUnionId === "-1" ? null : companyUnionId,
      }),
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      }
    });
  }

  const [companyName, setCompanyName] = useState("");
  const changeCompanyName = (companyId) => {
    ajaxCaller("Company/ChangeName/" + companyId, {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        name: companyName,
      }),
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      }
    });
  };

  const createCpmpany = () => {
    ajaxCaller("Company/CreateCompany/", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify({
        name: companyName,
      }),
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      }
    });
  };

  const [facilityName, setFacilityName] = useState("");
  const [liveChatGroupId, setLiveChatGroupId] = useState(0);
  const [editableGroupId, setEditableGroupId] = useState(null);
  const changeGroupByKontakAdmin = () => {
    ajaxCaller("Group/ChangeGroupByKontakAdmin/" + editableGroupId, {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        name: facilityName,
        liveChatGroupId: liveChatGroupId
      }),
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      }
    });
  };

  const [regions, setRegions] = useState(null);
  const getRegions = () => {
    ajaxCaller("Region/GetRegionsWithGroupCount", {
      method: "get",
      headers: authHeaders,
    }).then((regions) => {
      if (regions.isError) {
      } else {
        setRegions(regions);
      }
    });
  };


  useEffect(() => {
    if (regions == null) {
      getRegions();
    }
  })

  const [newGroupRegionId, setNewGroupRegionId] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupLVCId, setNewGroupLVCId] = useState("");
  const [newGroupCompanyId, setNewGroupCompanyId] = useState("");
  const clearFields = () => {
    setNewGroupName("");
    setNewGroupRegionId(null);
    setNewGroupLVCId("");
  }
  const createGroup = () => {
    ajaxCaller("Group/InsertGroup", {
      headers: authHeaders,
      method: "post",
      body: JSON.stringify({
        name: newGroupName,
        liveChatId: newGroupLVCId,
        regionId: newGroupRegionId,
        companyId: newGroupCompanyId
      }),
    }).then((response) => {
      if (!response.isError) {
        clearFields();
        getAllCompanies();
      } else {
      }
    });
  };

  const changeappointmentDirectEnabled = (groupId) => {
    ajaxCaller("Group/ChangeAppointmentDirectEnabled/" + groupId, {
      headers: authHeaders,
      method: "put"
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      } else {
      }
    });
  }
  
  const switchArchiveState = (groupId) => {
    ajaxCaller("Group/SwitchArchiveState/" + groupId, {
      headers: authHeaders,
      method: "put"
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      } else {
      }
    });
  }

  const switchAcceptsAppointmentRequests = (groupId) => {
    ajaxCaller("Group/SwitchAcceptsAppointmentRequests/" + groupId, {
      headers: authHeaders,
      method: "put"
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      } else {
      }
    });
  }

  const changePawPassEnabled = (groupId) => {
    ajaxCaller("Group/ChangePawPassEnabled/" + groupId, {
      headers: authHeaders,
      method: "put"
    }).then((response) => {
      if (!response.isError) {
        getAllCompanies();
      } else {
      }
    });
  }

  return (
    <>
      <div
        className="modal modal-metric bigger fade"
        id={"modalCreateCompany"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalCreateCompany"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-4 font-weight-700">
                      Create Company
                                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 align-self-center">
                    <p className="mb-2 mb-sm-0 font-weight-500">
                      Name:
                                    </p>
                  </div>
                  <div className="col-sm-9">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-style border-0 small-40"
                        placeholder="Name..."
                        value={companyName}
                        onChange={(event) => {
                          setCompanyName(
                            event.target.value
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                    >
                      CANCEL
                                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                      onClick={() =>
                        createCpmpany()
                      }
                    >
                      Create
                                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal modal-metric bigger fade"
        id={"addFacility"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addFacility"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-4 font-weight-700">
                      Add Location
                                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 align-self-center">
                    <p className="mb-2 mb-sm-0 font-weight-500">
                      Name:
                                    </p>
                  </div>
                  <div className="col-sm-9">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-style border-0 small-40"
                        placeholder="Location name..."
                        value={newGroupName}
                        onChange={(event) => setNewGroupName(
                          event.target.value
                        )}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-3 align-self-center">
                    <p className="mb-2 mb-sm-0 font-weight-500">
                      LVC Id:
                                    </p>
                  </div>
                  <div className="col-sm-9">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-style border-0 small-40"
                        placeholder="LivechatId for location..."
                        value={newGroupLVCId}
                        onChange={(event) => setNewGroupLVCId(
                          event.target.value
                        )}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-3 align-self-center">
                    <p className="mb-2 mb-sm-0 font-weight-500">
                      Region:
                                    </p>
                  </div>
                  <div className="col-sm-9">
                    <div className="form-group">
                      {regions && (<Select2
                        className="input-select-style no-search bigger-text"
                        data={
                          regions &&
                          regions.map((x) => ({ id: x.id, text: x.name }))
                        }
                        defaultValue={newGroupRegionId}
                        options={{
                          placeholder: 'Select region',
                          theme: 'bootstrap4',
                          minimumResultsForSearch: -1
                        }}
                        onChange={(event) => {
                          setNewGroupRegionId(event.target.value);
                        }}
                      />)}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                    >
                      CANCEL
                                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      disabled={newGroupLVCId == null || newGroupLVCId === "" || newGroupLVCId === " " || newGroupName == null || newGroupName === "" || newGroupName === " "}
                      className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                      onClick={() =>
                        createGroup()
                      }
                    >
                      CREATE
                                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
                              className="modal modal-metric bigger fade"
                              id={"modalEditFacility"}
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="modalEdit"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="container-fluid">
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                      <div className="row">
                                        <div className="col-12">
                                          <h5 className="mb-4 font-weight-700">
                                            Edit Facility Name
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-3 align-self-center">
                                          <p className="mb-2 mb-sm-0 font-weight-500">
                                            Name:
                                      </p>
                                        </div>
                                        <div className="col-sm-9">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-style border-0 small-40"
                                              placeholder="Name..."
                                              value={facilityName}
                                              onChange={(event) => {
                                                setFacilityName(
                                                  event.target.value
                                                );
                                                
                                              }}
                                              autoComplete="off"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-2">
                                        <div className="col-sm-3 align-self-center">
                                          <p className="mb-2 mb-sm-0 font-weight-500">
                                            LiveChat ID:
                                      </p>
                                        </div>
                                        <div className="col-sm-9">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-style border-0 small-40"
                                              placeholder="Name..."
                                              value={liveChatGroupId}
                                              onChange={(event) => {
                                                setLiveChatGroupId(
                                                  event.target.value
                                                );
                                                
                                              }}
                                              autoComplete="off"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 text-center mt-4">
                                          <button
                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                          >
                                            CANCEL
                                    </button>
                                          <button
                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                            onClick={() =>
                                              changeGroupByKontakAdmin(editableGroupId)
                                            }
                                          >
                                            UPDATE
                                    </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

      <SettingsPageNavBar pageName="admin-panel" />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">Admin Panel</h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label style={{ marginRight: "2px" }}> Add Company </label>
                  <button
                    data-toggle="modal"
                    data-target={"#modalCreateCompany"} style={{ width: "20px", height: "20px" }} className="btn btn-blue btn-box-36 border-0" data-offset="0 5" data-placement="top" title="Add Facility">
                    <img src={require("../img/plus-light.svg")} alt="" />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className="col-lg-2 pr-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">Company name</p>
                        </div>
                      </div>
                      <div className="col-lg-2 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">Facility name</p>
                        </div>
                      </div>
                      <div className="col-lg-1 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">LiveChat ID</p>
                        </div>
                      </div>
                      <div className="col-lg-1 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Typeform url</p>
                        </div>
                      </div>
                      <div className="col-lg-2 pl-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Client type</p>
                        </div>
                      </div>
                      <div className="col-lg-1 pl-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Inactive</p>
                        </div>
                      </div>
                      <div className="col-lg-1 pl-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Show in Appointment Direct</p>
                        </div>
                      </div>
                      <div className="col-lg-1 pl-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center text-center">
                          <p className="mb-0 font-size-14 font-weight-500">Accepts Appointment Requests</p>
                        </div>
                      </div>
                      <div className="col-lg-1 pl-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Show in Paw Pass</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-12 profile-category-wrap-move">
                  {companies && companies.map(company => (
                    <div key={company.id}>
                      <div
                        className="modal modal-metric bigger fade"
                        id={"modalEdit_" + company.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalEdit"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="container-fluid">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                                <div className="row">
                                  <div className="col-12">
                                    <h5 className="mb-4 font-weight-700">
                                      Edit Company
                                    </h5>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-3 align-self-center">
                                    <p className="mb-2 mb-sm-0 font-weight-500">
                                      Name:
                                    </p>
                                  </div>
                                  <div className="col-sm-9">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-style border-0 small-40"
                                        placeholder="Name..."
                                        value={companyName}
                                        onChange={(event) => {
                                          setCompanyName(
                                            event.target.value
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-center mt-4">
                                    <button
                                      type="button"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      type="button"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                      onClick={() =>
                                        changeCompanyName(company.id)
                                      }
                                    >
                                      UPDATE
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="section profile-category-wrap" style={{ cursor: "default" }}>
                        <div className="row">
                          <div className="col-xl-2 pr-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                              <p className="mb-0 font-size-15">{company.name}</p>
                              <button
                                data-toggle="modal"
                                data-target={"#modalEdit_" + company.id}
                                onClick={() => setCompanyName(company.name)} className="btn btn-blue btn-box-36 border-0">
                                <img src={require("../img/edit.svg")} alt="" />
                              </button>
                            </div>
                          </div>
                          <div className="col-xl-3 px-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                              <p className="mb-0 font-size-15">
                                {company.groups && company.groups.length > 0 && (<span className="mb-0" style={{ color: "#19A3FC", cursor: "pointer" }} data-toggle="collapse" data-target={".collapse-location-" + company.id} aria-expanded="false" aria-controls={"collapse-location-" + company.id}
                                >all locations</span>)}
                              </p>
                              <div className="row">
                                {/* {company.groups && company.groups.length > 0 && (<button 
                                data-toggle="modal"
                                data-target={"#modalEditFacility_"+ company.groups[0].id}
                                onClick={() => setFacilityName(company.groups[0].name)} style={{width:"30px", height:"30px"}} className="btn btn-blue btn-box-36 border-0">
                                            <img src={require("../img/edit.svg")} alt=""/>
                                        </button>)} */}
                                <button
                                  data-toggle="modal"
                                  onClick={() => setNewGroupCompanyId(company.id)}
                                  data-target={"#addFacility"} /*style={{width:"30px", height:"30px"}}*/ style={{ marginRight: "20px" }} className="btn btn-blue btn-box-36 border-0" data-offset="0 5" data-placement="top" title="Add Facility">
                                  <img src={require("../img/plus-light.svg")} alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 px-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                              {/* <CopyToClipboard
                      text={
                        "https://profileskontak.typeform.com/to/" + getTypeformIdForCompany(company.groups && company.groups.length > 0 && company.groups[0].companyUnionId) + "#companykey=" + company.companyKey + "&groupkey=" + company.groups && company.groups.length > 0 && company.groups[0].groupKey
                      }
                    >
                      <button
                        type="submit"
                        style={{ cursor: "pointer", marginRight: "5px" }}
                        className="btn btn-regions "
                        data-toggle="tooltip"
                        data-placement="top"
                        data-trigger="hover"
                        title="Copy"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          width="18px"
                          height="18px"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                        </svg>
                      </button>
                    </CopyToClipboard>
                    <p style={{overflowX:"hidden"}} className="mb-0 font-size-15 one-line-col">
                      https://profileskontak.typeform.com/to/{getTypeformIdForCompany(company.groups && company.groups.length > 0 && company.groups[0].companyUnionId)}#companykey={company.companyKey}&groupkey={company.groups && company.groups.length > 0 && company.groups[0].groupKey}
                      
                      </p> */}
                            </div>
                          </div>
                          <div className="col-xl-2 pl-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right">
                              <div className="section z-bigger-20 height-40-style light-style">

                                {/* <Select2
                                            className="input-select-style no-search bigger-text"
                                            data={
                                                companyUnions &&
                                                companyUnions.map((x) => ({ id: x.id, text: x.name }))
                                            }
                                            defaultValue={company.groups &&  company.groups.length > 0 && company.groups[0].companyUnionId}
                                            options={{
                                                placeholder: 'Select client type',
                                                theme: 'bootstrap4',
                                                minimumResultsForSearch: -1
                                            }}
                                            onChange={(event) => {
                                                if(!company.groups || company.groups.length < 1)
                                                {
                                                    return;
                                                }
                                                assignGroupToCompanyUnion(company.groups[0].id, event.target.value);
                                              }}
                                            /> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-1 pl-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right">
                              <div className="section z-bigger-20 height-40-style light-style">
                              </div>
                            </div>
                          </div>

                        </div>
                        {company.groups && company.groups.map(group => (
                          <div key={group.id} className={"section collapse collapse-location-" + company.id} >
                           
                            <div className="row">
                              <div className="col-xl-2 pr-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                                  <p className="mb-0 font-size-15"></p>
                                </div>
                              </div>
                              <div className="col-xl-2 px-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                                  <p className="mb-0 font-size-15">{group.name}</p>
                                </div>
                              </div>
                              <div className="col-xl-1 px-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-between">
                                  <p className="mb-0 font-size-15">{group.liveChatGroupId}</p>
                                  {!group.archived && (<button
                                    data-toggle="modal"
                                    data-target={"#modalEditFacility"}
                                    onClick={() =>  { setLiveChatGroupId(group.liveChatGroupId); setEditableGroupId(group.id); setFacilityName(group.name); }} /*style={{width:"30px", height:"30px"}} */ className="btn btn-blue btn-box-36 border-0">
                                    <img src={require("../img/edit.svg")} alt="" />
                                  </button>)}
                                </div>
                              </div>
                              
                              <div className="col-xl-1 px-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-center">
                                  <CopyToClipboard
                                    text={
                                      "https://profileskontak.typeform.com/to/" + getTypeformIdForCompany(group.companyUnionId) + "#companykey=" + company.companyKey + "&groupkey=" + group.groupKey
                                    }
                                  >
                                    <button
                                      type="submit"
                                      style={{ cursor: "pointer", marginRight: "5px" }}
                                      className="btn btn-regions "
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      data-trigger="hover"
                                      title="Copy"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        width="18px"
                                        height="18px"
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                      </svg>
                                    </button>
                                  </CopyToClipboard>
                                  <p style={{ overflowX: "hidden" }} className="mb-0 font-size-15 one-line-col">
                                  </p>
                                </div>
                              </div>
                              <div className="col-xl-2 pl-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right">
                                  <div className="section z-bigger-20 height-40-style light-style">
                                    {companyUnions && !group.archived && (<Select2
                                      className="input-select-style no-search bigger-text"
                                      data={
                                        companyUnions &&
                                        companyUnions.map((x) => ({ id: x.id, text: x.name }))
                                      }
                                      defaultValue={group.companyUnionId}
                                      options={{
                                        placeholder: 'Select client type',
                                        theme: 'bootstrap4',
                                        minimumResultsForSearch: -1
                                      }}
                                      onChange={(event) => {
                                        assignGroupToCompanyUnion(group.id, event.target.value);
                                      }}
                                    />)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-1 pl-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-center">
                                  <div className="col-auto align-self-center pr-1">
                                    <div className="htmlForm-group">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={"archived_" + group.id}
                                        checked={group.archived}
                                        onChange={(event) =>
                                          switchArchiveState(group.id)
                                        }
                                      />
                                      <label
                                        className="checkbox mb-0"
                                        htmlFor={"archived_" + group.id}
                                      >
                                      </label>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="col-xl-1 pl-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-center">
                                  <div className="col-auto align-self-center pr-1">
                                    {!group.archived && (<div className="htmlForm-group">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={"apptDirectEnabled_" + group.id}
                                        checked={group.appointmentDirectEnabled}
                                        disabled={group.archived}
                                        onChange={(event) =>
                                          changeappointmentDirectEnabled(group.id)
                                        }
                                      />
                                      <label
                                        className="checkbox mb-0"
                                        htmlFor={"apptDirectEnabled_" + group.id}
                                      >
                                      </label>
                                    </div>)}
                                  </div>

                                </div>
                              </div>
                              <div className="col-xl-1 pl-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-center">
                                  <div className="col-auto align-self-center pr-1">
                                    {!group.archived && (<div className="htmlForm-group">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={"acceptsAppointmentRequests_" + group.id}
                                        checked={group.acceptsAppointmentRequests}
                                        onChange={(event) =>
                                          switchAcceptsAppointmentRequests(group.id)
                                        }
                                      />
                                      <label
                                        className="checkbox mb-0"
                                        htmlFor={"acceptsAppointmentRequests_" + group.id}
                                      >
                                      </label>
                                    </div>)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-1 pl-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-center">
                                  <div className="col-auto align-self-center pr-1">
                                    {!group.archived && (<div className="htmlForm-group">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        id={"pawPassEnabled_" + group.id}
                                        checked={group.pawPassEnabled}
                                        onChange={(event) =>
                                          changePawPassEnabled(group.id)
                                        }
                                      />
                                      <label
                                        className="checkbox mb-0"
                                        htmlFor={"pawPassEnabled_" + group.id}
                                      >
                                      </label>
                                    </div>)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPanel;