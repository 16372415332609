import React, { useState, useEffect } from "react";
import "../style.css";
import { ajaxCaller } from "../ajaxCaller";
import 'react-select2-wrapper/css/select2.css';

const ActivationPage = (props) => {
  const [newPassword, setNewPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [activationFailed, setActivationFailed] = useState(null);


  const [errorMessage, setErrorMessage] = useState(null);
  const [isСomplexPassword, setIsСomplexPassword] = useState(true);

  const validatePassword = () => {
    let regex = new RegExp("(^\\S*$)");

    let isOk = regex.test(newPassword);

    if (
      isOk &&
      newPassword.length >= 6 &&
      newPassword.length <= 12
    ) {
      setIsСomplexPassword(true);
      return true;
    } else {
      setIsСomplexPassword(false);
      return false;
    }
  }

  const validateSecretKey = () => {
    const { secretKey } = props.match.params;

    ajaxCaller("User/ValidateSecretKey/" + secretKey, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.isError) {
        setActivationFailed(true);
        setErrorMessage(response.message)
      } else {
      }
    });
  }

  const validateKeyCallback = () => {
    validateSecretKey()
  }

  useEffect(validateKeyCallback, [props])

  const userActivate = () => {
    const { secretKey } = props.match.params;
    if (validatePassword()) {
      ajaxCaller("User/PasswordConfirm", {
        method: "put",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          password: newPassword,
          secretKey: secretKey
        })
      }).then(response => {
        if (response.isError) {
          setActivationFailed(true);
          setErrorMessage(response.message);
        } else {
          setActivationFailed(false);
          window.location.href = "/";
        }
      });
    }
  }

  const submitAllowed = newPassword !== "" && newPassword === repeatPassword;
  return (
    <div>
      <div
        className="section full-height img-background-cover"
        style={{ backgroundImage: `url(${require("../img/log-in.jpg")})` }}
      >
        <div className="container z-bigger-10">
          <div className="row full-height justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5 align-self-center">
              <div className="section log-in-section px-3 px-lg-4 py-4">
                <div className="row justify-content-center py-2 py-lg-3">
                  <div className="col-auto">
                    <img src={require("../img/logo.png")} alt="logo" />
                  </div>
                  <div className="col-12 pt-4">
                    <h5 className="text-center mb-3 pb-1">
                      Please type in your password
                    </h5>
                  </div>
                  {!isСomplexPassword && (
                    <div className="alert alert-danger" role="alert">
                      Password must meet or exceed these criteria: Between 6 and 12
                      characters long,and without spaces
                    </div>
                  )}
                  {activationFailed && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                  <div className="col-12 mb-2">
                    <div className="form-group">
                      <label className="mb-1" htmlFor="newPassword">
                        New Password
                      </label>
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        onKeyPress={(event) => {
                          var key = event.which;
                          if (key === 13 || key === 14) {

                          }
                        }}
                        onChange={(event) => setNewPassword(event.target.value)}

                        className="form-style"
                        placeholder="Your new password..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="form-group">
                      <label className="mb-1" htmlFor="password">
                        Repeat Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-style"
                        onKeyPress={(event) => {
                          var key = event.which;
                          if (key === 13 || key === 14) {

                          }
                        }}
                        onChange={(event) => setRepeatPassword(event.target.value)}
                        placeholder="Repeat your Password..."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      disabled={!submitAllowed}
                      onClick={() => userActivate()}
                      className="btn btn-blue btn-width-170 border-4"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationPage;
