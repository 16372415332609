import * as env from './env'
export default {
    env: {
        api: {
            setDevelopment: ()=>{
                sessionStorage.setItem('apiLocalPath', env.dev);
                window.location.reload()
            },
            setLocal: ()=>{
                sessionStorage.setItem('apiLocalPath', env.local);
                window.location.reload()
            },
            setStaging: ()=>{
                sessionStorage.setItem('apiLocalPath', env.staging);
                window.location.reload()
            },
            setStaging2: ()=>{
                sessionStorage.setItem('apiLocalPath', env.staging2);
                window.location.reload()
            },
            setProduction: ()=>{
                sessionStorage.removeItem('apiLocalPath');
                window.location.reload()
            }
        }
    }
}