import React, { useState } from "react";
import { ajaxCaller } from "../../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../../authentification";
import Select2 from "react-select2-wrapper";
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import { PrivilegeChecker, Privileges } from "../../privilegeChecker";
import "../../style.css";


const RepeaterComponent = ({ referrerTypes, formats, caches, items, handleItemChange, handleReffererTypeAdd , handleClearCache}) => {

    //React components values constants
    const [isLoading, setIsLoading] = useState(false)
    const postDniReferrerType = (item) => {

        const domainRemoved = item.label.replace(/\.[^.]+$/, "");
        const createJson = {
            name: domainRemoved.replace('://', '').replace('https', '').replace('www.', '').toUpperCase(),
            id: item.value,
            tag: item.label,
        };
        ajaxCaller("DniReferrerType", {
            headers: new Headers({
                Authorization: "Bearer " + getAccessToken(),
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
            method: "post",
            body: JSON.stringify(createJson),
        }).then((response) => {
            if (response.isError) {
                console.log(response);
            } else {
                const res = [response].map(record => ({
                    label: record.name,
                    value: record.id
                }));
                handleReffererTypeAdd(res);
            }
        });
    };


   


    //custom style
    const separatorStyle = {
        height: '2px',
        backgroundColor: 'lightgray',
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.selectProps.value.label === 'DEACTIVATE' ? 'salmon' : 'transparent',
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999, // Adjust the z-index as needed to ensure it appears above other elements
        }),
    };

    // Item Handler
    const addItem = () => {
        handleItemChange([...items, { id: 0 }], []);
    };

    const removeItem = (index) => {
        const updatedItems = [...items];
        const deletedItem = updatedItems.splice(index, 1);
        handleItemChange(updatedItems, deletedItem);
    };


    const copyItem = (item) => {
        handleItemChange([...items, {
            id: 0,
            dniUrlId: item.dniUrlId,
            dropdown_1: item.dropdown_1,
            inputMask_1: item.inputMask_1,
            dropdown_phone_format_1: item.dropdown_phone_format_1,
            inputMask_2: item.inputMask_2,
            dropdown_phone_format_2: item.dropdown_phone_format_2,
            explicit_replace: item.explicit_replace,
            url_param: item.url_param,
            dropdown_cache: item.dropdown_cache

        }], []);
    };


    const deactivateItem = (item, index) => {
        const updatedItems = [...items];
        var i = (referrerTypes && referrerTypes.find(option => option.label === 'DEACTIVATE'));
        if (i) {
            if (item.dropdown_1 !== i.value)
                item.dropdown_1 = i.value;
            else
                item.dropdown_1 = 1
            handleItemChange(updatedItems, []);
        }
        else {
            const handleCreatePromise = () => {
                return new Promise((resolve, reject) => {
                    handleCreate('DEACTIVATE', index, "dropdown_1", resolve);
                });
            };
            handleCreatePromise()
                .then((newValue) => {
                    var i = (referrerTypes && referrerTypes.find(option => option.label === 'DEACTIVATE'));
                    if (i) {
                        item.dropdown_1 = i.value;
                    }
                })
                .then(() => handleItemChange(updatedItems, []))
                .catch((error) => {
                    // Handle any errors that occurred during the AJAX call
                });
        }
    };

    const updateItem = (index, field, e) => {
        const updatedItems = [...items];
        var value;
        if (field === 'explicit_replace') {
            value = (e.target.checked)
        }
        else if (field === 'dropdown_1') {
            if (e !== null)
                value = (!e.value) ? null : e.value;
        }
        else
            value = e.target.value;

        updatedItems[index][field] = value;

        if (field === "dropdown_phone_format_1") {

            var res = getMaskByFormat(parseInt(value, 10));
            updatedItems[index]["mask_1"] = res && res.format;

            if (value > 1) {
                //seems to be only thing that works when going from custom=>non-custom format
                if (updatedItems[index]["inputMask_1_flag"]) {
                    updatedItems[index]["inputMask_1"] = "";
                    triggerElement2Change(index, "inputMask_1")
                    handleItemChange(updatedItems, []);
                    updatedItems[index]["inputMask_1"] = updatedItems[index]["inputMask_1_flag"];
                    triggerElement2Change(index, "inputMask_1")
                    updatedItems[index]["inputMask_1_flag"] = null;
                }

                else
                    triggerElement2Change(index, "inputMask_1")
            }
            else {
                updatedItems[index]["inputMask_1_flag"] = updatedItems[index]["inputMask_1"];
                updatedItems[index]["inputMask_1"] = "";
                handleItemChange(updatedItems, [updatedItems]);
            }
        }

        if (field === "dropdown_phone_format_2") {

            var res2 = getMaskByFormat(parseInt(value, 10));
            updatedItems[index]["mask_2"] = res2 && res2.format;

            if (value > 1) {
                //seems to be only thing that works when going from custom=>non-custom format
                if (updatedItems[index]["inputMask_2_flag"]) {
                    updatedItems[index]["inputMask_2"] = "";
                    triggerElement2Change(index, "inputMask_2")
                    handleItemChange(updatedItems, []);
                    updatedItems[index]["inputMask_2"] = updatedItems[index]["inputMask_2_flag"];
                    triggerElement2Change(index, "inputMask_2")
                    updatedItems[index]["inputMask_2_flag"] = null;
                }
                else
                    triggerElement2Change(index, "inputMask_2")
            }
            else {
                updatedItems[index]["inputMask_2_flag"] = updatedItems[index]["inputMask_2"];
                updatedItems[index]["inputMask_2"] = "";
                handleItemChange(updatedItems, [updatedItems]);
            }
        }

        updatedItems[index]["updatedAt"] = getUTCDate();

        handleItemChange(updatedItems, []);
    };

    const triggerElement2Change = (zindex, field) => {

        const updatedItems = [...items];
        updatedItems.map((elements, index) => {
            if (index === zindex) {
                var entries = Object.entries(elements);
                entries.map(([element, value]) => {
                    if (field === element) {

                        var elId = `${field}_${index}`
                        const element2 = document.getElementById(elId);
                        const event = new Event('change', { bubbles: true });
                        element2.dispatchEvent(event)
                        element2.addEventListener('change', updateItem(index, field, event));
                        var temp = element2.value
                        event.target.defaultValue = temp;
                        updateItem(index, field, event)
                        return value;
                    }
                    return [element, value];
                })
            }
            return elements;
        });
        //  handleItemChange(updatedItems);
    };


    const mapItemDropDown = (item) => {
        return referrerTypes && referrerTypes.find(option => option.value === item);
    }


    const getMaskByFormat = (format) => {
        return formats && formats.find(option => option.id === format);
    };


    const createOption = (id, label) => ({
        label,
        value: id
    });

    const handleCreate = (inputValue, index, field, callback) => {
        const lastIndex = referrerTypes.reduce((maxValue, option) => {
            return Math.max(maxValue, option.value) + 1;
        }, -Infinity);
        const newOption = createOption(lastIndex, inputValue)
        setIsLoading(true)
        postDniReferrerType(newOption)
        setIsLoading(false)
        updateItem(index, field, newOption);
        callback(newOption)
    }

    const getDateTime = (inputString) => {

        if (inputString===null || inputString===undefined)
        return ''
        const dateObject = new Date(inputString);
        const year = dateObject.getFullYear(); // Example: 2023
        const month = dateObject.getMonth() + 1; // Example: 7 (Note: Month is zero-based, so adding 1)
        const day = dateObject.getDate(); // Example: 16

        const hours = dateObject.getHours(); // Example: 12
        const minutes = dateObject.getMinutes(); // Example: 34

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const  getUTCDate= () => {

        const currentDateTime = new Date();
        return new Date(currentDateTime.getTime() + currentDateTime.getTimezoneOffset() * 60000);

    }

    return (
        <div>
            {items.map((item, index) => (
                <div key={index} className="row mt-2 pt-0">
                    <div
                        className="modal fade"
                        id={"modalCopy_" + item.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalCopy"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center">
                                                <h5 className="mb-2 pb-1">Make a DNI item copy</h5>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    You are about to make a COPY of the existing item.
                                                    Please make sure to change the data appropriately.
                                                    Duplicate data can have unexpected effects.
                                                </p>
                                                <p className="mb-0">
                                                    Are you sure you wish to continue?
                                                </p>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => copyItem(item)}
                                                    className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                                >
                                                    COPY
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id={"modalClearCache_" + item.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalClearCache"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center">
                                                <h5 className="mb-2 pb-1">Clear the current DNI item's cache.</h5>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    You are about to clear the cache for the current DNI item.
                                                    Please be notified that this can have performance effects.
                                                </p>
                                                <p className="mb-0">
                                                    Are you sure you wish to continue?
                                                </p>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => handleClearCache(item)}
                                                    className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                                >
                                                    CLEAR
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id={"modalDeactivate_" + item.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalDeactivate"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center">
                                                <h5 className="mb-2 pb-1">Deactivate DNI item.</h5>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    You are about to render the current DNI item inactive.
                                                </p>
                                                <p className="mb-0">
                                                    Are you sure you wish to continue?
                                                </p>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => deactivateItem(item, index)}
                                                    className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                                >
                                                    DEACTIVATE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id={"modalActivate_" + item.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalActivate"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center">
                                                <h5 className="mb-2 pb-1">Activate DNI item.</h5>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    You are about to activate the current DNI item.
                                                </p>
                                                <p className="mb-0">
                                                    Are you sure you wish to continue?
                                                </p>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => deactivateItem(item, index)}
                                                    className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                                >
                                                    ACTIVATE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal fade"
                        id={"modalDelete_" + item.id}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="modalDelete"
                        aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row justify-content-center">
                                            <div className="col-12 text-center">
                                                <h5 className="mb-2 pb-1">Delete DNI Referrer</h5>
                                            </div>
                                            <div className="col-12 text-center">
                                                <p className="mb-0">
                                                    You are about to delete data for DNI record :
                                                    <strong>{item.id}</strong>
                                                </p>
                                                <p className="mb-0">
                                                    Are you sure you wish to continue?
                                                </p>
                                            </div>
                                            <div className="col-12 text-center mt-4">
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                                >
                                                    CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    onClick={() => removeItem(index)}
                                                    className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                                >
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr className="border-0" style={separatorStyle}></hr>
                        <div className="section background-white p-4 p-lg-4">
                            <div className="row">
                                <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                                    <p className="mb-2 mb-md-0 font-weight-400">
                                        Referrer
                                    </p>
                                </div>
                                <div className="col-md-6 col-xl-4 align-self-center">
                                    <div className="section z-bigger-20 height-40-style light-style">
                                        <CreatableSelect
                                            id={"dropdown_1_" + index}
                                            isClearable={true}
                                            isDisabled={isLoading}
                                            isLoading={isLoading}
                                            onChange={(newValue) => {
                                                updateItem(index, 'dropdown_1', newValue)
                                            }}
                                            value={mapItemDropDown(item.dropdown_1) || ''}
                                            onCreateOption={(inputValue, e) => handleCreate(inputValue, index, 'dropdown_1', () => { })}
                                            options={referrerTypes}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    <div className="section height-40-style light-style">
                                        <button type="button"
                                            data-toggle="modal"
                                            data-target={"#modalCopy_" + item.id}
                                            data-placement="top"
                                            data-trigger="click"
                                            title="Create a COPY of the current DNI item."
                                            className="btn btn-blue btn-box-36 border-1 mt-2 mt-xl-0">
                                            <svg
                                                viewBox="0 0 512 512"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <path d="M224 0c-35.3 0-64 28.7-64 64v224c0 35.3 28.7 64 64 64h224c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64v224c0 35.3 28.7 64 64 64h224c35.3 0 64-28.7 64-64v-64h-64v64H64V224h64v-64H64z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-0">
                                    <p className="mb-2 mb-md-0 font-weight-400">Current Web Site Phone Number</p>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center">
                                    <div className="form-group">
                                        {(item.dropdown_phone_format_1 && item.dropdown_phone_format_1 > 1) ?
                                            (
                                                <InputMask
                                                    id={"inputMask_1_" + index}
                                                    maskChar={null}
                                                    onChange={(e) => updateItem(index, 'inputMask_1', e)}
                                                    className="form-style border-0 small-40"
                                                    mask={item.mask_1 || getMaskByFormat(item.dropdown_phone_format_1)?.format}
                                                    value={(item && item.inputMask_1) || ''}
                                                >
                                                    {(inputProps) => (
                                                        <input
                                                            type="text"
                                                            placeholder={item.mask_1 || getMaskByFormat(item.dropdown_phone_format_1)?.format}
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </InputMask>
                                            )
                                            :
                                            (
                                                <input
                                                    id={"inputMask_1_" + index}
                                                    className="form-style border-0 small-40"
                                                    type="text"
                                                    onChange={(e) => updateItem(index, 'inputMask_1', e)}
                                                    value={(item && item.inputMask_1) || ''}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    <div className="section height-40-style light-style">
                                        <Select2
                                            id={"dropdown_phone_format_1_" + index}
                                            className="input-search-style search bigger-text"
                                            data={formats}
                                            value={item.dropdown_phone_format_1 || ''}
                                            onChange={(e) => updateItem(index, 'dropdown_phone_format_1', e)}
                                            options={{
                                                placeholder: "Select the phone format",
                                                theme: "bootstrap4",
                                                minimumResultsForSearch: -1,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    {(item && item.dropdown_1 !== -1) ?
                                        (
                                            <div className="section height-40-style light-style">
                                                <button type="button"
                                                    data-toggle="modal"
                                                    data-target={"#modalDeactivate_" + item.id}

                                                    data-placement="top"
                                                    data-trigger="click"
                                                    title="Deactivate the current DNI item."
                                                    className="btn btn-blue btn-box-36 border-1 mt-2 mt-xl-0">

                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        height="1em"
                                                        width="1em"
                                                    >
                                                        <path d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2v-8a2 2 0 00-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 01.567-3.677A2.001 2.001 0 0114 16a1.99 1.99 0 01-1 1.723z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ) :
                                        (
                                            <div className="section height-40-style light-style">
                                                <button type="button"
                                                    data-toggle="modal"
                                                    data-target={"#modalActivate_" + item.id}

                                                    data-placement="top"
                                                    data-trigger="click"
                                                    title="Activate the current DNI item."
                                                    className="btn btn-blue btn-box-36 border-1 mt-2 mt-xl-0">

                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        height="1em"
                                                        width="1em"
                                                    >
                                                        <path d="M18 10H9V7c0-1.654 1.346-3 3-3s3 1.346 3 3h2c0-2.757-2.243-5-5-5S7 4.243 7 7v3H6a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2v-8a2 2 0 00-2-2zm-7.939 5.499A2.002 2.002 0 0114 16a1.99 1.99 0 01-1 1.723V20h-2v-2.277a1.992 1.992 0 01-.939-2.224z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-0">
                                    <p className="mb-2 mb-md-0 font-weight-400">New DID</p>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center">
                                    <div className="form-group">
                                        {(item.dropdown_phone_format_2 && item.dropdown_phone_format_2 > 1) ?
                                            (
                                                <InputMask
                                                    id={"inputMask_2_" + index}
                                                    className="form-style border-0 small-40"
                                                    maskChar={null}
                                                    onChange={(e) => updateItem(index, 'inputMask_2', e)}
                                                    mask={item.mask_2 || getMaskByFormat(item.dropdown_phone_format_2)?.format}
                                                    value={(item && item.inputMask_2) || ''}
                                                >
                                                    {(inputProps) => (
                                                        <input
                                                            type="text"
                                                            placeholder={item.mask_2 || getMaskByFormat(item.dropdown_phone_format_2)?.format}
                                                            {...inputProps}
                                                        />
                                                    )}
                                                </InputMask>
                                            )
                                            :
                                            (
                                                <input
                                                    id={"inputMask_2_" + index}
                                                    className="form-style border-0 small-40"
                                                    type="text"
                                                    onChange={(e) => updateItem(index, 'inputMask_2', e)}
                                                    value={(item && item.inputMask_2) || ''}

                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    <div className="section height-40-style light-style">
                                        <Select2
                                            id={"dropdown_phone_format_2_" + index}
                                            className="input-search-style search bigger-text"
                                            data={formats}
                                            value={item.dropdown_phone_format_2 || ''}
                                            onChange={(e) => updateItem(index, 'dropdown_phone_format_2', e)}
                                            options={{
                                                placeholder: "Select the phone format",
                                                theme: "bootstrap4",
                                                minimumResultsForSearch: -1,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    <div className="section height-40-style light-style">
                                        <button type="button"
                                            data-toggle="modal"
                                            data-target={"#modalClearCache_" + item.id}
                                            data-place="top"
                                            data-trigger="click"
                                            title="Clear the current DNI item's cache."
                                            className="btn btn-blue btn-box-36 border-1 mt-2 mt-xl-0">
                                            <svg
                                                viewBox="0 0 1024 1024"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <defs>
                                                    <style />
                                                </defs>
                                                <path d="M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-.3 1.5-.4 3-.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-.1 4.4-.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390zm468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z" />
                                            </svg>
                                            {/* <img src={require("../../img/bin.svg")} alt="" /> */}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-0">
                                    <p className="mb-2 mb-md-0 font-weight-400">URL Parameters</p>
                                </div>
                                <div className="col-md-6 col-xl-4 align-self-center">
                                    <div className="form-group">
                                        <input
                                            id={"url_param_" + index}
                                            type="text"
                                            className="form-style border-0 small-40"
                                            value={item.url_param || ''}
                                            onChange={(e) => updateItem(index, 'url_param', e)}
                                            placeholder="Enter URL parameters..."
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-xl-2 align-self-center pr-0 pr-sm-3">
                                    <div className="section height-40-style light-style">
                                        <button type="button"
                                            data-toggle="modal"
                                            data-target={"#modalDelete_" + item.id}
                                            data-place="top"
                                            data-trigger="click"
                                            title="Delete the current DNI item."
                                            className="btn btn-red btn-box-36 border-1 mt-2 mt-xl-0">
                                            <img src={require("../../img/bin.svg")} alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                                    <p className="mb-2 mb-md-0 font-weight-400">
                                        Cache
                                    </p>
                                </div>
                                <div className="col-md-4 col-xl-2 align-self-center">
                                    <div className="section height-40-style light-style">
                                        <div className="form-group">
                                            <Select2
                                                id={"dropdown_cache_" + index}
                                                className="input-search-style search bigger-text"
                                                data={caches}
                                                value={item.dropdown_cache || ''}
                                                options={{
                                                    placeholder: "Select a Cache Type",
                                                    theme: "bootstrap4",
                                                    minimumResultsForSearch: -1,
                                                }}
                                                onChange={(e) => updateItem(index, 'dropdown_cache', e)}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-2 align-self-center">
                                    <div className="form-group">
                                        <div className="row mt-3">
                                            <div className="form-group" >
                                                <label className="text-muted font-italic small fw-bold" htmlFor={"updatedAt_" + index} >Last Cache Update : </label>
                                            </div>
                                            <div className="form-group" >
                                                <label className="text-muted font-italic small fw-bold">{getDateTime(item.updatedAt) || ''}</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 col-xl-2 align-self-center">
                                    <div className="section height-40-style light-style">
                                        <div className="form-group">
                                            <input
                                                id={"explicit_replace_" + index}
                                                type="checkbox"
                                                className="form-style border-0 small-40"
                                                value={item.explicit_replace || false}
                                                checked={item.explicit_replace}
                                                onChange={(e) => updateItem(index, 'explicit_replace', e)}
                                            />
                                            <label htmlFor={"explicit_replace_" + index} className="checkbox mb-0 d-inline-block">Retain formatting</label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            ))
            }
            {
                (isKontakAdmin() || PrivilegeChecker.hasPrivilege(Privileges.ViewEditClientValue)) && (<>
                    <div className="col-6 mt-4 ml-0">
                        <button
                            type="button"
                            data-dismiss="modal"
                            onClick={addItem}
                            className="btn btn-blue btn-width-120 btn-height-36 border-1 font-size-14">
                            Add new DID Number
                        </button>
                    </div>
                </>)
            }
        </div >
    );
}
export default RepeaterComponent;