import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import Moment from "react-moment";

const Releases = () => {
  const [releases, setReleases] = useState(null);

  const getReleaseNotes = () => {
    ajaxCaller("Release", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setReleases(response);
      } else {
      }
    });
  };
  useEffect(() => {
    getReleaseNotes();
  }, []);

  return (
    <div className="section sec-move-left">
      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4">
              <div className="col-12">
                <h5 className="mb-2 pb-1">Release Notes</h5>
              </div>
              <div className="col-12">
                <div className="section background-white p-3 p-lg-4">
                  <div className="row">
                    <div className="col-auto">
                      <div id="accordion" className="max-450">
                        {releases &&
                          releases.map((release) => (
                            <div className="card mb-2">
                              <div
                                className="card-header"
                                id={"headingOne_" + releases.id}
                              >
                                <h5
                                  className="accordion-link mb-0"
                                  data-toggle="collapse"
                                  data-target={"#collapseOne_" + release.id}
                                  aria-expanded={
                                    releases[0].id === release.id
                                      ? "true"
                                      : "false"
                                  }
                                  aria-controls="collapseOne"
                                >
                                  {release.name} -{" "}
                                  <Moment
                                    format="dddd, Do MMMM "
                                    date={release.date}
                                  />
                                </h5>
                              </div>

                              <div
                                id={"collapseOne_" + release.id}
                                class={
                                  releases[0].id === release.id
                                    ? "collapse show"
                                    : "collapse"
                                }
                                aria-labelledby={"headingOne_" + releases.id}
                                data-parent="#accordion"
                              >
                                {release.releaseNotes &&
                                  release.releaseNotes.map((releaseNotes) => (
                                    <div className="card-body">
                                      <div className="section pl-3 pt-2 pb-3">
                                        <h7 className="link mb-0">
                                          Release features -{" "}
                                          {releaseNotes.notes}
                                        </h7>
                                        {releaseNotes.new && (<>
                                          <p className="mb-0 font-size-15 line-height-22 font-weight-500">
                                            - New
                                        </p>
                                          <p className="mb-0 font-size-14 line-height-22 pl-2">
                                            {releaseNotes.new}
                                          </p>
                                        </>)}

                                        {releaseNotes.improvements && (<>
                                          <p className="mb-0 font-size-15 line-height-22 font-weight-500">
                                            - Improvements
                                        </p>
                                          <p className="mb-0 font-size-14 line-height-22 pl-2">
                                            {releaseNotes.improvements}
                                          </p>
                                        </>)}

                                        {releaseNotes.fixes && (<>
                                          <p className="mb-0 font-size-15 line-height-22 font-weight-500">
                                            - Fixes
                                        </p>
                                          <p className="mb-0 font-size-14 line-height-22 pl-2">
                                            {releaseNotes.fixes}
                                          </p>
                                        </>)}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Releases;
