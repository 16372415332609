import Cookies from "js-cookie";

export const getCurrentCompanyId = () => {
  if (Cookies.get("company_id") == null) {
    return null;
  } else {
    return parseInt(Cookies.get("company_id"));
  }
};

export const getCurrentUserId = () => {
  if (Cookies.get("user_id") == null) {
    return null;
  } else {
    return parseInt(Cookies.get("user_id"));
  }
};

export const getCurrentUserName = () => {
  if (Cookies.get("user_name") == null) {
    return null;
  } else {
    return Cookies.get("user_name");
  }
};

export const getCurrentUserAvatar = () => {
  if (Cookies.get("user_avatar") == null) {
    return null;
  } else {
    return Cookies.get("user_avatar");
  }
};

export const getCurrentUserSecretKey = () => {
  if (Cookies.get("secret_key") == null) {
    return null;
  } else {
    return Cookies.get("secret_key");
  }
};

export const getCurrentCompanyLogo = () => {
  if (Cookies.get("company_logo") == null) {
    return null;
  } else {
    return Cookies.get("company_logo");
  }
};

export const getIsEnableAppDirect = () => {
  if (Cookies.get("isEnableAppDirect") == null) {
    return null;
  } else {
    return Cookies.get("isEnableAppDirect");
  }
};

export const getCurrentCompanyKey = () => {
  if (Cookies.get("companyKey") == null) {
    return null;
  } else {
    return Cookies.get("companyKey");
  }
};

export const getCurrentUserEmail = () => {
  if (Cookies.get("user_email") == null) {
    return null;
  } else {
    return Cookies.get("user_email");
  }
};

export const getCurrentUserPrivileges = () => {
  if (Cookies.get("privileges") == null) {
    return null;
  } else {
    return Cookies.get("privileges");
  }
};


export const getCurrentUserRoleId = () => {
  if (Cookies.get("role_id") == null) {
    return null;
  } else {
    return parseInt(Cookies.get("role_id"));
  }
};

export const isKontakAdmin = () => {
  if (Cookies.get("kontak_admin") == null) {
    return null;
  } else {
    return Cookies.get("kontak_admin") === 'true' ? true : false //parseInt(Cookies.get("kontak_admin"));
  }
};

export const isAuthenticated = () => {
  const today = new Date();
  const loggedInDateString = Cookies.get("logged_in_date");

  if (loggedInDateString == null) {
    return false;
  }

  const loggedInDate = new Date(loggedInDateString);
  const hours = (today - loggedInDate) / 36e5;
  if (Cookies.get("access_token") != null && hours < 23) {
    return true;
  } else {
    return false;
  }
};

export const getAccessToken = () => {
  return Cookies.get("access_token");
}