import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../../ajaxCaller";
import { getAccessToken } from "../../authentification";
import $ from "jquery";

const FacilitiesPicker = (props) => {
    const [regionsLocations, setRegionLocations] = useState([]);
    const fetchData = () => {
        if (props.showAssignedLocations && props.userId) {
            ajaxCaller("Group/GetAssignedGroups/" + props.userId, {
                method: "get",
                headers: new Headers({
                    Authorization: "Bearer " + getAccessToken(),
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }),
            }).then((response) => {
                var countOfCheckedRegions = 0;
                response.currentUserGroups.forEach(function (region) {
                    var countOfRegionGroups = 0;
                    response.roleGroups.forEach(item => {
                        if (item.regionId === region.id || (item.regionId == null && region.id === 0)) {
                            countOfRegionGroups++;
                        }
                    });

                    if (countOfRegionGroups === region.groups.length) {
                        region.checked = true;
                        countOfCheckedRegions++;
                    }
                    else {
                        region.checked = false;
                    }
                    region.groups.forEach(function (facility) {
                        if (response.roleGroups.find(x => x.id === facility.id)) {
                            if (props.groupIds == null) {
                                facility.checked = true;
                            }
                            else {
                                if (props.groupIds.includes(facility.id)) {
                                    facility.checked = true;
                                }
                                else {
                                    facility.checked = false;
                                }
                            }
                        } else {
                            facility.checked = false;
                        }
                    })

                    if (region.groups.every(x => x.checked === true)) {
                        region.checked = true;
                    }
                    else {
                        region.checked = false;
                    }
                });
                if (response.currentUserGroups.every(x => x.checked === true)) {
                    setAllLocationsSelected(true);
                }
                else {
                    setAllLocationsSelected(false);
                }
                setAllLocationsSelected(countOfCheckedRegions === response.currentUserGroups.length);
                setRegionLocations(response.currentUserGroups);
                props.onChange(response.currentUserGroups);
            });

        }
        else {
            ajaxCaller("Region/GetRegionGroups", {
                method: "get",
                headers: new Headers({
                    Authorization: "Bearer " + getAccessToken(),
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }),
            }).then((response) => {
                response.forEach(function (region) {
                    region.checked = true;
                    region.groups.forEach(function (facility) {
                        if (props.groupIds == null) {
                            facility.checked = true;
                        }
                        else {
                            if (props.groupIds.includes(facility.id)) {
                                facility.checked = true;
                            }
                            else {
                                facility.checked = false;
                            }
                        }
                    })
                    if (region.groups.every(x => x.checked === true)) {
                        region.checked = true;
                    }
                    else {
                        region.checked = false;
                    }
                });
                if (response.every(x => x.checked === true)) {
                    setAllLocationsSelected(true);
                }
                else {
                    setAllLocationsSelected(false);
                }

                setRegionLocations(response);
                props.onChange(response);
            });
        };
    }


    useEffect(fetchData, [props.userId, props.chartType]);

    useEffect(() => {
        $(document).on('click', 'body .dropdown-menu', function (e) {
            e.stopPropagation();
        });
    });

    const [allLocationsSelected, setAllLocationsSelected] = useState(props.showAssignedLocations === true ? false : true);
    const toogleAll = () => {
        var newRegionLocations = regionsLocations.map(region => {
            region.checked = !allLocationsSelected;
            region.groups.map(facility => {
                facility.checked = !allLocationsSelected;
                return facility;
            });
            return region;
        });

        setRegionLocations(newRegionLocations);

        props.onChange(newRegionLocations);

        setAllLocationsSelected(!allLocationsSelected);
    }

    const changeRegion = (regionId) => {
        var newRegionLocations = regionsLocations.map(region => {
            let changedRegion = false;
            if (region.id === regionId) {
                region.checked = !region.checked;
                changedRegion = true;
            }
            region.groups.map(facility => {
                if (changedRegion) {
                    facility.checked = region.checked;
                }
                return facility;
            });

            return region;
        });
        if (regionsLocations.every(x => x.checked === true)) {
            setAllLocationsSelected(true);
        }
        else {
            setAllLocationsSelected(false);
        }

        setRegionLocations(newRegionLocations);

        props.onChange(newRegionLocations);
    }

    const changeFacility = (facilityId) => {
        var newRegionLocations = regionsLocations.map(region => {
            region.groups.map(facility => {
                if (facility.id === facilityId) {
                    facility.checked = !facility.checked;
                }
                return facility;
            });

            if (region.groups.every(x => x.checked === true)) {
                region.checked = true;
            }
            else {
                region.checked = false;
            }

            return region;
        });

        if (regionsLocations.every(x => x.checked === true)) {
            setAllLocationsSelected(true);
        }
        else {
            setAllLocationsSelected(false);
        }

        setRegionLocations(newRegionLocations);

        props.onChange(newRegionLocations);
    }

    return (
        <>
            <div
                className={props.className == null ? "col-md-auto pr-1 pt-2 pt-md-0 pb-1 pb-md-0" : props.className}
            >
                <div className="dropdown navbar">
                    <div
                        className={props.subClassName == null ? "select-location" : props.subClassName}
                        type="button"
                        id="dropdownSelectLocations"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span>Select Locations</span>
                        <span>
                            <img
                                src={require("../../img/down.svg")}
                                alt="down"
                            />
                        </span>
                    </div>

                    <div
                        className="dropdown-menu dropdown-select-locations"
                        aria-labelledby="dropdownSelectLocations"
                    >

                        <div className="form-group">
                            <input
                                id={props.showAssignedLocations == null ? "allLocationsSelector" : "allLocationsSelector-showAssigned"}
                                type="checkbox"
                                className="allLocations"
                                checked={allLocationsSelected ? "checked" : ""}
                                onChange={(event) => { toogleAll(); event.stopPropagation(); }} />
                            <label
                                className="checkbox checkbox-gray mb-0"
                                htmlFor={props.showAssignedLocations == null ? "allLocationsSelector" : "allLocationsSelector-showAssigned"}
                            >
                                <b>All Locations</b>
                            </label>
                        </div>

                        {regionsLocations &&
                            regionsLocations.map((region) => (
                                <div key={"region_" + region.id} className="section pl-2 pl-sm-3 select-all-wrap">
                                    <div className="form-group mt-2 mb-1">
                                        <input
                                            className="allLocations-sub-1"
                                            id={props.showAssignedLocations == null ? "region-" + region.id : "region-showAssigned-" + region.id}
                                            type="checkbox"
                                            checked={region.checked ? "checked" : ""}
                                            onChange={(event) => { changeRegion(region.id); event.stopPropagation(); }} />
                                        <label
                                            className="checkbox checkbox-gray mb-0"
                                            htmlFor={props.showAssignedLocations == null ? "region-" + region.id : "region-showAssigned-" + region.id}
                                        >
                                        </label>
                                        <p className="loc-sub-accordion mb-0" data-toggle="collapse" data-target={".collapse-" + region.id} aria-expanded="false" aria-controls="collapse-1">
                                            {region.name}
                                        </p>
                                    </div>
                                    {region.groups.map((location) => (
                                        <div
                                            key={"location_" + location.id}
                                            className={"pl-3 collapse  select-all-wrap-sub-1 collapse-" + region.id}
                                            id="select-all-wrap"
                                        >
                                            <div className="form-group pb-1 pt-1">
                                                <input
                                                    type="checkbox"
                                                    id={props.showAssignedLocations == null ? "locations-showAssigned-" + location.id : "locations-" + location.id}
                                                    checked={location.checked ? "checked" : ""}
                                                    onChange={(event) => { changeFacility(location.id); event.stopPropagation(); }}
                                                />
                                                <label
                                                    className="checkbox checkbox-gray mb-0"
                                                    htmlFor={props.showAssignedLocations == null ? "locations-showAssigned-" + location.id : "locations-" + location.id}
                                                >
                                                    {location.name}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </ div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FacilitiesPicker;