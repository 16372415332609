import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ajaxCaller } from "../../ajaxCaller";
import { getAccessToken } from "../../authentification";
import {NavLink} from "react-router-dom";
import moment from "moment";
import NumberFormat from 'react-number-format';

const convertToDecimalWithComma = (value) => {
  return Math.round(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function findLineByLeastSquares(values_x, values_y) {
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let count = 0;

  /*
   * We'll use those variables for faster read/write access.
   */
  let x = 0;
  let y = 0;
  let values_length = values_x.length;

  if (values_length !== values_y.length) {
    throw new Error(
      "The parameters values_x and values_y need to have same size!"
    );
  }

  /*
   * Nothing to do.
   */
  if (values_length === 0) {
    return [[], []];
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = values_y[v];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  let m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  let b = sum_y / count - (m * sum_x) / count;

  /*
   * We will make the x and y result line now
   */
  let result_values_x = [];
  let result_values_y = [];

  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = x * m + b;
    result_values_x.push(x);
    result_values_y.push(y);
  }

  return [result_values_x, result_values_y];
}

const calculateDifference = (minuend, subtahend, reportType) => {
  let differenceData;
  //flip/reverse for some reports where the Total is NOT the main result/series but additional one
    if ([7].includes(reportType)) {
      differenceData = subtahend.map((point, index) => [
        point[0], // Assuming the first element in the data arrays is the period
        point[1] - (minuend[index] ? minuend[index][1] : 0), // Calculating the difference
      ]);
    }//for all others just this
    else {
      differenceData = minuend.map((point, index) => [
        point[0], // Assuming the first element in the data arrays is the period
        point[1] - (subtahend[index] ? subtahend[index][1] : 0), // Calculating the difference
      ]);
    }
    return differenceData
}

const Widget = (props) => {

  const chartPointFormatter = function () {
    if (widget.widgetTypeId === 3) {
      return `Conv: <b>${this.y}%</b><br/>`;
    }
    else
      if (widget.widgetTypeId === 4) {
        let value = "$" + Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Value: <b>  ${value} </b><br/>`;
      }
      else if (widget.widgetTypeId === 1) {
        let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Current Chats: <b>  ${value} </b><br/>`;
      }
      else if (widget.widgetTypeId === 2) {
        let value = this.y.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `New Appt. Requests: <b>  ${value} </b><br/>`;
      }
      else if (widget.widgetTypeId === 12 || widget.widgetTypeId === 16) {
        let value = this.y.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Minutes: <b>  ${value} </b><br/>`;
      }
      else if (widget.widgetTypeId >= 5 && widget.widgetTypeId <= 17) {
        let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `${this.series.name}: <b>  ${value} </b><br/>`;
      }
  }


  const [widget] = useState(props.widget);
  const [chart, setChart] = useState(null);
  const [chartRangeType] = useState(2);
  const [columnsReferences] = useState([]);
  const [showPEPeriod, setShowPEPeriod] = useState(false);
  const [showAdSeries, setShowAdSeries] = useState(false);

  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      backgroundColor: "rgba(64,66,85,0.95)",
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: '15px'
      },
      borderRadius: 0,
      headerFormat: '',
      pointFormatter: chartPointFormatter,
      footerFormat: '<span style="">{point.key}</span><br/>',
    },
    chart: {
      height: "130px",
      events: {
        load() {
          this.showLoading();
          setTimeout(this.hideLoading.bind(this), 2000);
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: "",
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    series: [
      {
        name: "Total",
        type: widget.isLineChart ? "areaspline" : "column",
        fillColor: "rgba(119, 181, 223, 0.1)",
        lineWidth: 1,
        borderWidth: 1,
      },

    ],
  });


  let entityType = 'Threads'
  let valueName = 'threadsNumber'
  if ([6, 7, 8, 14, 15, 17].includes(widget.widgetTypeId)) {
    entityType = 'Calls'
    valueName = 'callsNumber'
  }
  else if ([16, 12].includes(widget.widgetTypeId)) {
    entityType = 'Calls'
    valueName = 'averageDurationsNumber'
  }
  else if ([9, 10, 11, 13].includes(widget.widgetTypeId)) {
    entityType = 'Appointments'
    valueName = 'appointmentsNumber'
  }

  const [data, setData] = useState(null);
  const [isLineChart] = useState(true);
  const afterChartCreated = (chart) => {
    setChart(chart);
  };

  const applyWithAfterHours = () => {

    const chartOptionsNew = { ...chartOptions };
    if (
      widget &&
      widget.isExpanded &&
      widget.widgetType.afterHours &&
      chartOptionsNew.series
    ) {
      if (props.withAfterHours) {
        let seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "After Hours"
        );
        if (seriesIndex === -1) {
          chartOptionsNew.tooltip = null;
          refreshChart();
          return;
        }
        seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "After Hours"
        );
        if (chartOptionsNew.series[seriesIndex] != null) {
          chartOptionsNew.series[
            seriesIndex
          ].data = data.afterHoursResults.map((a) => [
            a.period,
            a[valueName],
          ]);
          chartOptionsNew.tooltip = {
            pointFormatter: function () {
              let result = "";

              if (columnsReferences[this.x] != null) {
                columnsReferences[this.x].scrollIntoView({
                  block: "nearest",
                  alignToTop: false,
                });
              }

              if (
                chartOptionsNew == null ||
                chartOptionsNew.series[seriesIndex] == null ||
                chartOptionsNew.series[seriesIndex].data == null ||
                chartOptionsNew.series[seriesIndex].data.length === 0 || seriesIndex < 0 ||
                props.withAfterHoursDiff === false
              ) {
                let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                return `After Hours :<b> ${value} </b><br/>`;
              }
              const totalValue = chartOptionsNew.series[0].data[this.x][1];
              const afterHoursValue = chartOptionsNew.series[seriesIndex].data[this.x][1];
              const normalHoursValue =
                parseInt(totalValue) - parseInt(afterHoursValue);
              result =
                totalValue.toFixed(2) +
                " (Total) - " +
                afterHoursValue.toFixed(2) +
                " (After Hours) = " +
                normalHoursValue.toFixed(2) +
                " (Business Hours)\n";
              return result;
            },
          };
        }
        setChartOptions(chartOptionsNew);
      } else {
        var seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "After Hours"
        );
        if (
          chartOptionsNew.series[seriesIndex] &&
          chartOptionsNew.series[seriesIndex].data
        ) {
          chartOptionsNew.series[seriesIndex].data = [];
          chartOptionsNew.tooltip = null;
        }
        setChartOptions(chartOptionsNew);
      }
    }
  };

  const applyWithTrendLine = () => {
    const chartOptionsNew = { ...chartOptions };
    if (widget && widget.isExpanded && chartOptionsNew.series) {
      if (props.withTrendLine) {
        var seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (seriesIndex === -1) {
          refreshChart();
          return;
        }
        seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (chartOptionsNew.series[seriesIndex] != null) {
          const formattedResponse = data.results.map((a) => [
            a.period,
            a[valueName],
          ]);
          const xPoints = formattedResponse.map((value, index) => index);
          const yPoints = formattedResponse.map((a) => a[1]);
          const trendLineYPoints = findLineByLeastSquares(xPoints, yPoints)[1];
          if (chartOptionsNew.series[seriesIndex] != null) {
            chartOptionsNew.series[
              seriesIndex
            ].data = trendLineYPoints.map((value, index) => [
              xPoints[index],
              parseFloat(value.toFixed(2)),
            ]);
          }
        }
        setChartOptions(chartOptionsNew);
      } else {
        seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (
          chartOptionsNew.series[seriesIndex] &&
          chartOptionsNew.series[seriesIndex].data
        ) {
          chartOptionsNew.series[seriesIndex].data = [];
          setChartOptions(chartOptionsNew);
        }
        refreshChart();
      }
    }
  };

  const applyshowPEPeriod = () => {
    const chartOptionsNew = { ...chartOptions };
    if (widget && widget.isExpanded && chartOptionsNew.series && showPEPeriod !== props.showPEPeriod) {
      setChartOptions(chartOptionsNew);
      setShowPEPeriod(!showPEPeriod)
      refreshChart();
    }
  };

  const applyshowAdSeries = () => {
    const chartOptionsNew = { ...chartOptions };
    if (widget && widget.isExpanded && chartOptionsNew.series && showAdSeries !== props.showAdSeries) {
      setChartOptions(chartOptionsNew);
      setShowAdSeries(!showAdSeries)
      refreshChart();
    }
  };

  const afterHoursCallback = () => {
    applyWithAfterHours();
  };
  useEffect(afterHoursCallback, [props.withAfterHours, props.withAfterHoursDiff]);

  const showPEPeriodCallback = () => {
    applyshowPEPeriod();
  };
  useEffect(showPEPeriodCallback, [props.showPEPeriod]);

  const showAdSeriesCallback = () => {
    applyshowAdSeries();
  };
  useEffect(showAdSeriesCallback, [props.showAdSeries]);

  const trendLineCallback = () => {
    applyWithTrendLine();
  };
  useEffect(trendLineCallback, [props.withTrendLine]);

  const extractPeriodString = () => {
    switch (widget.periodType.toString()) {
      case "1": return 'days';
      case "2": return 'weeks';
      case "3": return 'months';
      case "4": return 'quarter';
      case "5": return 'years';
      default: return 'days';
    }
  }



  const refreshChart = () => {
    chart && chart.showLoading();
    const chartOptionsNew = {
      tooltip: {
        backgroundColor: "rgba(64,66,85,0.95)",
        borderWidth: 1,
        shadow: true,
        useHTML: true,
        style: {
          padding: "15px",
        },
        borderRadius: 15,
        headerFormat: "",
        pointFormatter: chartPointFormatter,
        footerFormat: '<span style="">{point.key}</span><br/>',
      },
      chart: {
        height: "150px",
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          viewDistance: 25,
          depth: 40
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      loading: {
        hideDuration: 1000,
        showDuration: 1000,
      },
      xAxis: {
        tickInterval: 1,
        labels: {
          enabled: widget.showXLabels,
          formatter: (obj) => {
            if (
              chartOptionsNew == null ||
              chartOptionsNew.series == null ||
              chartOptionsNew.series[0] == null ||
              chartOptionsNew.series[0].data[obj.value] == null ||
              chartOptionsNew.series[0].data[obj.value][0] == null
            ) {
              return "";
            } else {
              return chartOptionsNew.series[0].data[obj.value][0];
            }
          },
        },
      },
      yAxis: {
        title: "",
        labels: {
          enabled: widget.showYLabels, //Branko temp
          formatter: (obj) => {
            if (props.reportType === 3) {
              return obj.value + "%"
            }
            else if (props.reportType === 4) {
              return "$" + convertToDecimalWithComma(obj.value);
            }
            else {
              return convertToDecimalWithComma(obj.value);
            }
          }
        },
        endOnTick: true,
        maxPadding: 0,
        max: null,
        tickInterval: 1,
        minorTickLength: 0,
        tickLength: 0,
        visible: widget.showYLabels,
      },
      plotOptions: {
        column: {
          stacking: null,
          grouping: true,
          pointWidth: 25, // Set a fixed width for the columns in pixels
          pointPadding: 0, // Increase or decrease the padding between columns
          groupPadding: 0.05 // Set the padding between groups of columns
        },
        series: {
          animation:false, 
          marker: {
            radius: 3,
            enabled: true,
            symbol: "circle"
          },
        },
      },
      series: [
        {
          name: props.reportType === 3
            ? "Conv"
            : props.reportType === 4
              ? "Value"
              : props.reportType === 1
                ? "Current Chats"
                : props.widget.widgetType.name,
          lineColor: isLineChart ? "#4C95E3" : "#3495E6",//"#1A2430" : "#3495E6",
          color: isLineChart ? "#4C95E3" : "#3495E6",
          type: isLineChart ? "areaspline" : "column",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(76, 149, 227, 0.5)'], // Start color at 100% opacity
              [1, 'rgba(76, 149, 227, 0)']  // End color at 0% opacity
            ]
          },//"rgba(76, 149, 227, 0.1)",
          lineWidth: 1,
          borderWidth: 0,
          tooltip: {
            backgroundColor: "rgba(64,66,85,0.95)",
            borderWidth: 1,
            shadow: true,
            useHTML: true,
            style: {
              padding: "15px",
            },
            borderRadius: 15,
            headerFormat: "",
            pointFormatter: chartPointFormatter,
            footerFormat: '<span style="">{point.key}</span><br/>',
          }
        },
      ],
    };


    if (props.widget.widgetType.additionalSeries && props.widget.widgetType.additionalSeries.length > 0 && props.showAdSeries) {
      const arrSeries = props.widget.widgetType.additionalSeries.split(/,\s+/).map(item => item.trim());
      let i = 0;
      arrSeries.forEach(item => {
        i++;

        var lineCol = i === 1 ? "#E88C1E" : "#04BD81"
        var barCol = "rgba(136, 206, 251, 0.5)"
        var fillColor = i === 1 ?
          {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(232, 140, 30, 0.5)'], // Start color at 100% opacity
              [1, 'rgba(232, 140, 30, 0)']  // End color at 0% opacity
            ]
          }
          :
          {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(4, 189, 129, 0.5)'], // Start color at 100% opacity
              [1, 'rgba(4, 189, 129, 0)']  // End color at 0% opacity
            ]
          }
        chartOptionsNew.series.push({
          index: `series0${i}`,
          name: item,
          lineColor: isLineChart ? lineCol : barCol,
          color: isLineChart ? lineCol : barCol,
          type: isLineChart ? "areaspline" : "column",
          fillColor: fillColor,//barCol, //"rgba(136, 206, 251, 0.25)",
          lineWidth: 1,
          borderWidth: 0,
          marker: {
            radius: 4,
            enabled: true,
            symbol: "diamond"
          },
          animation:false,
          tooltip: {
            backgroundColor: "rgba(64,66,85,0.95)",
            borderWidth: 1,
            shadow: true,
            useHTML: true,
            style: {
              padding: "15px",
            },
            borderRadius: 15,
            headerFormat: "",
            pointFormatter: chartPointFormatter,
            footerFormat: '<span style="">{point.key}</span><br/>',
          },
        });
      });
    }

    // After hours 
    if (props.withAfterHours) {
      chartOptionsNew.series.push({
        name: "After Hours",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgba(177, 179, 201, 0.45)'], // Start color at 100% opacity
            [1, 'rgba(177, 179, 201, 0)']  // End color at 0% opacity
          ]
        },
        lineColor: "#B1B3C9",
        color: "#B1B3C9",
        lineWidth: 1,
        borderWidth: 1,
        type: widget.isLineChart ? "areaspline" : "column", 
        animation:true, 
      });
    }

    //Trend Line
    let trendLineSeriesIndex = 1;
    if (props.withTrendLine) {
      chartOptionsNew.series.push({
        name: "Trends",
        lineColor: "rgba(249, 25, 25, 1)",
        color: "rgba(249, 25, 25, 1)",
        type: "line",
        lineWidth: 2,
        borderWidth: 1,
        marker: { enabled: false },
        animation:true, 
      });

      trendLineSeriesIndex = chartOptionsNew.series.length - 1;
    }


    ajaxCaller(`Dashboard/Get${entityType}Statistic`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        type: widget.periodType,
        dateFrom: moment().add(- widget.lastPeriodRange, extractPeriodString()),
        dateTo: moment(),
        isLeads: widget.widgetTypeId > 1,
        reportType: widget.widgetTypeId,
        withAfterHours: props.withAfterHours,
        showPEPeriod: props.showPEPeriod,
        groupIds: props.groupIds
      }),
    }).then((response) => {
      if (response.results != null) {
        const formattedResponse = response.results.map((a) => [
          a.period,
          a[valueName],
        ]);
        chartOptionsNew.series[0].data = formattedResponse;

        let formattedResponse01;
        let series1Index = chartOptionsNew.series.findIndex(
          (x) => x.index === "series01"
        );
        if (series1Index > 0 && props.showAdSeries) {
          if (response.results01) {
            formattedResponse01 = response.results01.map((a) => [
              a.period,
              a[valueName],
            ]);
            chartOptionsNew.series[series1Index].data = formattedResponse01;
          }
        }

        let formattedResponse02;
        let series2Index = chartOptionsNew.series.findIndex(
          (x) => x.index === "series02"
        );
        if (series2Index > 0 && props.showAdSeries) {
          if (response.results02) {
            formattedResponse02 = response.results02.map((a) => [
              a.period,
              a[valueName],
            ]);
            chartOptionsNew.series[series2Index].data = formattedResponse02;
          }
          else {
            if (formattedResponse01 && formattedResponse) {
              const differenceData = calculateDifference(formattedResponse, formattedResponse01, widget.widgetTypeId)
              chartOptionsNew.series[series2Index].data = differenceData;
            }
          }
        }

        //AfterHours filter by field
        if (props.withAfterHours && widget.widgetType.afterHours) {
          let seriesIndex = chartOptionsNew.series.findIndex(
            (x) => x.name === "After Hours"
          );
          if (chartOptionsNew.series[seriesIndex] != null) {
            chartOptionsNew.series[seriesIndex].data = response.afterHoursResults.map(
              (a) => [a.period, a[valueName]]
            );
          }
        }

        // Calculating Line Trend
        if (props.withTrendLine) {
          const xPoints = formattedResponse.map((value, index) => index);
          const yPoints = formattedResponse.map((a) => a[1]);
          const trendLineYPoints = findLineByLeastSquares(
            xPoints,
            yPoints
          )[1];
          if (chartOptionsNew.series[trendLineSeriesIndex] != null) {
            chartOptionsNew.series[
              trendLineSeriesIndex
            ].data = trendLineYPoints.map((value, index) => [
              xPoints[index],
              parseFloat(value.toFixed(2)),
            ]);
          }
        }

        setData(response);
        setChartOptions(chartOptionsNew);
        chart && chart.hideLoading();
      }
    }).catch(() => {
      console.log("Not allowed")
    });

  };

  const hookCallbackGroups = () => {
    if (!props.editMode)
      refreshChart();
  }

  useEffect(hookCallbackGroups, [
    props.groupIds
  ]);

  const hookCallback = () => {
    if (props.editMode)
      refreshChart();
  };
  useEffect(hookCallback, [
    chartRangeType,
    isLineChart,
    props.widget.periodType,
    props.widget.chartType,
    props.widget.isExpanded,
    props.widget.isLineChart,
    props.widget.lastPeriodRange,
    props.widget.showXLabels,
    props.widget.showYLabels,
    props.widget.additionalSeries,
    props.groupIds
  ]);

  const calculateTotalValues = () => {
    var total = 0;
    let count = 0;
    data && data.results.forEach((x) => {
      total += x[valueName];
      count++;
    });
    if (valueName.includes("average")) {
      total = count > 0 ? total / count : 0;
      return total.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    return Math.round(total).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <div key={"widget_key_" + widget.id}>
      <NavLink

        id={"widget_" + widget.id}
        key={"widget_" + widget.id}
        onClick={(e) => {
          if (props.editMode) {
            e.preventDefault();
          }
          else {
            document.getElementById("nav-reports-page").className =
              "left-side-nav-link active";
            document.getElementById("nav-dashboard-page").className =
              "left-side-nav-link";
          }
        }}
        to={{
          pathname: "/reports",

          dateRange: {
            dateFrom: moment().add(- widget.lastPeriodRange, extractPeriodString()),
            dateTo: moment(),
          },
          withTrendLine: props.withTrendLine,
          withAfterHours: props.withAfterHours,
          showPEPeriod: props.showPEPeriod,
          groupIds: props.groupIds,
          isLineChart: widget.isLineChart,
          chartType: widget.widgetTypeId === 5 ? 1 : widget.widgetTypeId,
          chartRangeType: widget.periodType,
          showAfterHours: widget.widgetType.afterHours,
          withAfterHoursDiff: props.withAfterHoursDiff,
          allWidgets: props.allWidgets,
          widgetTypes: props.widgetTypes,
          additionalSeries: widget.widgetType.additionalSeries,
          showAdSeries: props.showAdSeries
        }}
      >
        <div
          className="modal fade"
          id={"modalDelete_" + widget.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalDelete"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <h5 className="mb-2 pb-1">Delete widget</h5>
                    </div>
                    <div className="col-12 text-center">
                      <p className="mb-0">
                        You are about to delete the widget:
                        <br />
                        <strong>{widget.widgetType.name} </strong>
                      </p>
                      <p className="mb-0">Are you sure you wish to continue?</p>
                    </div>
                    <div className="col-12 text-center mt-4">
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        aria-label="Close"
                        data-dismiss="modal"
                        className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                        onClick={() => props.removeWidget()}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            widget.isExpanded === true
              ? "section background-white px-3 px-sm-4 pt-1 pt-sm-3 mb-3 mb-md-4 home-d-box"
              : "section background-white mb-3 mb-md-4 home-d-box"
          }
          style={widget.isExpanded === true ? { paddingLeft: "1rem", paddingTop: "0.5rem" } : { paddingLeft: "1rem", paddingTop: "0.5rem", paddingRight: "1.5rem" }}
        >
          <div
            className={
              widget.isExpanded === true
                ? "section"
                : "section py-md-2 my-sm-1"
            }
          >
            <div
              className="row"
              style={widget.widgetType.displayTotal === true ? {} : { height: "38px" }}
            >
              <div className="col-sm align-self-center">
                <h5 className="mb-2 mb-xl-0 metric-name">{widget.widgetType.name}</h5>
              </div>
              <div className="col-sm align-self-right">
                <div className="section">
                  <div className="edit-remove-links">
                    <p className="mb-0 font-size-16 font-weight-700">
                      <span
                        data-toggle="modal"
                        data-target="#editMetric"
                        className="link-dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.selectForEdit()}
                      >
                        Edit
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target={"#modalDelete_" + widget.id}
                        className="link-dark ml-2"
                      >
                        Remove
                      </span>
                    </p>
                  </div>
                  {widget.widgetType.displayTotal && (
                    <div className="row">
                      <div className="col text-xl-right pr-0 pr-sm-3">
                        <h6 className="mb-0" style={{ textAlign: "right" }}>
                          {widget.widgetTypeId && (widget.widgetTypeId.toString() === "3" ? (calculateTotalValues() + "%") : widget.widgetTypeId.toString() === "4" ? (<NumberFormat value={calculateTotalValues()} displayType={'text'} thousandSeparator={true} prefix={'$'} />) : (calculateTotalValues()))}
                        </h6>
                        <p className="mb-0 font-size-12 line-height-22 opacity-70" style={{ textAlign: "right" }}>
                          Last {widget.lastPeriodRange} {widget.lastPeriodRange < 2 ? extractPeriodString().replace("s", "") : extractPeriodString()}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(
            <div className="section mt-1" style={{ display: widget.isExpanded ? "" : "none" }}>
              <figure className="highcharts-figure chart-home">
                <div
                  id="chat-chart"
                >
                  <HighchartsReact
                    allowChartUpdate={true}
                    highcharts={Highcharts}
                    options={chartOptions}
                    callback={afterChartCreated}
                  />
                </div>
              </figure>
            </div>
          )}
        </div>
      </NavLink>
    </div>
  );
};
export default Widget;
