import React, { useEffect, useRef } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import 'bootstrap-daterangepicker/daterangepicker.css';

const KontakDateRangePicker = (props) => {

  const myRef = useRef();

  useEffect(() => {
    props.setPickerRef(myRef);
  })
    return (<>
    <DateRangePicker ref={myRef} onApply={props.onChange} initialSettings={{
      startDate: props.startDate,
      endDate: props.endDate,
      autoApply: true,
      ranges: {
        'LAST 30 DAYS': [moment().subtract(29, 'days'), moment()],
        'LAST 90 DAYS': [moment().subtract(89, 'days'), moment()],
        'LAST 365 DAYS': [moment().subtract(364, 'days'), moment()],
        'YTD': [moment().startOf('year'), moment()],
        'ALL': [moment().startOf('year'), moment()],
      }
    }}>
      <input ref="input" type="text" className="form-style daterange-style" />
    </DateRangePicker></>)

}

export default KontakDateRangePicker;
