import React, { useEffect, useState } from "react";
import { ajaxCaller,ajaxNonJsonCaller,authHeaders } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Highlighter from "react-highlight-words";
import KontakDateRangePicker from "./components/kontakDateRangePicker";
import FacilitiesPicker from "./components/facilitiesPicker";
import PerfectScrollbar from "react-perfect-scrollbar";
import $ from "jquery";
import Cookies from "js-cookie";

const ChartArchive = (props) => {
  const [keyword, setKeyword] = useState(props.searchKeyword || "");
  const [countOfThreads, setCountOfThreads] = useState(0);
  const [reportFilters] = useState(Cookies.get("reportFilters") == null ? null : JSON.parse(Cookies.get("reportFilters")));

  const returnIfExistDateRangeFilter = () => {
    var tmpStart = new Date();
    var tmpEnd = new Date();

    tmpStart.setDate(tmpEnd.getDate() - 30);
    if (reportFilters) {
      return (reportFilters["archive"].dateRange == null ? {

        dateFrom: tmpStart.toLocaleDateString("en-US"),
        dateTo: tmpEnd.toLocaleDateString("en-US"),
      } : reportFilters["archive"].dateRange)
    }
    return {
      dateFrom: tmpStart.toLocaleDateString("en-US"),
      dateTo: tmpEnd.toLocaleDateString("en-US"),
    };
  }

  const [dateRange, setDateRange] = useState(props.dateRange == null ? returnIfExistDateRangeFilter() : {
    dateFrom: new Date(props.dateRange.dateFrom).toLocaleDateString("en-US"),
    dateTo: new Date(props.dateRange.dateTo).toLocaleDateString("en-US")
  });
  
  const [groupIds, setGroupIds] = useState(null);
  const facilitiesChanged = (regionFacilities) => {
    var newGroupIds = [];
    regionFacilities.forEach((region) => {
      //if (region.checked) {
        region.groups.forEach((group) => {
          if (group.checked) {
            newGroupIds.push(group.id);
          }
        });
      //}
    });

    console.log(newGroupIds);

    setGroupIds(newGroupIds);
  };
  const getCountOfThreadsForPeriod = (propsKeyword) => {
    ajaxCaller("Thread/CountOfThreads", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        startAt: dateRange.dateFrom,
        endAt: dateRange.dateTo,
        groupIds: groupIds,
        pageSize: 10,
        searchString: propsKeyword == null ? keyword : propsKeyword,
      }),
    }).then((response) => {
      setCountOfThreads(response);
    });
  };

  const [threads, setThreads] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const getThreadsForPeriod = (propsKeyword) => {
    setThreads(null);
    ajaxCaller("Thread/" + currentPage, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        startAt: dateRange.dateFrom,
        endAt: dateRange.dateTo,
        groupIds: groupIds,
        pageSize: 10,
        sortRuleNumber: 1,
        searchString: propsKeyword == null ? keyword : propsKeyword,
      }),
    }).then((response) => {
      setThreads(response);
    });
  };

  const getCountOfThreadsCallback = (propsKeyword) => {
    if (groupIds != null) {
      getCountOfThreadsForPeriod(propsKeyword);
    }
  };
  useEffect(getCountOfThreadsCallback, [dateRange, groupIds]);

  const getThreadsCallback = (propsKeyword) => {
    if (groupIds != null) {
      getThreadsForPeriod(propsKeyword);
    }
  };
  useEffect(getThreadsCallback, [currentPage, dateRange, groupIds]);

  const propsKeywordChangedCallback = () => {
    getCountOfThreadsCallback(
      props.searchKeyword == null ? "" : props.searchKeyword
    );
    getThreadsCallback(props.searchKeyword == null ? "" : props.searchKeyword);
    setKeyword(props.searchKeyword == null ? "" : props.searchKeyword);
  }
  useEffect(propsKeywordChangedCallback, [props.searchKeyword]);

  const [threadConversation, setThreadConversation] = useState(null);
  const [conversationThreadId, setConversationThreadId] = useState(null);

  const getThreadConversation = () => {
    ajaxCaller("Thread/" + conversationThreadId, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      setThreadConversation(response);
    });
  };

  const getConversationCallback = () => {
    if (conversationThreadId != null) {
      getThreadConversation();
    }
  };
  useEffect(getConversationCallback, [conversationThreadId]);

  const addSearchKeyword = () => {
    ajaxCaller("Search", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "post",
      body: JSON.stringify({
        keyword: keyword,
      }),
    }).then((response) => {
      if (!response.isError) {
        props.updateArchive();
      } else {
      }
    });
  };

  const extractMessage = (thread) => {
    var indexOf = thread.messages.indexOf(keyword);
    var message = thread.messages.substring(
      indexOf - 10 < 1 ? 0 : indexOf - 20,
      indexOf + 70 > thread.messages.length
        ? thread.messages.length
        : indexOf + 70
    );
    return "... " + message + " ...";
  };

  const [, setDatePickerRef] = useState(null);
  const setPickerRef = (ref) => {
    setDatePickerRef(ref);
  };

  const downloadPdf = () => {
    ajaxNonJsonCaller("Pdf/ExportConversation/" + conversationThreadId, {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      response.blob().then((blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    });
  };

  return (
    <>
      <div className="row mt-3 mt-lg-4">
        <div className="col-sm-5 col-md-4 col-lg-2 align-self-center pr-lg-0">
          <div className="form-group">
            <input
              type="text"
              className="form-style border-0 small-32 white"
              placeholder="Enter your keyword..."
              value={keyword}
              onChange={(event) => setKeyword(event.target.value)}
              onKeyPress={(event) => {
                var key = event.which;
                if (key === 13 || key === 14) {
                  getCountOfThreadsCallback();
                  getThreadsCallback();
                  addSearchKeyword();
                }
              }}
            />
          </div>
        </div>
        <FacilitiesPicker onChange={facilitiesChanged}></FacilitiesPicker>
        <div className="col-lg align-self-center mt-2 mt-lg-0">
          <span
            className="btn btn-blue btn-width-80 btn-height-32 border-0 font-size-14"
            onClick={() => {
              getCountOfThreadsCallback();
              getThreadsCallback();
              addSearchKeyword();
            }}
          >
            Search
          </span>
        </div>
        <div className="col-auto align-self-center mt-2 mt-lg-0">
          <div className="section">
            <KontakDateRangePicker
            
              setPickerRef={setPickerRef}
              startDate={dateRange.dateFrom}
              endDate={dateRange.dateTo}
              onChange={(evt, picker) => {
                setDateRange({
                  dateFrom: picker.startDate
                    .toDate()
                    .toLocaleDateString("en-US"),
                  dateTo: picker.endDate.toDate().toLocaleDateString("en-US"),
                });
              }}
            />
            {/* <label for="daterange"><img src={require("../img/down.svg")} alt="down"/></label> */}
          </div>
        </div>
      </div>
      <div className="row mt-2 pt-1">
        <div className="col-12 chat-archive-table">
          <div className="section background-white">
            <div className="row py-2">
              <div className="col align-self-center">
                <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                  Chats &ensp;
                  <i className="font-weight-500"> {countOfThreads} Results</i>
                </p>
              </div>
            </div>
          </div>
          <div className="section d-none d-lg-block">
            <div className="row">
              <div className="col-lg-2 align-self-center pr-lg-0">
                <div className="tab-box-blue">
                  <p className="mb-0 font-size-14 line-height-18 font-weight-500">
                    Date & Time
                  </p>
                </div>
              </div>
              <div className="col-lg-2 align-self-center px-lg-0">
                <div className="tab-box-blue justify-content-lg-center">
                  <p className="mb-0 font-size-14 line-height-18 font-weight-500">
                    Name
                  </p>
                </div>
              </div>
              <div className="col-lg-2 align-self-center px-lg-0">
                <div className="tab-box-blue justify-content-lg-center">
                  <p className="mb-0 font-size-14 line-height-18 font-weight-500">
                    Messages Exchanged
                  </p>
                </div>
              </div>
              <div className="col-lg-6 align-self-center pl-lg-0">
                <div className="tab-box-blue justify-content-lg-center no-tab-box-border">
                  <p className="mb-0 font-size-14 line-height-18 font-weight-500">
                    Conversation
                  </p>
                </div>
              </div>
            </div>
          </div>

          {threads &&
            threads.map((thread) => (
              <div key={"thread_" + thread.id} className="section background-white py-2 py-lg-0 tab-ch-all-sec">
                <div className="row">
                  <div className="col-lg-2 align-self-center pr-lg-0">
                    <div className="tab-box-white">
                      <p className="mb-0 font-size-14 line-height-18 font-weight-500">
                        <span className="mr-1 d-lg-none">Date & Time:</span>
                        <Moment
                          format="ddd, MMM DD - hh:mm A"
                          date={thread.startAt}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 align-self-center px-lg-0">
                    <div className="tab-box-white justify-content-lg-center">
                      <p className="mb-0 font-size-14 line-height-18">
                        <span className="mr-1 font-weight-500 d-lg-none">
                          Name:
                        </span>
                        {thread.agentName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 align-self-center px-lg-0">
                    <div className="tab-box-white justify-content-lg-center">
                      <p className="mb-0 font-size-14 line-height-18">
                        <span className="mr-1 font-weight-500 d-lg-none">
                          Messages Exchanged:
                        </span>
                        {thread.messageCount}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center pl-lg-0">
                    <div
                      className={
                        thread.messages == null
                          ? "tab-box-white justify-content-lg-center no-tab-box-border"
                          : "tab-box-white justify-content-lg-between no-tab-box-border"
                      }
                    >
                      {thread.messages && (
                        <>
                          <p className="mb-0 font-size-14 line-height-18" data-toggle="modal"
                            onClick={() => setConversationThreadId(thread.id)}
                            data-target="#chatConv">
                            <Highlighter
                              className="showConversation"
                              highlightStyle={{
                                background: "white",
                                color: "#404255",
                                fontWeight: "bold",
                              }}
                              searchWords={keyword.split(" ")}
                              autoEscape={true}
                              textToHighlight={extractMessage(thread)}
                            />
                          </p>
                        </>
                      )}
                      {
                        (!keyword || keyword == null) && (<p className="mb-0 font-size-14 line-height-18">
                          <span
                            className="showConversation"
                            data-toggle="modal"
                            onClick={() => setConversationThreadId(thread.id)}
                            data-target="#chatConv"
                          >
                            View conversation
                        </span>
                        </p>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="section pt-2 mt-1">
            <div className="row justify-content-center justify-content-sm-end">
              <div className="col-auto">
                <Pagination
                  innerClass="pag-tab-links"
                  itemClass="page-item"
                  activePage={currentPage}
                  itemsCountPerPage={10}
                  totalItemsCount={countOfThreads}
                  nextPageText={"NEXT"}
                  prevPageText={"PREV"}
                  firstPageText={"FIRST"}
                  lastPageText={"LAST"}
                  hideFirstLastPages={false}
                  onChange={(pageNumber) => setCurrentPage(pageNumber)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal modal-metric fade"
        id="chatConv"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="chatConv"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => $('#chatConv').modal('toggle')}
                ></button>
                {threadConversation && (<div className="row">
                  <div className="col-12">
                    <h5 className="mb-0 font-weight-700">
                      {threadConversation.liveChatThreadId}{threadConversation.messages.find(x => x.authorName !== "Visitor") == null ? '' : ' - ' + threadConversation.messages.find(x => x.authorName !== "Visitor").authorName}
                    </h5>
                    <p className="mb-1 font-size-15">
                      <span className="font-weight-500">Facility:</span> {threadConversation.facilityName}
                    </p>
                    <p
                      className="mb-3 font-size-14">
                      <Moment
                        format="ddd, MMM DD - hh:mm A"
                        date={
                          threadConversation &&
                          threadConversation.messages[0].date
                        }
                      />
                    </p>
                  </div>
                </div>)}
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="chat-view-wrapper">
                      {threadConversation && (<PerfectScrollbar className="chat-view-wrap ps">
                        {threadConversation &&
                          threadConversation.messages.map((message) => {
                            return message.userType === "agent" ? (
                              <div key={message.id} className="row mb-2 justify-content-end">
                                <div className="col-auto">
                                  <div className="section blue-chat">
                                    <p className="mb-1 font-size-13 line-height-22 font-weight-700">
                                      {message.authorName}
                                    </p>
                                    <p className="mb-0 font-size-14 line-height-22">
                                      {keyword === "" ? (
                                        message.text
                                      ) : (
                                        <Highlighter
                                          activeStyle={{
                                            wordBreak: "break-all",
                                            wordWrap: "break-word",
                                          }}
                                          highlightStyle={{
                                            background:
                                              "rgba(255, 221, 65, 0.6)",
                                            color: "black",
                                          }}
                                          searchWords={[keyword]}
                                          autoEscape={true}
                                          textToHighlight={message.text}
                                        />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div key={message.id} className="row mb-2" style={{ marginLeft: "0px" }}>
                                <div className="col-auto pl-sm-0">
                                  <div className="section gray-chat">
                                    <p className="mb-1 font-size-13 line-height-22 font-weight-700">
                                      {message.authorName}
                                    </p>
                                    <p className="mb-0 font-size-14 line-height-22">
                                      {keyword === "" ? (
                                        message.text
                                      ) : (
                                        <Highlighter
                                          activeStyle={{
                                            wordBreak: "break-all",
                                            wordWrap: "break-word",
                                          }}
                                          highlightStyle={{
                                            background:
                                              "rgba(255, 221, 65, 0.6)",
                                            color: "black",
                                          }}
                                          searchWords={[keyword]}
                                          autoEscape={true}
                                          textToHighlight={message.text}
                                        />
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </PerfectScrollbar>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto align-self-center">
                    <span className="link-download font-size-13 mr-2 pr-1" onClick={() => downloadPdf()}>
                      <img
                        src={require("../img/download.svg")}
                        alt=""
                        className="mr-1"
                      />
                      Export (PDF)
                    </span>
                  </div>
                  <div className="col text-right">
                    <button
                      type="button"
                      data-dismiss="modal"
                      onClick={() => {
                        setThreadConversation(null);
                        setConversationThreadId(null);
                      }}
                      aria-label="Close"
                      className="btn btn-blue btn-width-80 btn-height-32 border-0 font-size-13"
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartArchive;
