import React, { Component } from "react";
import { inputChange } from "../utilities";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import InputMask from "react-input-mask";
import { debounce } from "lodash";
import Error from "../pages/error";

class AppointmentRequestsTypes {}
AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichAcceptsThem = [{id:1, type:"Immediate Attention Required - Follow Up ASAP"}, {id:2, type:"Appointment Request"},{id:3, type:"New Inquiry from PetDesk Phones"}];
AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichNotAcceptThem = [{id:3, type:"New Inquiry from PetDesk Phones"}];

export default class SendNewLead extends Component {
  constructor(props) {   
    super(props);

    this.state = {
      liveChatThreadId: this.props.liveChatThreadId,
      selectedEmail: "",
      groups: this.props.groups,
      cityState: "",
      clientFirstName: "",
      clientLastName: "",
      contactNumber: "",
      emails: [],
      email: "",
      additionalInfo: "",      
      selectedGroupId: this.props.selectedGroupId,
      leadType: "New",
      petsName: "",
      petsYear: 0,
      petsBreed: "",
      petsMonth: 0,
      company: null,
      petsWeek: 0,
      communicationMethod: "Phone",
      companyId: this.props.companyId,
      disablePhone: false,
      sendButtonDebounceChanges: false,
      appointmentRequestTypes: this.props.acceptsAppointmentRequests ? AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichAcceptsThem : AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichNotAcceptThem,
      selectedAppointmentRequestTypeId: 1,
      errorMessage: "",
      requestFailed: null,
      canBeSend: false 
    };

    this.inputChange = inputChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.liveChatThreadId != null &&
      state.groups != null &&
      state.selectedGroupId != null
    ) {
      return {
        ...state,
        data:
          props.liveChatThreadId !== state.liveChatThreadId ||
          props.selectedGroupId !== state.selectedGroupId ||
          props.groups !== state.groups
            ? null
            : state.data,
        liveChatThreadId: props.liveChatThreadId,
        groups: props.groups,
        selectedGroupId: state.selectedGroupId,
        appointmentRequestTypes: props.acceptsAppointmentRequests ? AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichAcceptsThem : AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichNotAcceptThem,
      };
    } else {
      return {
        ...state,
        liveChatThreadId: props.liveChatThreadId,
        groups: props.groups,
        selectedGroupId: props.selectedGroupId,
        appointmentRequestTypes: props.acceptsAppointmentRequests ? AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichAcceptsThem : AppointmentRequestsTypes.appointmentRequestsTypesForGroupWhichNotAcceptThem,
      };
    }
  }

  clientTypeChange = (value) => {
    this.setState({
      leadType: value
    });
  };

  changeGroup = (event) => {
    const groupIdInDropDown = event.target.value;
    this.changeGroupInternal(groupIdInDropDown);
  };

  changeGroupInternal = (groupId) => {
    const groupsSelected = this.state.groups.filter(a => a.id.toString() === groupId.toString());
    let groupEmails = [];
    if (groupsSelected.length > 0) {
      groupEmails = groupsSelected[0].sendLeadsTo.split(',');
    }

    this.setState({
      selectedGroupId: groupId,
      emails: groupEmails
    });
  }

  methodOfCommunicationChange = (e) => {
    const { value } = e.target;
    this.setState({
      communicationMethod: value,
    });
  };

  getGroups = (companyId) => {
    if (companyId === -1) {
      this.setState({ groups: null });
    } else {
      ajaxCaller("Group/GetGroups/" + companyId + "?Simple=true", {
        headers: authHeaders,
        method: "get",
      }).then((response) => {
        if (!response.isError) {
          console.log('getGroups called');
          this.setState({
            groups: response,
          });
        }
      });
    }
  };

  canBeSend = () =>{
    var canBeSend = 
    (this.state.additionalInfo !== "" && this.state.additionalInfo != null)
    &&
    (this.state.appointmentRequestTypes !== "" && this.state.appointmentRequestTypes != null)
    &&
    (this.state.cityState !== "" && this.state.cityState != null )
    &&
    (this.state.clientFirstName !== "" && this.state.clientFirstName != null )
    &&
    (this.state.clientLastName !== "" && this.state.clientLastName != null)
    &&
    (this.state.communicationMethod !== "" && this.state.communicationMethod != null)
    &&
    (this.state.disablePhone === true ? true : (this.state.contactNumber !== "" && this.state.contactNumber != null))
    &&
    (this.state.email !== "" && this.state.email != null)
    &&
    (this.state.leadType !== "" && this.state.leadType != null)
    var selectedGroup = this.getSelectedGroup(this.state.selectedGroupId);
    if(selectedGroup && selectedGroup.companyUnionId !== 6 &&
      selectedGroup.companyUnionId !== 7 &&
      selectedGroup.companyUnionId !== 8 &&
      selectedGroup.companyUnionId !== 9 &&
      selectedGroup.companyUnionId !== 12)
      {
        return canBeSend && (this.state.petsBreed !== "" && this.state.petsBreed != null)
        &&
        (this.state.petsMonth !== "" && this.state.petsMonth != null)
        &&
        (this.state.petsName !== "" && this.state.petsName != null)
        &&
        (this.state.petsWeek !== "" && this.state.petsWeek != null)
        &&
        (this.state.petsYear !== "" && this.state.petsYear != null);
      }else{

        return canBeSend;
      }
  }

  getCompany = (companyId) => {
    ajaxCaller("Company/GetCompany/" + companyId, {
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        this.setState({
          company: response,
        });
      }
    });
  };

  disablePhoneChange = () => {
    this.setState({
      disablePhone: !this.state.disablePhone
    })
  }

  createLead = () => {
    if(!this.canBeSend())
    {
      this.setState({
        canBeSend: true
      })
      this.canBeSendChange();
    }else{
      this.setState({
        requestFailed: null
      })

      ajaxCaller("Lead", {
      headers: authHeaders,
      method: "post",
      body: JSON.stringify({
        liveChatThreadId: this.state.liveChatThreadId,
        companyId: this.state.companyId,
        groupId: this.state.selectedGroupId,
        cityState: this.state.cityState,
        leadType: this.state.leadType,
        clientFirstName: this.state.clientFirstName,
        clientLastName: this.state.clientLastName,
        contactNumber: this.state.disablePhone === true ? null : this.state.contactNumber,
        email: this.state.email,
        additionalInfo: this.state.additionalInfo,
        petsYear: this.state.petsYear,
        petsMonth: this.state.petsMonth,
        petsName: this.state.petsName,
        petsBreed: this.state.petsBreed,
        petsWeek: this.state.petsWeek,
        communicationMethod: this.state.communicationMethod,
        appointmentRequestTypeId: this.state.selectedAppointmentRequestTypeId,
        sentToEmail: this.state.selectedEmail
      }),
    }).then((response) => {
      if (!response.isError) {
        this.props.onCancel();
        this.setState({
          groups: null,
          cityState: "",
          selectedEmail: null,
          clientFirstName: "",
          clientLastName: "",
          contactNumber: "",
          email: "",
          additionalInfo: "",
          petsName: "",
          petsYear: "",
          petsMonth: "",
          petsBreed: "",
          petsWeek: "",
          communicationMethod: "Phone",
          disablePhone: false,
          sendButtonDebounceChanges: true,
          errorMessage: "",
          requestFailed: null
        });

        this.sendColorDebounceChange();
      }else{
        this.setState({
          requestFailed: true,
          errorMessage: response.message
        });
      }
    });
    }    
  };

  validatePertsYear = (value) => {
    this.setState({
      petsYear: value,
    });
  };

  validatePertsMonth = (value) => {
    this.setState({
      petsMonth: value,
    });
  };

  validatePertsWeek = (value) => {
    this.setState({
      petsWeek: value,
    });
  };

  sendColorDebounceChange = debounce(() => {
    this.setState({ sendButtonDebounceChanges: false });
  }, 2000);

  canBeSendChange = debounce(() => {
    this.setState({ canBeSend: false });
  }, 2000);

  cancelCreate = () => {
    this.setState({
      groups: null,
      selectedEmail: null,
      cityState: "",
      clientFirstName: "",
      clientLastName: "",
      contactNumber: "",
      email: "",
      additionalInfo: "",
      petsName: "",
      petsYear: "",
      petsMonth: "",
      petsBreed: "",
      petsWeek: "",
      disablePhone: false,
      communicationMethod: "Phone",
      errorMessage: "",
      reuqestFailed: null
    });
  };

  componentDidMount() {
    if (document.getElementsByClassName("App").length > 0) {
      document
        .getElementsByClassName("App")[0]
        .setAttribute("style", "background-color: white;");
    }

    if (document.getElementsByClassName("d-flex").length > 0) {
      document
        .getElementsByClassName("d-flex")[0]
        .setAttribute("style", "padding: 0px; font-size: 14px;");
    }

    if (document.getElementsByClassName("container-fluid").length > 0) {
      document
        .getElementsByClassName("container-fluid")[0]
        .setAttribute("style", "padding: 0px; margin: 5px; width: 100%;");
    }

    this.getCompany(this.state.companyId);
  }

  getSelectedGroup = (selectedGroupId) => {
    return this.state.groups.find(x => x.id.toString() === selectedGroupId.toString());
  }

  render() {
    const {
      cityState,
      clientFirstName,
      clientLastName,
      contactNumber,
      email,
      additionalInfo,
      groups,
      selectedGroupId,
      petsYear,
      petsName,
      petsBreed,
      petsMonth,
      petsWeek,
      disablePhone,
      sendButtonDebounceChanges,
      selectedAppointmentRequestTypeId,
      appointmentRequestTypes,
      errorMessage,
      requestFailed,
      selectedEmail,
      emails,
    } = this.state;

    let emailsList = [];
    if (emails.length === 0 && this.state.groups != null && this.state.groups.length > 0)
    {
      const groupsSelected = this.state.groups.filter(a => a.id.toString() === this.state.selectedGroupId.toString());
      if (groupsSelected.length > 0) {
        emailsList = groupsSelected[0].sendLeadsTo.split(',');
      }
      else {
        emailsList = []
      }      
    }
    else {
      emailsList = emails;      
    }    

    const selectedGroup = this.getSelectedGroup(selectedGroupId);
    return (
      <div
        className="sendNewLead"
        style={{ marginLeft: "0", marginTop: "15px" }}
      >
        <div
          className="row border-bottom border-dark"
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <span className="border-bottom border-dark"></span>
        </div>
        <div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="formLabel">
              <b>Send Lead To</b>
            </div>
            <div className="col">
              <select
                className="form-control input"
                style={{ height: "33px" }}
                id="selectedGroupId"
                name="selectedGroupId"
                value={selectedGroupId || 0}
                onChange={this.changeGroup}
              >
                {groups &&
                  groups.map((a) => <option key={"group_" + a.id} value={a.id}>{a.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="formLabel">
              <span>Choose email</span>
            </div>
            <div className="col">
              <select
                className="form-control input"
                style={{ height: "33px" }}
                id="selectedEmail"
                name="selectedEmail"
                value={selectedEmail || 0}
                onChange={this.inputChange}
              >
                <option key="email_all" value="">All</option>
                {emailsList &&
                  emailsList.map((email, index) => <option key={"email_" + index} value={email}>{email}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: "10px" }}>
            <div className="formLabel">
              <span>Subject </span>
            </div>
            <div className="col">
              <select
                className="form-control input"
                style={{ height: "33px" }}
                id="selectedAppointmentRequestTypeId"
                name="selectedAppointmentRequestTypeId"
                value={selectedAppointmentRequestTypeId || 1}
                onChange={this.inputChange}
              >
                {appointmentRequestTypes &&
                  appointmentRequestTypes.map((a) => <option key={"appointmentRequestType_" + a.id} value={a.id}>{a.type}</option>)}
              </select>
            </div>
          </div>
        <div style={{ marginLeft: "0" }}>
          <div className="row">
            <div className="formLabel">
              <span>City, State:</span>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control input"
                value={cityState || ""}
                id="cityState"
                name="cityState"
                onChange={this.inputChange}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="formLabel">
              <span>New or Existing: </span>
            </div>
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>
                        <input
                          type="radio"
                          value={this.state.leadType}
                          name="newOrExisting"
                          defaultChecked={true}
                          onChange={() => this.clientTypeChange("New")}
                        />{" "}
                        New client
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          value={this.state.leadType}
                          name="newOrExisting"
                          onChange={() => this.clientTypeChange("Existing")}
                        />{" "}
                        Existing client
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="formLabel">
              <span>First Name:</span>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control input"
                value={clientFirstName || ""}
                id="clientFirstName"
                name="clientFirstName"
                onChange={this.inputChange}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="formLabel">
              <span>Last Name:</span>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control input"
                value={clientLastName || ""}
                id="clientLastName"
                name="clientLastName"
                onChange={this.inputChange}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="formLabel">
              <span>Client Request</span>
            </div>
            <div className="col">
              <textarea
                className="form-control "
                style={{ resize: "none", height: "170%" }}
                value={additionalInfo || ""}
                id="additionalInfo"
                name="additionalInfo"
                onChange={this.inputChange}
              ></textarea>
            </div>
          </div>
          {selectedGroup &&
            selectedGroup.companyUnionId !== 6 &&
            selectedGroup.companyUnionId !== 7 &&
            selectedGroup.companyUnionId !== 8 && (
              <div className="row" style={{ marginTop: "50px" }}>
                <div className="formLabel">
                  <span>Pet`s Name</span>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control input"
                    value={petsName || ""}
                    id="petsName"
                    name="petsName"
                    onChange={this.inputChange}
                  ></input>
                </div>
              </div>
            )}


          {selectedGroup &&
            selectedGroup.companyUnionId !== 6 &&
            selectedGroup.companyUnionId !== 7 &&
            selectedGroup.companyUnionId !== 8 && (
              <div className="row">
                <div className="formLabel">
                  <span>Pet`s Age</span>
                </div>
                <div
                  className="col"
                  style={{
                    width: "100%",
                    minWidth: "200px",
                    paddingLeft: "0px",
                    display: "flex",
                  }}
                >
                  <input
                    type="number"
                    className="form-control input"
                    max="30"
                    min="0"
                    placeholder="Years"
                    value={petsYear || ""}
                    id="petsYear"
                    name="petsYear"
                    style={{ width: "50%" }}
                    onChange={(e) => this.validatePertsYear(e.target.value)}
                  ></input>
                  <input
                    type="number"
                    className="form-control input"
                    max="11"
                    min="0"
                    value={petsMonth || ""}
                    id="petsMonth"
                    placeholder="Months"
                    name="petsMonth"
                    style={{ width: "50%" }}
                    onChange={(e) => this.validatePertsMonth(e.target.value)}
                  ></input>
                  <input
                    type="number"
                    className="form-control input"
                    max="4"
                    min="0"
                    value={petsWeek || ""}
                    id="petsWeek"
                    placeholder="Weeks"
                    name="petsWeek"
                    style={{ width: "50%" }}
                    onChange={(e) => this.validatePertsWeek(e.target.value)}
                  ></input>
                </div>
              </div>
            )}
          {selectedGroup &&
            selectedGroup.companyUnionId !== 6 &&
            selectedGroup.companyUnionId !== 7 &&
            selectedGroup.companyUnionId !== 8 && (
              <div className="row">
                <div className="formLabel">
                  <span>Pet`s Breed</span>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control input"
                    value={petsBreed || ""}
                    id="petsBreed"
                    name="petsBreed"
                    onChange={this.inputChange}
                  ></input>
                </div>
              </div>
            )}

          <div className="row">
            <div className="formLabel">
              <span>Phone Number</span>
            </div> <div className="col">
              <InputMask
                id="contactNumber"
                name="contactNumber"
                value={contactNumber || ""}
                disabled={disablePhone}
                className="form-control  input"
                mask="(999) 999-9999"
                onChange={this.inputChange}
              />
            </div>
            <div>
            {disablePhone === true ? (<button
                  type="submit"
                  style={{ cursor: "pointer", width:"50px", padding: 0}}
                  className="form-control input"
                  onClick={this.disablePhoneChange}
                >
                  <span style={{color:"#12c112"}}>&#10003;</span>
                </button>) : (<button
                  type="submit"
                  style={{ cursor: "pointer", width:"50px", padding: 0}}
                  className="form-control input"
                  onClick={this.disablePhoneChange}
                >
                  N/A
                </button>)}
            
            </div>
          
          </div>
          <div className="row">
            <div className="formLabel">
              <span>Email Address</span>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control input"
                value={email || ""}
                id="email"
                name="email"
                onChange={this.inputChange}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="formLabel">
              <span>Preferred Method of Communication</span>
            </div>
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>
                        <input
                          type="radio"
                          value="Phone"
                          defaultChecked={true}
                          name="methodOfCommunication"
                          onChange={this.methodOfCommunicationChange}
                        />{" "}
                        Phone
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ marginLeft: "10px" }}>
                      <label>
                        <input
                          type="radio"
                          value="Email"
                          name="methodOfCommunication"
                          onChange={this.methodOfCommunicationChange}
                        />{" "}
                        Email
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
              <div style={{marginTop: "50px", color:"red"}}>
                {this.state.canBeSend && ("*Error: All fields need to be populated before sending.")}
              </div>
          <div
            className="row border-top border-dark"
            style={{width: "100%" }}
          >
            {requestFailed && <Error errorMessage={errorMessage}></Error>}
            <span className="border-top border-dark"></span>
          </div>
          <div
            className="row justify-content-end"
            style={{ marginTop: "5px", width: "100%" }}
          >
            <div className="col-md-auto" style={{ paddingLeft: "0px" }}>
              <button
                type="submit"
                style={{ cursor: "pointer", marginRight: "4px" }}
                className="btn btn-secondary"
                onClick={this.cancelCreate}
              >
                Clear
              </button>
              {sendButtonDebounceChanges === false ? (
                <button
                  type="submit"
                  style={{ cursor: "pointer" }}
                  className="btn btn-primary"
                  onClick={this.createLead}
                >
                  Send
                </button>
              ) : (
                <button
                  type="submit"
                  style={{ cursor: "pointer", backgroundColor: "#8b072e" }}
                  className="btn btn-primary"
                  onClick={this.createLead}
                >
                  Sent
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
