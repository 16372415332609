import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import SettingsPageNavBar from "./components/settingsPageNavBar";

const KontakConfig = () => {
    const [threadsUpdaterStatisticEmailsLine, setThreadsUpdaterStatisticEmailsLine] = useState(null);
    const [threadsUpdaterStatisticEmails, setThreadsUpdaterStatisticEmails] = useState(null);

    const [nonReportingDaysForEmailNotification, setNonReportingDaysForEmailNotification] = useState(null);
    const [kontakConfig, setKontakConfig] = useState(null);

    const getKontakConfig = () => {
        ajaxCaller("KontakConfig", {
            method: "get",
            headers: new Headers({
                "Access-Control-Allow-Headers": "Authorization, Accept",
                "Access-Control-Allow-Methods": "GET",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getAccessToken(),
                "Accept": "*/*"
            }),
        }).then((response) => {
            if (!response.isError) {
                setThreadsUpdaterStatisticEmails(
                    response.threadsUpdaterStatisticEmails == null || response.threadsUpdaterStatisticEmails === ""
                        ? []
                        : response.threadsUpdaterStatisticEmails.split(",")
                );
                setKontakConfig(response);
                setNonReportingDaysForEmailNotification(response.nonReportingDaysForEmailNotification);
               
            }
            else{}
        });
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
  
    const saveEmailsChanges = (threadsUpdaterStatisticEmail) => {

        let threadsUpdaterStatisticEmailLine = "";

        threadsUpdaterStatisticEmail.forEach((email) => {
            if (email.length > 1) {
                threadsUpdaterStatisticEmailLine = email + "," + threadsUpdaterStatisticEmailLine;
            }
        });

        threadsUpdaterStatisticEmailLine = threadsUpdaterStatisticEmailLine.slice(0, -1);

        setThreadsUpdaterStatisticEmailsLine(threadsUpdaterStatisticEmailLine);
    };

    const removeEmail = (index) => {
        var newLeadsEmails = [...threadsUpdaterStatisticEmails];
        newLeadsEmails.splice(index, 1);
        setThreadsUpdaterStatisticEmails(newLeadsEmails);
        saveEmailsChanges(newLeadsEmails);
    };

    const addEmail = (event) => {
        if (
            event.target.value === "" ||
            event.target.value.length < 2 ||
            !validateEmail(event.target.value)
        ) {
            return;
        }
        var key = event.which;
        if (key === 13 || key === 14) {
            console.log("qweqwe")
            var newLeadsEmails = [...threadsUpdaterStatisticEmails];
            newLeadsEmails.push(event.target.value);

            setThreadsUpdaterStatisticEmails(newLeadsEmails);
            saveEmailsChanges(newLeadsEmails);
            event.target.value = "";
        }
    };

    const updateConfig = () => {
        ajaxCaller("KontakConfig/Update", {
            method: "put",
            headers: new Headers({
                Authorization: "Bearer " + getAccessToken(),
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
            body: JSON.stringify({
                id: kontakConfig.id,
                nonReportingDaysForEmailNotification: nonReportingDaysForEmailNotification,
                threadsUpdaterStatisticEmails: threadsUpdaterStatisticEmailsLine
            })
        }).then((response) => {
            if (!response.isError) {
                getKontakConfig();

                setNonReportingDaysForEmailNotification(response.nonReportingDaysForEmailNotification);
                setThreadsUpdaterStatisticEmails(
                    response.threadsUpdaterStatisticEmails == null || response.threadsUpdaterStatisticEmails === ""
                        ? []
                        : response.threadsUpdaterStatisticEmails.split(",")
                );
            }
        });
    };

    useEffect(() => {
        getKontakConfig()
    }, []);

    return (
        <>
            <SettingsPageNavBar pageName="kontakConfig" />
            <div className="section sec-move-left">
                <div className="section py-5 px-lg-2">
                    <div className="section px-1">
                        <div className="container-fluid z-bigger-10">
                            <div className="row">
                                <div className="section background-white p-3 p-lg-4">
                                    <div className="row mt-3 mt-lg-4">
                                        <div className="col-12">
                                            <h5 className="mb-2 pb-1">PetDesk Configuration</h5>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                                                    <p className="mb-2 mb-md-0 font-weight-500">
                                                        Number of days with zero chats
                                                    </p>
                                                </div>
                                                <div className="col-sm-auto col-sm-auto align-self-center">
                                                    <div className="form-group">
                                                        <input
                                                            type="number"
                                                            value={nonReportingDaysForEmailNotification}
                                                            onChange={(event) =>
                                                                setNonReportingDaysForEmailNotification(event.target.value)
                                                            }
                                                            className="form-style border-0 small-40"
                                                            placeholder="Days..."
                                                            autoComplete="off"
                                                        />

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
                                            <p className="mb-2 mb-md-0 font-weight-500">Contacts for Daily Import stats:</p>
                                        </div>
                                        <div className="col-md align-self-center">
                                            <div className="section z-bigger-10">
                                                <div htmlFor="tag-typer" className="for-tags-typer">
                                                    <div className="tags-section">
                                                        {threadsUpdaterStatisticEmails &&
                                                            threadsUpdaterStatisticEmails.map((email, index) => (
                                                                <span className="tag-button">
                                                                    <span
                                                                        className="close-button"
                                                                        onClick={() =>
                                                                            removeEmail(index)
                                                                        }
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                    {email}
                                                                </span>
                                                            ))}
                                                        <span>
                                                            <input
                                                                className="form-style p-0 tag-typer"
                                                                id="tag-typer"
                                                                type="text"
                                                                onKeyPress={(event) =>
                                                                    addEmail(event)
                                                                }
                                                                placeholder="Type an email address and press ENTER..."
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4"> <div className="col-md-auto align-self-center pl-md-2">
                                        <button
                                            onClick={() => updateConfig()}
                                            className="btn btn-blue btn-width-80 btn-height-40 border-0 font-size-16"
                                        >
                                            Save
                                        </button>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KontakConfig;