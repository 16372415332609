import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ajaxCaller, ajaxNonJsonCaller, authHeaders } from "../../ajaxCaller";
import { getAccessToken } from "../../authentification";

const CICReport = (props) => {
    const [chart, setChart] = useState(null);
    const [selectedColumnNumber, setSelectedColumnNumber] = useState(null);
    const [columnsReferences] = useState([]);
    const [chartOptions, setChartOptions] = useState(null);
    const [data, setData] = useState(null);


  const refreshChart = () => {
    const chartOptionsNew = {
      tooltip: {
        backgroundColor: "rgba(64,66,85,0.95)",
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        style: {
          padding: '15px',
        },
        borderRadius: 0,
        headerFormat: '',
        pointFormatter: function () {
           
          setSelectedColumnNumber(this.x);
          
          let value = convertToDecimalWithComma(this.y);
          return `${this.series.name}: <b>  ${value} <br/>`;
        }
      },
      chart: {
        height: "320px",
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 2000);
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      loading: {
        hideDuration: 1000,
        showDuration: 1000,
      },
      xAxis: {
        tickInterval: 1,
        labels: {
          enabled: true,
          formatter: (obj) => {
            if (
              chartOptionsNew == null ||
              chartOptionsNew == null ||
              chartOptionsNew.series == null ||
              chartOptionsNew.series[0] == null ||
              chartOptionsNew.series[0].data[obj.value] == null ||
              chartOptionsNew.series[0].data[obj.value][0] == null
            ) {
              return "";
            } else {
              return chartOptionsNew.series[0].data[obj.value][0];
            }
          },
        },
      },
      yAxis: {
        title: "",
        labels: {
          enabled: true,
          formatter: (obj) => {
            if (props.reportType === 3) {
              return obj.value + "%"
            }
            else if (props.reportType === 4) {
              return "$" + convertToDecimalWithComma(obj.value);
            }
            else {
              return convertToDecimalWithComma(obj.value);
            }
          }
        }
      },
      plotOptions: {
        column: {
          grouping: false,
        },
      },
      series: [
        {
          name: "Number Of Inquiries",
          lineColor:  "#3495E6",
          color:  "#3495E6",
          type: "column",
          fillColor: "rgba(119, 181, 223, 0.1)",
          lineWidth: 2,
          borderWidth: 1,
        },
      ],
    };

    chart && chart.showLoading();

    ajaxCaller("QuestionCategory/GetCICReportData", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        dateFrom: props.dateRange.dateFrom,
        dateTo: props.dateRange.dateTo,
        groupIds: props.groupIds,
      }),
    }).then((response) => {
      if (response != null) {
        const formattedResponse = response.map((a) => [
          a.category,
          a.numberOfInquiries,
        ]);
        chartOptionsNew.series[0].data = formattedResponse;

        setData(response);
        setChartOptions(chartOptionsNew);
        chart && chart.hideLoading();
      }
    });
  };

  const hoverColumn = (columnIndex) => {
    setSelectedColumnNumber(columnIndex);
    chart.tooltip.refresh(chart.series[0].data[columnIndex]);
  };

  const afterChartCreated = (chart) => {
    setChart(chart);
  };
  const hookCallback = () => {
    refreshChart();
  };
  useEffect(hookCallback, [
    props.dateRange,
    props.groupIds
  ]);
  
  const convertToDecimalWithComma = (value) => {
    return Math.round(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const downloadCategoryAnalytics = () => {
    ajaxNonJsonCaller("Excel/ExportCategoryAnalytics", {
      method: "post",
      headers: authHeaders,
      body: JSON.stringify({
        dateFrom: props.dateRange.dateFrom,
        dateTo: props.dateRange.dateTo,
        groupIds: props.groupIds
      }),
    }).then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "CIC Report.xlsx";
          a.click();
        });
      }
      else {
      }
    });
  };

  return (
    <>
      <div className="row mt-2 pt-1">
        <div className="col-12">
          <div className="section background-white p-3 p-lg-4">
            <div className="row d-none d-md-flex">
              <div className="col">
                <p className="font-size-13">
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section">
                  <figure className="highcharts-figure">
                    <div
                      id="chat-chart-reports"
                      className="chart-report-height"
                    >
                      <HighchartsReact
                        ref={React.createRef()}
                        allowChartUpdate={true}
                        highcharts={Highcharts}
                        options={chartOptions}
                        callback={afterChartCreated}
                      />
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="section background-white">
            <div className="row py-2">
              <div className="col align-self-center">
                <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                  Details
                </p>
              </div>
              <div className="col-auto">
                  <span
                    onClick={() => downloadCategoryAnalytics()}
                    className="link-download font-size-13 mr-2 pr-1"
                  >
                    <img
                      src={require("../../img/download.svg")}
                      alt=""
                      className="mr-1"
                    />
                    Export (xlsx)
                  </span>
              </div>
            </div>
            <div className="row">
              <div className="col-5 col-md-3 col-lg-2 pr-0">
                <div className="section over-p">
                  <div className="blue-box-table">
                    <p className="mb-0 font-weight-500 font-size-14"></p>
                  </div>
                  <div className="white-box-table">
                    <p className="mb-0 font-weight-500 font-size-14">
                    Number Of Inquiries
                    </p>
                  </div>
                  <div className="white-box-table">
                      <p className="mb-0 font-weight-500 font-size-14">
                      Percentage Of Inquiries
                      </p>
                    </div>
                </div>
              </div>
              <div className="col-7 col-md-9 col-lg-10 pl-0">
                <div className="section section-table-scroll-wrapper">
                  <div className="section section-table-scroll-wrap" style={{ overflowX: "auto" }}>
                    {data &&
                      data.map((result, index) => {

                        return (
                          <div key={result.category}
                            className={
                              index === selectedColumnNumber
                                ? "section max-140 tab-reports-col selected"
                                : "section max-140 tab-reports-col"
                            }
                            ref={(el) => {
                              columnsReferences[index] = el;
                            }}
                             onMouseEnter={() => hoverColumn(index)}
                          >
                            <div
                              className={
                                index === selectedColumnNumber
                                  ? "blue-box-table box-center-text selected"
                                  : "blue-box-table box-center-text"
                              }
                            >
                              <p className="mb-0 font-weight-500 font-size-14 date-top">
                                {result.category}
                              </p>
                            </div>
                            <div className="white-box-table box-center-text">
                              <p className="mb-0 font-size-13">
                                {result.numberOfInquiries}
                              </p>
                            </div>
                            <div className="white-box-table box-center-text">
                              <p className="mb-0 font-size-13">
                                {result.percentageOfInquiries} %
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CICReport;