import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken, getCurrentCompanyLogo } from "../authentification";
import Cookies from "js-cookie";
import SettingsPageNavBar from "./components/settingsPageNavBar";
import ErrorModal from "./components/errorModal";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Company = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState(getCurrentCompanyLogo());
  const [companyId, setCompanyId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [CompanyApiKey, setCompanyApiKey] = useState("");
  const clearError = () => {
    setErrorMessage("");
  }

  const getCompany = () => {
    clearError();
    ajaxCaller("Company/GetCurrentUserCompany/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setCompanyName(response.name);
        setCompanyLogo(response.logo);
        setCompanyId(response.id);
        setCompanyApiKey(response.apiKey)
      } else {
        setErrorMessage(response.message);
      }
    });
  };

  const generateApiKey = (id) => {
    clearError();
    ajaxCaller("Company/RecreateCompanyAPIKey/" + id, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "put",
    }).then((response) => {
      if (!response.isError) {
        setCompanyApiKey(response)
      } else {
        setErrorMessage(response.message);
      }
    });
  }

  const getCOmpanyCallback = () => {
    getCompany();
  }
  useEffect(getCOmpanyCallback, []);

  const uploadLogo = (file) => {
    clearError();
    if (!file || file.size > 1000000) {
      return;
    }
    var data = new FormData();
    data.append("file", file);
    ajaxCaller("Company/UploadImage", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
      }),
      body: data,
    }).then((response) => {
      if (!response.isError) {
        Cookies.set("company_logo", response.fileName);
        document
          .getElementById("company-logo")
          .setAttribute(
            "src",
            "https://kontakstorage.blob.core.windows.net/company-logos/" +
            response.fileName
          );
        setCompanyLogo(response.fileName);
      }
      else {
        setErrorMessage(response.message);
      }
    });
  };

  const handleFileUpload = (event) => {
    clearError();
    if (
      !event.target.files[0] ||
      event.target.files[0].size > 1000000
    ) {
      setErrorMessage("Maximum file size is 1mb.");
      return;
    }
    var file = event.target.files[0];
    var _URL = window.URL || window.webkitURL;
    var image = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    image.onload = function () {
      clearError();
      if (this.width > 250 || this.height > 100) {
        setErrorMessage("Max dimensions are 250 height x 100 width.");
      }
      else {
        uploadLogo(file);
        image.src = objectUrl;
      }
      _URL.revokeObjectURL(objectUrl);
    };

    if (objectUrl) {
      image.src = objectUrl;
    }
  }

  return (
    <>
      <ErrorModal errorMessage={errorMessage}></ErrorModal>
      <SettingsPageNavBar pageName="company" />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">General Settings</h5>
                </div>
                <div className="col-12">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-md-3 col-xl-2 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Corporate name</p>
                      </div>
                      <div className="col-md-4 col-xl-3">
                        <div className="form-group">
                          {companyName}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3 col-xl-2 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">
                          Your corporate logo
                        </p>
                      </div>
                      <div className="col-md">
                        <div className="section avatar-upload account-upload">
                          <div className="container-avatar corporate">
                            <div className="imageWrapper">
                              <img
                                id="company-logo"
                                className="avatar-image"
                                src={
                                  companyLogo == null
                                    ? "https://kontakstorage.blob.core.windows.net/company-logos/defaultLogo.svg"
                                    : "https://kontakstorage.blob.core.windows.net/company-logos/" +
                                    companyLogo
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="file-upload align-self-center">
                            <input
                              type="file"
                              className="file-input"
                              onClick={() => clearError()}
                              onChange={(event) => handleFileUpload(event)}
                            />
                            Choose File
                          </div>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md offset-md-3 offset-xl-2 mt-1">
                        <p className="mb-0 font-size-13 line-height-18 opacity-70">
                          <i>
                            Max dimensions are 250 height x 100 width.
                            <br />
                            Maximum file size is 1mb.
                          </i>
                        </p>
                      </div>
                    </div>
                    {/* <div className="row mt-4">
                    <div className="col-12">
                      <button
                        onClick={() => saveChanges()}
                        className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                      >
                        Save
                      </button>
                    </div>
                  </div> */}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">

                <div className="col-12 chat-archive-table">
                  <div className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-md-4 col-xl-2 align-self-center">
                        <p className="mb-2 mb-md-0 font-weight-500">Company APIKey</p>
                      </div>

                      <div className="col-md-6 col-xl-4">
                        <div className="form-group">
                          <span
                            className="link-download font-size-15 mr-2 pr-1"
                          >
                            {CompanyApiKey}

                            <CopyToClipboard text={CompanyApiKey}>
                              <button
                                type="submit"
                                style={{ cursor: "pointer", marginRight: "5px" }}
                                className="btn btn-regions "
                                data-toggle="tooltip"
                                data-placement="top"
                                data-trigger="hover"
                                title="Copy the Company APIKey for Auth"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width="18px"
                                  height="18px"
                                >
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </span>
                        </div>
                        <div
                          className="modal fade"
                          id={"modalRegenerate"}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalRegenerate"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                      <h5 className="mb-2 pb-1">Generate a new APIKey</h5>
                                    </div>
                                    <div className="justify-content-center">
                                      <p className="mb-0">
                                        You are about to generate a new APIKey!
                                      </p>
                                      <p className="mb-0">
                                        This will affect the PetDesk Phones in PetDesk iFrame!
                                      </p>
                                      <p className="mb-0">
                                        Are you sure you want to continue?
                                      </p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                      >
                                        CANCEL
                                      </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => generateApiKey(companyId)}
                                        className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                      >
                                        GENERATE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <button type="button"
                        data-toggle="modal"
                        data-target={"#modalRegenerate"}
                        className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16">
                        Generate new APIKey
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Company;
