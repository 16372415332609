import React, { useEffect, useState, useCallback } from "react";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import Cookies from "js-cookie";

const PawPass = (props) => {

  const[apiKey, setApiKey] = useState(null);
  const[userEmail, setUserEmail] = useState(null);

  const showSpinner = () => {
    const globalSpinner = document.getElementById("globalSpinner");
    if (globalSpinner != null) {
      globalSpinner.style.display = "block";
    }
  }

  const hideSpinner = () => {
    const globalSpinner = document.getElementById("globalSpinner");
    if (globalSpinner != null) {
      globalSpinner.style.display = "none";
    }
  }

  const getApiKey = useCallback(() => {
    showSpinner();
    ajaxCaller("PawPass/Authenticate", {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (!response.isError) {
        setApiKey(response.key);
        setUserEmail(response.email);
        showSpinner();        
      }
    });
  }, []);
  
  const [, setOrgInfo] = useState(null);
  const getOrgInfo = useCallback(() => {
    showSpinner();
    ajaxCaller("PawPass/GetOrgInfo", {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      console.log('logo response');
      console.log(response);
      if (!response.isError) {
        setOrgInfo(response);
        
        var companyLogo = Cookies.get("company_logo");
        if(response.org.logoURL && (companyLogo == null || companyLogo === "null" || companyLogo === ""))
        {
          var logoElement = document.getElementById("company-logo");

          logoElement.setAttribute(
            "src",
            response.org.logoURL
          );
          
          logoElement.style.display = "";
        }
        
        setTimeout(hideSpinner, 12000);
      }
    });
  }, []);


  useEffect(() => {
    getApiKey();
  }, [getApiKey, apiKey])

  useEffect(() => {
    if(apiKey)
    {
      getOrgInfo();
    }
  }, [getOrgInfo, apiKey])

  useEffect(() => {
    const globalSpinner = document.getElementById("globalSpinner");
    globalSpinner.style.display = "none";
  });

  return (
    apiKey && userEmail && (<iframe
      title="PawPass"
      src={`https://khub.devscraft.com/login/${apiKey}/${userEmail}`}
      width="100%"
      style={{ marginTop: "50px", paddingLeft: "58px", height: "calc(100vh - 60px)" }}>
    </iframe>)
  );
};

export default PawPass;
