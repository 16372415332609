import React, { Component } from "react";
import * as LiveChat from "@livechat/agent-app-sdk";
import { ajaxCaller } from "../ajaxCaller";
import Highlighter from "react-highlight-words";
import SendNewLead from "./sendNewLead";
import { inputChange } from "../utilities";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactQuill from "react-quill";

require("react-quill/dist/quill.snow.css");

export default class KnowledgeBaseWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: null,
      categories: [],
      selectedCategoryId: null,
      searchKeyword: "",
      questionAnswers: null,
      allQuestionAnswers: null,
      isSearchingAll: false,
      groups: [],
      companyId: null,
      selectedGroupId: null,
      liveChatThreadId: null,
      isCompanyNotFound: false,
      currentLiveChatThreadId: null,
      widgetColor: "ffffff",
      acceptsAppointmentRequests: true,
    };

    this.inputChange = inputChange.bind(this);
  }

  getCategories = (groupId) => {
    if (groupId === -1) {
      this.setState({ categories: null, allQuestionAnswers: null });
    } else {
      ajaxCaller("QuestionCategory/Facility/" + groupId, {
        method: "get",
      }).then((response) => {
        if (!response.isError) {
          this.setState({
            categories: response.categories,
            acceptsAppointmentRequests: response.acceptsAppointmentRequests
          });
          console.log('acceptsAppointmentRequests');
          console.log(response.acceptsAppointmentRequests);
        }
      });
    }
  };

  searchingQuestionAnswerByKeyword() {
    if (this.state.companyId === -1 || this.state.companyId === null) {
    } else {
      ajaxCaller(
        "QuestionAnswer/SearchByKeyword/" +
          this.state.selectedGroupId +
          "/" +
          this.state.searchKeyword,
        {
          method: "get",
        }
      ).then((response) => {
        if (!response.isError) {
          this.setState({
            questionAnswers: response,
          });
        }
      });
    }
  }

  handleKeyDownInput = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.length > 0) {
        this.setState({ isSearchingAll: true });
        this.searchingQuestionAnswerByKeyword();
      } else if (this.state.selectedCategoryId === null) {
        this.backToAllSections();
      }
    }
  };

  onChangeInput = (e) => {
    this.setState({ searchKeyword: e.target.value });
    if (this.state.selectedCategoryId !== null) {
      this.setState({
        questionAnswers: this.state.allQuestionAnswers.filter((x) => {
          return (
            x.question.toLowerCase().includes(e.target.value.toLowerCase()) ||
            x.answer.toLowerCase().includes(e.target.value.toLowerCase())
          );
        }),
      });
    }
  };

  backToAllSections = () => {
    this.setState({
      selectedCategoryId: null,
      isSearchingAll: false,
      searchKeyword: "",
      questionAnswers: [],
      allQuestionAnswers: [],
    });
  };

  createHighlight = (inputText) => {
    if (this.state.searchKeyword) {
      let split = inputText
        .toLowerCase()
        .split(this.state.searchKeyword.toLowerCase());

      let outputText = "";

      for (let i = 0; i < split.length; i++) {
        if (i === split.length - 1) {
          outputText += split[i];
        } else {
          outputText += `${split[i]} <span class="highlight" style="background: rgb(255, 221, 65, 0.2); font-size: 15px; line-height: 22px;">${this.state.searchKeyword}</span>`;
        }
      }
      return outputText;
    } else {
      return inputText;
    }
  };

  componentDidMount() {
    const myObject = this;

    // // TEST DATA
    // const liveChatThreadId = "RJ9CH3CEAY"
    // ajaxCaller(
    //   "QuestionAnswer/GetCompanyIdByThread/" + liveChatThreadId, // profile.chat.thread_id
    //   {
    //     method: "get",
    //   }
    // ).then((response) => {
    //   if (response.companyId != null && response.companyId > 0) {
    //     myObject.getGroups(response.companyId);

    //     myObject.setState({
    //       companyId: response.companyId,
    //       selectedGroupId: response.groupId,
    //       liveChatThreadId: liveChatThreadId,
    //       isCompanyNotFound: false,
    //       widgetColor: response.widgetColor,
    //       acceptsAppointmentRequests: response.acceptsAppointmentRequests
    //     });
    //   } else {
    //     myObject.setState({ isCompanyNotFound: true });
    //   }
    // });

    // REAL DATA
    LiveChat.createDetailsWidget().then(function (widget) {
      myObject.setState({ widget });

      widget.on("customer_profile", (profile) => {
        if (profile.chat.thread_id != null) {
          if(profile.chat.thread_id !== myObject.state.currentLiveChatThreadId) {
            myObject.backToAllSections();
          }

          ajaxCaller(
            "QuestionAnswer/GetCompanyIdByThread/" + profile.chat.thread_id,
            {
              method: "get",
            }
          ).then((response) => {
            if (response.companyId != null && response.companyId > 0) {
              myObject.setState({
                companyId: response.companyId,
                selectedGroupId: response.groupId,
                liveChatThreadId: profile.chat.thread_id,
                currentLiveChatThreadId: profile.chat.thread_id,
                isCompanyNotFound: false,
                widgetColor: response.widgetColor,
                acceptsAppointmentRequests: response.acceptsAppointmentRequests
              });

              myObject.getGroups(response.companyId);
            } else {
              myObject.setState({ isCompanyNotFound: true });
            }
          });
        }
      });
    });
  }

  addClick = () => {
    ajaxCaller("QuestionCategory/AddClick", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        categoryId: this.state.selectedCategoryId,
        liveChatThreadId: this.state.liveChatThreadId,
      }),
    }).then((response) => {
      if (!response.isError) {
        this.setState({ copied: true });
      }
    });
  };

  getQuestionAnswersByCategory = (categoryId) => {
    this.setState({ selectedCategoryId: categoryId });
    ajaxCaller("QuestionAnswer/Category/" + categoryId, {
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        this.setState({
          questionAnswers: response,
          allQuestionAnswers: response,
        });
      }
    });
  };

  getGroups = (companyId) => {
    ajaxCaller("Group/GetGroups/" + companyId + "?Simple=true", {
      method: "get",
      headers: this.authHeaders,
    }).then((groups) => {
      this.setState({
        groups: groups,
      });

      this.getCategories(this.state.selectedGroupId);
    });
  };

  render() {
    const {
      categories,
      selectedCategoryId,
      questionAnswers,
      searchKeyword,
      isSearchingAll,
      groups,
    } = this.state;


    return (
      <div className="knowledgeBaseWidget">
        <>
          {this.state.isCompanyNotFound && (
            <div className="alert alert-danger" role="alert">
              Company not found
            </div>
          )}
          <div>
            <div className="row-md">
              {this.state.selectedGroupId && (
                <select
                  className="form-control"
                  id="selectedGroupId"
                  name="selectedGroupId"
                  value={this.state.selectedGroupId}
                  onChange={(e) =>
                    this.inputChange(e, () => {
                      this.getCategories(e.target.value);
                      this.backToAllSections();
                    })
                  }
                >
                  {groups &&
                    groups.map((a) => <option key={"group_" + a.id} value={a.id}>{a.name}</option>)}
                </select>
              )}
            </div>
          </div>
          <input
            className="search"
            type="text"
            onChange={(e) => this.onChangeInput(e)}
            value={searchKeyword}
            onKeyDown={this.handleKeyDownInput}
            placeholder="Search"
          ></input>

          {selectedCategoryId == null &&
            isSearchingAll === false &&
            categories &&
            categories.map((a) => (
              <div key={"category_" + a.id}>
                <span                  
                  className="link"
                  style={{
                    color: a.isHighlighted === true ? "#f11f1f" : "",
                    fontWeight: a.isHighlighted === true ? "bold" : "",
                  }}
                  onClick={() => this.getQuestionAnswersByCategory(a.id)}
                >
                  {a.name}
                </span>
                <br />
              </div>
            ))}

          {(selectedCategoryId != null || isSearchingAll) && (
            <div className="link" onClick={this.backToAllSections}>
              Back to all sections
            </div>
          )}

          {(selectedCategoryId != null || isSearchingAll) &&
            questionAnswers &&
            questionAnswers.map((a) => (
              <div
                className="questionAnswerOuterContainer"
                style={{ backgroundColor: "#" + this.state.widgetColor }}
              >
                <div className="questionAnswerContainer">
                  {!a.isPending && (
                    <>
                      {isSearchingAll && (
                        <>
                          <span
                            className="link"
                            style={{
                              color: a.isHighlighted === true ? "#f11f1f" : "",
                              fontWeight:
                                a.isHighlighted === true ? "bold" : "",
                              cursor: "default",
                              fontSize: "15px",
                              lineHeight: "22px",
                            }}
                          >
                            {a.categoryName}
                          </span>
                          <br />
                        </>
                      )}
                      <b>
                        <Highlighter
                          activeStyle={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                            fontSize: "15px",
                            lineHeight: "22px",
                          }}
                          unhighlightStyle={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                            fontSize: "15px",
                            lineHeight: "22px",
                          }}
                          highlightStyle={{
                            background: "#FFDD41",
                            fontSize: "15px",
                            lineHeight: "22px",
                          }}
                          searchWords={searchKeyword.split(" ")}
                          autoEscape={true}
                          textToHighlight={a.question + "\t"}
                        />
                      </b>
                      <br />
                      <span>
                        <ReactQuill
                          id="answerView"
                          key="answerView"
                          readOnly="true"
                          className="disabledRichTextBoxToolbar"
                          value={this.createHighlight(a.answer)}
                        ></ReactQuill>
                        {a.answer && a.answer.length > 1 && (
                          <CopyToClipboard
                            text={a.answerText}
                            onCopy={() => this.addClick()}
                          >
                            <button>copy</button>
                          </CopyToClipboard>
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))}
          {this.state.selectedGroupId && (
            <SendNewLead
              acceptsAppointmentRequests={this.state.acceptsAppointmentRequests}
              companyId={this.state.companyId}
              groups={this.state.groups}
              liveChatThreadId={this.state.liveChatThreadId}
              selectedGroupId={this.state.selectedGroupId}
              onCancel={() => {
                this.setState({ isCreateNewLead: false });
              }}
            ></SendNewLead>
          )}
        </>
      </div>
    );
  }
}
