import React, { useState, useEffect } from "react";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin} from "../authentification";
import Dragula from "react-dragula";
import $ from "jquery";
import ReactQuill from "react-quill";
import Moment from "react-moment";
import Highlighter from "react-highlight-words";
import { Steps } from 'intro.js-react';
import { useGlobal } from "./components/globalIntroState";
require("react-quill/dist/quill.snow.css");

const ProfileCategory = (props) => {
  const [categoryId] = useState(props.match.params.categoryId);
  const [groupId] = useState(props.match.params.groupId);
  const [category, setCategory] = useState(null);
  const [isKontakAdminHere] = useState(isKontakAdmin());

  const hookCallback = () => {

    getQuestionAnswers();
    setupDrake();
  }
  useEffect(hookCallback, [categoryId]);

  const [questionAnswers, setQuestionAnswers] = useState(null);
  const getQuestionAnswers = () => {
    if (categoryId && categoryId.toString() === "all") {
      ajaxCaller("QuestionAnswer/GetAllByFacility/" + groupId, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }).then((response) => {
        if (!response.isError) {
          setQuestionAnswers(response);
          setFirstQuestionForIntro(response[0].id)
        }
      });
    } else {
      ajaxCaller("QuestionAnswer/Category/" + categoryId, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }).then((response) => {
        if (!response.isError) {
          console.log(categoryId);
          console.log(response);
          setQuestionAnswers(response);
          setFirstQuestionForIntro(response[0].id)
        }
      });
    }
  };

  const [containerElements, setContainerElements] = useState(null);
  const setupDrake = () => {
    var container = document.getElementById("container");
    var drake = Dragula([container]);

    drake.on("drop", (el, target, source, sibling) => {
      var childContainerElements = Array.prototype.slice.call(
        document.getElementById("container").children
      );
      setContainerElements(childContainerElements);
    });
  };

  const reorderQuestionAnswers = () => {
    if (questionAnswers !== null) {
      var reorderedElements = [];
      for (let index = 0; index < containerElements.length; index++) {
        let id = containerElements[index].id;
        reorderedElements.push({ id: id, rowIndex: index });
      }

      var reorderedQuestionAnswers = [...questionAnswers];
      reorderedQuestionAnswers.forEach((element) => {
        reorderedElements.forEach((containerElement) => {
          if (
            "questionAnswer_" + element.id ===
            containerElement.id.toString()
          ) {
            element.rowIndex = containerElement.rowIndex;
          }
        });
      });

      setQuestionAnswers(reorderedQuestionAnswers);
      updateReorderedQuestionAnswers();
    }
  };

  const reorderHookCallback = () => {
    reorderQuestionAnswers();
  }
  useEffect(reorderHookCallback, [containerElements]);

  const updateReorderedQuestionAnswers = () => {
    ajaxCaller("QuestionAnswer/ReorderQuestionAnswers", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify(questionAnswers),
    }).then((response) => {
      if (!response.isError) {
        setCategory(response);
      }
    });
  };

  const [editableQuestion, setEditableQuestion] = useState("");
  const updateQuestionAnswer = (id) => {
    var questionAnswer = questionAnswers.find(
      (x) => x.id.toString() === id.toString()
    );
    questionAnswer.question = editableQuestion;
    ajaxCaller("QuestionAnswer/" + id, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        question: questionAnswer.question,
        answer: questionAnswer.answer,
        answerText: questionAnswer.answerText,
      }),
    }).then((response) => {
      if (!response.isError) {
        setCategory(response);
      }
    });
  };

  const switchLockForEditState = (id) => {
    ajaxCaller("QuestionAnswer/SwitchLockForEditState/" + id, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      })
    }).then((response) => {
      if (!response.isError) {
        getQuestionAnswers();
      }
    });
  } 

  const clearFields = () => {
    setQuestion("");
    setAnswer("");
    setAnswerText("");
  };
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerText, setAnswerText] = useState("");
  const createQuestionAnswer = () => {
    ajaxCaller("QuestionAnswer", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        question: question,
        answer: answer,
        answerText: answerText,
        categoryId: categoryId,
      }),
    }).then((response) => {
      if (!response.isError) {
        clearFields();
        setQuestionAnswers((prev) => [...prev].concat(response));
      }
    });
  };
  const addButtonIsDisabled = () => {
    return answer === "" || answer == null || question == null || question === "" || question === " " || answer === " ";
  }

  const removeQuestionAnswer = (id) => {
    ajaxCaller("QuestionAnswer/" + id, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        getQuestionAnswers();
      } else {
      }
    });
  };


  const [searchString, setSearchString] = useState("");
  const matchQuestionAnswer = (questionAnswer) => {
    if(searchString == null || searchString === "" || searchString === " ")
    {
      return true;
    }
    return questionAnswer.question.toLowerCase().includes(searchString.toLowerCase()) ||
    questionAnswer.answer.toLowerCase().includes(searchString.toLowerCase())
  } 

  const [firstQuestionForIntro, setFirstQuestionForIntro] = useState(null)
  const steps = [
    {
      element: '#profile-category-add',
      intro: 'If you wish to add additional Q&As, you can do so by using these boxes.  ',
      position: 'right',
      highlightClass: 'intro-profile-category-add-hightlight'
    },
    {
      element: '#edit_'+firstQuestionForIntro,
      intro: ' To edit any entry, please click here, and be sure to click on the update button afterwards to ensure your edits have been saved.',
      position: 'right'
    },
    {
      element: '#centred-intro',
      intro: '<p style="text-align:center">NOTE: To avoid confusion with your visitors, PetDesk Phones agents do not use terminology to imply that they are on-site. Therefore, when editing, please use the third person narrative (i.e. The hospital offers…) and try to keep the style uniform. </p>',
      position: 'right',
      tooltipClass: 'intro-profile-main'
    },
    {
      element: '#cntered-intro',
      intro: '<p style="text-align:center">If you require additional training regarding this section, please contact <span style="font-weight:bold;">support@getkontak.com <span/></p>',
      position: 'right'
    }
  ];

  const [globalState] = useGlobal();
  const [userIntroEnabled, setUserIntroEnabled] = useState(false); // note enable steps ONLY if they are not finished\
  const [currentIntroStep, setCurrentIntroStep] = useState(null);
  const [introId, setIntroId] = useState(null); 

  const changeCurrentStep = (step) => {
    ajaxCaller("UserIntro/UpdateStep/", {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        id: introId,
        currentStep: step
       }),
    }).then((response) => {
      if (response.isError) {
      } else {
      }
    });
  }

  const finishUserIntro = () => {
    ajaxCaller("UserIntro/FinishIntro/" + introId, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(false);
      }
    });
  }
  
  const getUserIntro = () => {
    ajaxCaller("UserIntro/UserIntro/3", {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(!response.finished);
        setCurrentIntroStep(response.currentStep);
        setIntroId(response.id);
      }
    });
  }

  useEffect(() => {
    if(!isKontakAdmin())
    {
      getUserIntro();
    }
  }, [globalState.counter])

  const onExitStep = () => {
     finishUserIntro();
 };

 const onChangeStep = (step) => {
  if(step != null)
   {
     changeCurrentStep(step)
   }
 };

  return (
    <>
    {groupId && userIntroEnabled && firstQuestionForIntro && (<Steps
        enabled={userIntroEnabled}
        steps={steps}
        initialStep={currentIntroStep}
        onExit={onExitStep}
        onChange={onChangeStep}
      />)}
    <div className="section sec-move-left">
      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4 mb-2 pb-1">
              <div className="col-auto align-self-center">
                <a
                  href={"/profile/" + props.match.params.groupId}
                  className="mb-0 font-weight-500 animsition-link link-back-img"
                >
                  <img
                    src={require("../img/left-arrow.svg")}
                    alt=""
                    className="mr-1"
                  />
                  back
                </a>
              </div>
              <div className="col-auto align-self-center">
                <h5 className="mb-0">{category && category.name}</h5>
              </div>
            </div>
            <div id="profile-category-add" className="row">
              <div className="col-12">
                <div className="section background-white p-3 p-lg-4">
                  <div className="row">
                    <div className="col-md-3 col-xl-2 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">
                        Add a question:
                      </p>
                    </div>
                    <div className="col-md-7 col-lg-6 col-xl-5">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-style border-0 small-40"
                          value={question}
                          onChange={(event) =>
                            setQuestion($(event.target).val())
                          }
                          placeholder="Add a question..."
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-3 col-xl-2">
                      <p className="mb-2 mb-md-0 mt-xl-1 font-weight-500">
                        Answer:
                      </p>
                    </div>
                    <div className="col-md-7 col-lg-6 col-xl-5">
                      <div className="section">
                        <div className="editor-full">
                          <div
                            className="document-full-2 ql-scroll-y"
                          >
                              {/* //TODO: need add modules like Ivan added  {modules}*/}
                              <ReactQuill
                                theme={"snow"}
                                placeholder={""}
                                value={answer}
                                onChange={(content, delta, source, editor) => {
                                  setAnswer(content);
                                  setAnswerText(editor.getText());
                                }}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <button
                        onClick={() => createQuestionAnswer()}
                        disabled={addButtonIsDisabled()} 
                        className="btn btn-blue btn-width-80 btn-height-40 border-0 font-size-16"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid z-bigger-10">
            <div className="row mt-2 pt-1">
              <div className="col-12 d-none d-xl-inline">
                <div className="section background-blue-light-4">
                  <div className="row">
                    <div className="col-lg-8 pr-0">
                      <div className="profile-box-blue profile-box-blue-bor-right">
                        <p className="mb-0 font-size-14 font-weight-500">
                          {category && category.name}
                        </p>
                        <input
                                        type="text"
                                        className="form-style border-0 small-40"
                                        style={{height:"30px", width:"300px", marginLeft:"20px", backgroundColor:"white", cursor:"text"}}
                                        placeholder="Search..."
                                        value={searchString}
                                        onChange={(event) => setSearchString( event.target.value)}
                                        autoComplete="off"
                                      />
                      </div>
                      
                    </div>
                    <div className="col-lg-2 px-0">
                      <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                        <p className="mb-0 font-size-14 font-weight-500">
                          Last Modified
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div id="container" className="col-12 profile-category-wrap-move">
                {questionAnswers &&
                  questionAnswers.map((questionAnswer) => {
                    if(matchQuestionAnswer(questionAnswer))
                    {
                      return (
                        <div key={questionAnswer.id}>
                          <div
                            className="modal fade"
                            id={"modalDelete_" + questionAnswer.id}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modalDelete"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="container-fluid">
                                    <div className="row justify-content-center">
                                      <div className="col-12 text-center">
                                        <h5 className="mb-2 pb-1">
                                          Delete question: {questionAnswer.question}
                                        </h5>
                                      </div>
                                      <div className="col-12 text-center">
                                        <p className="mb-0">
                                          Are you sure you wish to continue?
                                        </p>
                                      </div>
                                      <div className="col-12 text-center mt-4">
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                        >
                                          CANCEL
                                        </button>
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() =>
                                            removeQuestionAnswer(questionAnswer.id)
                                          }
                                          className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                        >
                                          DELETE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="modal modal-metric bigger fade"
                            id={"modalEdit_" + questionAnswer.id}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="modalEdit"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="container-fluid">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                    <div className="row">
                                      <div className="col-12">
                                        <h5 className="mb-4 font-weight-700">
                                          Edit Information
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-3 align-self-center">
                                        <p className="mb-2 mb-sm-0 font-weight-500">
                                          Question:
                                        </p>
                                      </div>
                                      <div className="col-sm-9">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-style border-0 small-40"
                                            placeholder="Add a question..."
                                            value={editableQuestion}
                                            onChange={(event) => {
                                              setEditableQuestion(
                                                $(event.target).val()
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row pt-3">
                                      <div className="col-sm-3 mt-sm-1">
                                        <p className="mb-2 mb-sm-0 font-weight-500">
                                          Answer:
                                        </p>
                                      </div>
                                      <div className="col-sm-9">
                                        <div className="section">
                                          <div className="editor-full">
                                            <div
                                              className="document-full ql-scroll-y"
                                              // style={{ height: "200px;" }}
                                            >
                                              <ReactQuill
                                                theme={"snow"}
                                                placeholder={""}
                                                value={questionAnswer.answer}
                                                onChange={(
                                                  content,
                                                  delta,
                                                  source,
                                                  editor
                                                ) => {
                                                  questionAnswer.answer = content;
                                                  questionAnswer.answerText = editor.getText();
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 text-center mt-4">
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                        >
                                          CANCEL
                                        </button>
                                        <button
                                          type="button"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                          onClick={() =>
                                            updateQuestionAnswer(questionAnswer.id)
                                          }
                                        >
                                          UPDATE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                          style={{cursor: props.match.params.categoryId === "all" ? "auto" : "move" }}
                            className="section profile-category-wrap"
                            id={"questionAnswer_" + questionAnswer.id}
                          >
                            <div className="row">
                              <div className="col-xl-8 pr-xl-0">
                                <div className="profile-box-white box-min-h-100 profile-box-white-bor-right">
                                  <div className="row">
                                    <div className="col-12">
                                      <p className="mb-1 font-weight-500">
                                      {(searchString && (searchString !== " " && searchString !== "")) ? (<Highlighter
                                                      activeStyle={{
                                                        wordBreak: "break-all",
                                                        wordWrap: "break-word",
                                                      }}
                                                      highlightStyle={{
                                                        background: "#FFDD41",
                                                        color:"black"
                                                      }}
                                                      searchWords={searchString.split(
                                                        " "
                                                      )}
                                                      autoEscape={true}
                                                      textToHighlight={
                                                        questionAnswer.question +
                                                        "\t"
                                                      }
                                                    />) : questionAnswer.question}
                                      </p>
                                    </div>
                                    <div className="col-12 pl-4">
                                      {/* <p className="mb-0 font-size-15"> */}
                                      {(searchString && (searchString !== " " && searchString !== "")) ? (<Highlighter
                                                      activeStyle={{
                                                        wordBreak: "break-all",
                                                        wordWrap: "break-word",
                                                      }}
                                                      highlightStyle={{
                                                        background: "#FFDD41",
                                                        color:"black"
                                                      }}
                                                      searchWords={searchString.split(
                                                        " "
                                                      )}
                                                      autoEscape={true}
                                                      textToHighlight={
                                                        questionAnswer.answerText +
                                                        "\t"
                                                      }
                                                    />) :   <ReactQuill
                                                    id="answerEdit"
                                                    key="answerEdit"
                                                    readOnly={true}
                                                    theme={"snow"}
                                                    className="disabledRichTextBoxToolbar"
                                                    value={questionAnswer.answer}
                                                  ></ReactQuill>}
                                      
                                      {/* </p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-2 px-xl-0">
                                <div className="profile-box-white box-min-h-100 profile-box-white-bor-right justify-content-xl-center">
                                  <p className="mb-0 font-size-15">
                                    <span className="d-xl-none font-weight-500">
                                      Last Modified:{" "}
                                    </span>
                                    <Moment
                                      format="MMM DD, YYYY"
                                      date={questionAnswer.updatedAt}
                                    />
                                    {

                                      isKontakAdminHere ? (
                                        <img src={require("../img/question-icon.png")} alt="WHO UPDATED" title={questionAnswer.user != null ? questionAnswer.user.name : ""} style={{ cursor:"pointer", marginLeft: "10px" }} />
                                      ) : <></>
                                    }
                                  </p>
                                </div>
                              </div>
                              <div className="col-xl-2 pl-xl-0">
                                <div className="profile-box-white box-min-h-100 justify-content-xl-center">
                                 {isKontakAdminHere ? (<button
                                    type="button"
                                    onClick={() => {
                                      
                                        switchLockForEditState(questionAnswer.id);
                                    }}
                                    className="btn btn-blue btn-height-36 border-0 font-size-14 mr-2 mt-2 mt-xl-0"
                                  >
                                    <img src={questionAnswer.isLockedForEdit === true ? require("../img/lock.svg") : require("../img/lock-opened.svg")} alt={questionAnswer.isLockedForEdit === true ? "lock" : "unlock"} />
                                  </button>) : questionAnswer.isLockedForEdit && (<button
                                    type="button"
                                    className="btn btn-blue btn-height-36 border-0 font-size-14 mr-2 mt-2 mt-xl-0"
                                  >
                                    <img src={require("../img/lock.svg")} alt="lock" />
                                  </button>)}
                                  {!questionAnswer.isLockedForEdit ? (<button
                                    id={"edit_" + questionAnswer.id}
                                    type="button"
                                    data-toggle="modal"
                                    data-target={"#modalEdit_" + questionAnswer.id}
                                    onClick={() => {
                                      setEditableQuestion(questionAnswer.question);
                                    }}
                                    className="btn btn-blue btn-width-130 btn-height-36 border-0 font-size-14 mr-2 mt-2 mt-xl-0"
                                  >
                                    Edit
                                  </button>) : isKontakAdminHere && <button
                                    id={"edit_" + questionAnswer.id}
                                    type="button"
                                    data-toggle="modal"
                                    data-target={"#modalEdit_" + questionAnswer.id}
                                    onClick={() => {
                                      setEditableQuestion(questionAnswer.question);
                                    }}
                                    className="btn btn-blue btn-width-130 btn-height-36 border-0 font-size-14 mr-2 mt-2 mt-xl-0"
                                  >
                                    Edit
                                  </button>}
                                  {isKontakAdminHere && (<button
                                    type="button"
                                    data-toggle="modal"
                                    data-target={
                                      "#modalDelete_" + questionAnswer.id
                                    }
                                    className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0"
                                  >
                                    <img src={require("../img/bin.svg")} alt="" />
                                  </button>)}
                                  {!isKontakAdminHere && questionAnswer.isPending && (<button
                                    type="button"
                                    data-toggle="modal"
                                    data-target={
                                      "#modalDelete_" + questionAnswer.id
                                    }
                                    className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0"
                                  >
                                    <img src={require("../img/bin.svg")} alt="" />
                                  </button>)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    else{
                      return (<></>);
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProfileCategory;
