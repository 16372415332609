import React from 'react';
import ReactDOM from 'react-dom';
import KontakDev from './dev'

import * as serviceWorker from './serviceWorker';

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
//import App from './App';
import NewApp from './app/app.js';
window.KontakDev = KontakDev
//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<NewApp />, document.getElementById('root'));

serviceWorker.unregister();
