import React, { useEffect, useState } from "react";
import ReportChart from "./components/reportChart.component";
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../authentification";
import ChartArchive from "./chatArchive";
import $ from "jquery";
import KontakDateRangePicker from "./components/kontakDateRangePicker";
import { useHistory } from "react-router-dom";
import FacilitiesPicker from "./components/facilitiesPicker";
import { PrivilegeChecker, Privileges } from "../privilegeChecker";
import DashboardReports from "./components/dashboardReports";
import { Steps } from 'intro.js-react';
import { useGlobal } from "./components/globalIntroState";
import CICReport from "./components/CICReport";
import _ from 'lodash'

const setDataToSessionStorage = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

// Get item from Session Storage
const getDataFromSessionStorage = (key) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

let chartTypeLocal = 6;

const Reports = (props) => {

  const [reportType, setReportType] = useState(null);
  const [reportFilters, setReportFilters] = useState(null);
  const [widgetTypes, setWidgetTypes] = useState(null);
  const getWidgetTypes = () => {
    ajaxCaller("Widget/GetWidgetTypes", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response, error) => {
      if (!response.isError) {
        setWidgetTypes(response);
      } else {
        console.log(error)
      }
    });
  };

  // First useEffect for fetching widget types
  useEffect(() => {
    getWidgetTypes();
  }, []);

  const initReportFilters = () => {
    let reportFilterz = {};
    if (props.location.widgetTypes) {
      removeFromSessionStorage("reportFilters");
    }

    let localWidgetTypes = widgetTypes || props.location.widgetTypes

    if (getDataFromSessionStorage("reportFilters") != null) {
      reportFilterz = getDataFromSessionStorage("reportFilters")
      setReportFilters(reportFilterz);
      return getDataFromSessionStorage("reportFilters");
    } else
      if (localWidgetTypes) {
        localWidgetTypes.forEach(widgetType => {

          let existingWidget = null
          if (props.location.allWidgets) {
            existingWidget = props.location.allWidgets.find(
              (x) => x.widgetTypeId === widgetType.id);
          }
          let anyWidget = localWidgetTypes.find(
            (x) => x.id === widgetType.id);

          if (existingWidget) {
            reportFilterz[widgetType.id] = {
              "withAfterHoursDiff": props.location.withAfterHoursDiff == null ? returnIfExistWithAfterHoursDiffFilter() : props.location.withAfterHoursDiff && widgetType.afterHours,
              "showPEPeriod": props.location.showPEPeriod == null ? returnIfExistShowPEPeriodFilter() : props.location.showPEPeriod,
              "showAdSeries": widgetType.additionalSeries && props.location.showAdSeries == null ? returnIfExistShowAdSeriesFilter() : props.location.showAdSeries,
              "showAfterHours": widgetType.afterHours,
              "withAfterHours": props.location.withAfterHours == null ? returnIfExistAfterHoursFilter() : props.location.withAfterHours && widgetType.afterHours,
              "groupIds": props.location.groupIds == null ? returnIfExistGroupIdsFilter() : props.location.groupIds,
              "dateRange": props.location.dateRange == null ? returnIfExistDateRangeFilter() : {
                dateFrom: new Date(props.location.dateRange.dateFrom).toLocaleDateString("en-US"),
                dateTo: new Date(props.location.dateRange.dateTo).toLocaleDateString("en-US")
              },
              "withTrendLine": props.location.withTrendLine == null ? returnIfExistTrendLineFilter() : props.location.withTrendLine,
              "additionalSeries": widgetType.additionalSeries
            }
          } else {
            reportFilterz[widgetType.id] = {
              "withAfterHoursDiff": props.location.withAfterHoursDiff == null ? returnIfExistWithAfterHoursDiffFilter() : props.location.withAfterHoursDiff && widgetType.afterHours,
              "showPEPeriod": props.location.showPEPeriod == null ? returnIfExistShowPEPeriodFilter() : props.location.showPEPeriod,
              "showAdSeries": anyWidget.additionalSeries && returnIfExistShowAdSeriesFilter(),
              "showAfterHours": widgetType.afterHours,
              "withAfterHours": props.location.withAfterHours == null ? returnIfExistAfterHoursFilter() : props.location.withAfterHours && widgetType.afterHours,
              "groupIds": props.location.groupIds == null ? returnIfExistGroupIdsFilter() : props.location.groupIds,
              "dateRange": props.location.dateRange == null ? returnIfExistDateRangeFilter() : {
                dateFrom: new Date(props.location.dateRange.dateFrom).toLocaleDateString("en-US"),
                dateTo: new Date(props.location.dateRange.dateTo).toLocaleDateString("en-US")
              },
              "withTrendLine": props.location.withTrendLine == null ? returnIfExistTrendLineFilter() : props.location.withTrendLine,
              "additionalSeries": anyWidget.additionalSeries
            }
          }
        });
        setDataToSessionStorage("reportFilters", reportFilterz)
        setReportFilters(reportFilterz);
      }
    return reportFilterz;
  };

  // First useEffect for fetching widget types
  useEffect(() => {
    getWidgetTypes();
  }, []);

  // useEffect to initialize report filters after widgetTypes have been updated
  useEffect(() => {
    if (widgetTypes) {
      initReportFilters();
    }
    /* eslint-disable-next-line */
  }, [widgetTypes]);

  //const [chartType, setChartType] = useState(chartTypeLocal)
  const [chartType, setChartType] = useState(chartTypeLocal)
  useEffect(() => {
    // This useEffect will trigger whenever chartType changes
    if (chartType !== chartTypeLocal) {
      chartTypeLocal = chartType;
    }
  }, [chartType]);

  useEffect(() => {
    setChartType((props.location.chartType == null ? (reportType ? reportType : 6) : props.location.chartType));
    /* eslint-disable-next-line */
  }, []);

  const changeCurrentReport = (reportType, keyword) => {
    // reportFilters[chartType].withAfterHours = withAfterHours;
    // reportFilters[chartType].withTrendLine = withTrendLine;
    // reportFilters[chartType].dateRange = dateRange;
    // reportFilters[chartType].groupIds = groupIds;
    // setReportFilters(reportFilters);


    // setGroupIds(reportFilters[reportType].groupIds);
    // setWithTrendLine(reportFilters[reportType].withTrendLine);

    Object(reportFilters[reportType]).showAdSeries = false;
    setDataToSessionStorage("reportFilters", reportFilters);
    setShowAdSeries(false);
    setChartType(reportType);
    setReportType(reportType);
    if (reportFilters && reportFilters[reportType]) {
      datePickerRef.current.setStartDate(_.get(reportFilters[reportType], 'dateRange.dateFrom'))
      datePickerRef.current.setEndDate(_.get(reportFilters[reportType], 'dateRange.dateTo'))
      // setWithAfterHours(reportFilters[reportType].withAfterHours);
      setDateRange(reportFilters[reportType].dateRange);
      if (reportFilters[reportType].showAfterHours !== undefined)
        setShowAfterHours(reportFilters[reportType].showAfterHours);
    }
    // setSearchKeyword(keyword);
  }





  const returnIfExistAfterHoursFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).withAfterHours == null ? false : Object(reportFilters[chartType]).withAfterHours)
    }
    return false;
  }

  const returnIfExistAllowAfterHoursFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).showAfterHours == null ? false : Object(reportFilters[chartType]).showAfterHours)
    }
    return false;
  }

  const returnIfExistWithAfterHoursDiffFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).withAfterHoursDiff == null ? false : Object(reportFilters[chartType]).withAfterHoursDiff)
    }
    return false;
  }

  const returnIfExistShowPEPeriodFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).showPEPeriod == null ? false : Object(reportFilters[chartType]).showPEPeriod)
    }
    return false;
  }

  const returnIfExistShowAdSeriesFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).showAdSeries == null ? false : Object(reportFilters[chartType]).showAdSeries)
    }
    return false;
  }

  const returnIfExistTrendLineFilter = () => {
    if (reportFilters && chartType !== "archive" && chartType !== 5) {
      return (Object(reportFilters[chartType]).withAfterHours == null ? false : Object(reportFilters[chartType]).withAfterHours)
    }
    return false;
  }

  const returnIfExistDateRangeFilter = () => {
    var tmpStart = new Date();
    var tmpEnd = new Date();

    tmpStart.setDate(tmpEnd.getDate() - 60);
    if (reportFilters) {
      return (Object(reportFilters[chartType]).dateRange == null ? {

        dateFrom: tmpStart.toLocaleDateString("en-US"),
        dateTo: tmpEnd.toLocaleDateString("en-US"),
      } : Object(reportFilters[chartType]).dateRange)
    }
    return {
      dateFrom: tmpStart.toLocaleDateString("en-US"),
      dateTo: tmpEnd.toLocaleDateString("en-US"),
    };
  }

  const returnIfExistGroupIdsFilter = () => {
    if (reportFilters) {
      return (Object(reportFilters[chartType]).groupIds == null ? null : Object(reportFilters[chartType]).groupIds)
    }
    return null;
  }



  const steps = [
    {
      element: '#card-body',
      intro: 'Reports will display its own reports for features that are part of your subscription. Each additional feature enabled will display own reports.',
      position: 'right',
      tooltipClass: 'intro-card-body',  // can be used for changing styles
      highlightClass: 'intro-card-body-hightlight', // can be used for changing styles when it is highlighted
    },
    PrivilegeChecker.hasPrivilege(
      Privileges.ExportData
    ) === true ?
      {
        element: '#card-body',
        intro: 'These reports are currently available as downloads.',
        position: 'right',
        tooltipClass: 'intro-export',  // can be used for changing styles
        highlightClass: 'intro-export-hightlight', // can be used for changing styles when it is highlighted
      } : null,
    {
      element: '#reports-calendar',
      intro: ' Set a preferred date range and time view. Your default setting is last 30 days in the daily view. ',
      position: 'left',
      // tooltipClass: 'intro-searches',  // can be used for changing styles
      highlightClass: 'intro-reports-calendar', // can be used for changing styles when it is highlighted
    },
    {
      element: '#headingOne-2',
      intro: 'See what your clients talk about. Review all their chats, filter by location, date range or search by keyword.',
      position: 'right',
      tooltipClass: 'intro-archive',  // can be used for changing styles
      highlightClass: 'intro-archive-hightlight', // can be used for changing styles when it is highlighted
    },
    {
      element: '#headingOne-3',
      intro: 'Your last searches within the chat archive. This feature can be a big time-saver if you are frequently reviewing chats using the same parameters.',
      position: 'right',
      tooltipClass: 'intro-searches',  // can be used for changing styles
      highlightClass: 'intro-searches-hightlight', // can be used for changing styles when it is highlighted
    }
  ].filter(x => x !== null);

  const [globalState] = useGlobal();
  const [userIntroEnabled, setUserIntroEnabled] = useState(false); // note enable steps ONLY if they are not finished\
  const [currentIntroStep, setCurrentIntroStep] = useState(null);
  const [introId, setIntroId] = useState(null);

  const changeCurrentStep = (step) => {
    ajaxCaller("UserIntro/UpdateStep/", {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        id: introId,
        currentStep: step
      }),
    }).then((response) => {
      if (response.isError) {
      } else {
      }
    });
  }

  const finishUserIntro = () => {
    ajaxCaller("UserIntro/FinishIntro/" + introId, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(false);
      }
    });
  }

  const getUserIntro = () => {
    ajaxCaller("UserIntro/UserIntro/1", {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(!response.finished);
        setCurrentIntroStep(response.currentStep);
        setIntroId(response.id);
      }
    });
  }

  useEffect(() => {
    if (!isKontakAdmin()) {
      getUserIntro();
    }
  }, [globalState.counter])

  const onExitStep = () => {
    finishUserIntro();
  };

  const onChangeStep = (step) => {
    if (step != null) {
      changeCurrentStep(step)
    }
  };


  const [withAfterHoursDiff, setWithAfterHoursDiff] = useState(props.location.withAfterHoursDiff == null ? returnIfExistWithAfterHoursDiffFilter() : props.location.withAfterHoursDiff);
  const [showPEPeriod, setshowPEPeriod] = useState(props.location.showPEPeriod == null ? returnIfExistShowPEPeriodFilter() : props.location.showPEPeriod);
  const [showAdSeries, setShowAdSeries] = useState(props.location.showAdSeries == null ? returnIfExistShowAdSeriesFilter() : props.location.showAdSeries);
  const [withAfterHours, setWithAfterHours] = useState(props.location.withAfterHours == null ? returnIfExistAfterHoursFilter() : props.location.withAfterHours);
  const [withTrendLine, setWithTrendLine] = useState(props.location.withTrendLine == null ? returnIfExistTrendLineFilter() : props.location.withTrendLine);
  const [showAfterHours, setShowAfterHours] = useState(props.location.showAfterHours == null ? returnIfExistAllowAfterHoursFilter() : props.location.showAfterHours);
  const [dateRange, setDateRange] = useState(props.location.dateRange == null ? returnIfExistDateRangeFilter() : {
    dateFrom: new Date(props.location.dateRange.dateFrom).toLocaleDateString("en-US"),
    dateTo: new Date(props.location.dateRange.dateTo).toLocaleDateString("en-US")
  });
  const [chartRangeType] = useState(props.location.chartRangeType == null ? 2 : props.location.chartRangeType);
  const [searchKeyword] = useState(props.match.params.searchKeyword);
  const [navBarIsOpened, setNavBarIsOpened] = useState(true);
  const [datePickerRef, setDatePickerRef] = useState(null);
  const [isShowIntros, setIsShowIntros] = useState(false);

  useEffect(() => {
    if (navBarIsOpened) {
      document.body.className = "opened-sub-nav";
      setIsShowIntros(true);
    } else {
      document.body.className = "app";
    }
  }, [navBarIsOpened]);

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const historyCallback = () => {
    history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) setLocationKeys([location.key]);
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          document.getElementById("nav-reports-page").className = "left-side-nav-link ";
          document.getElementById("nav-dashboard-page").className = "left-side-nav-link active";
          document.body.className = "app";
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
  }

  useEffect(historyCallback, [locationKeys]);
  const [groupIds, setGroupIds] = useState(props.location.groupIds == null ? returnIfExistGroupIdsFilter() : props.location.groupIds);
  const facilitiesChanged = (regionFacilities) => {
    var newGroupIds = [];
    regionFacilities.forEach(region => {
      region.groups.forEach(group => {

        if (group.checked) {
          newGroupIds.push(group.id);
        }
      })
    });

    if (reportFilters) {
      Object(reportFilters[chartType]).groupIds = newGroupIds;
      setDataToSessionStorage("reportFilters", reportFilters);
    }
    setGroupIds(newGroupIds);
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  const [lastSearches, setLastSearches] = useState(null);
  const getSearchArchive = () => {
    ajaxCaller("Search", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get"
    }).then((response) => {
      if (!response.isError) {
        setLastSearches(response);
      } else {
      }
    });
  }

  useEffect(() => {
    getSearchArchive();
  }, []);

  const setPickerRef = (ref) => {
    setDatePickerRef(ref);
  };

  return (
    <>
      {isShowIntros && userIntroEnabled && (currentIntroStep != null) && (<Steps
        enabled={userIntroEnabled}
        steps={steps}
        initialStep={currentIntroStep}
        onExit={onExitStep}
        onChange={onChangeStep}
      />)}
      <div id="left-side-bar" className="left-side-sub-nav">
        <div
          className="sub-nav-close"
          onClick={() => setNavBarIsOpened(!navBarIsOpened)}
        >
          <img src={require("../img/left.svg")} alt="" />
        </div>
        <div className="left-side-title">Reports</div>
        <div id={"card-body"} className="section pt-3">
          <div id="accordion">
            <div className="card mb-3">
              <div className="card-header" id="headingOne">
                <h5
                  id={"reports-charts"}
                  className="accordion-link sub-menu mb-0"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Calls
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="section pt-2">
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewCalls
                    ) && (<span
                      onClick={() => changeCurrentReport(6)}
                      className={
                        chartType === 6
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Total Calls
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(17)}
                      className={
                        chartType === 17
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Missed Calls
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(7)}
                      className={
                        chartType === 7
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Answered Calls
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(8)}
                      className={
                        chartType === 8
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Voicemail Calls
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(16)}
                      className={
                        chartType === 16
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Average Duration of Calls
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(13)}
                      className={
                        chartType === 13
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Calls Resulting in Appts.
                    </span>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordion-appointments">
            <div className="card mb-3">
              <div className="card-header" id="appointments">
                <h5
                  id={"reports-charts"}
                  className="accordion-link sub-menu mb-0"
                  data-toggle="collapse"
                  data-target="#collapseAppointments"
                  aria-expanded="true"
                  aria-controls="collapseAppointments"
                >
                  Appointments
                </h5>
              </div>
              <div
                id="collapseAppointments"
                className="collapse show"
                aria-labelledby="headingAppointments"
                data-parent="#accordion-appointments"
              >
                <div className="card-body">
                  <div className="section pt-2">
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(9)}
                      className={
                        chartType === 9
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Total Appointments
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(10)}
                      className={
                        chartType === 10
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      New Client Appts.
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewAppointments
                    ) && (<span
                      onClick={() => changeCurrentReport(11)}
                      className={
                        chartType === 11
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Returning Client Appts.
                    </span>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordion-1">
            <div className="card mb-3">
              <div className="card-header" id="headingTwo">
                <h5
                  id={"reports-charts"}
                  className="accordion-link sub-menu mb-0"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Chats & Appt. Requests
                </h5>
              </div>

              <div
                id="collapseTwo"
                className="collapse show"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="section pt-2">
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewChatsChartObject
                    ) && (<span
                      onClick={() => changeCurrentReport(1)}
                      className={
                        chartType === 1
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      Chats
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewLeadsChartObject
                    ) && (<span
                      onClick={() => changeCurrentReport(2)}
                      className={
                        chartType === 2
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link"
                      }
                    >
                      New Appointment Requests
                    </span>)}
                    {PrivilegeChecker.hasPrivilege(
                      Privileges.ViewConversionToLeadsChartObject
                    ) && (<span
                      onClick={() => changeCurrentReport(3)}
                      className={
                        chartType === 3
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link"
                      }
                      style={{ height: "auto" }}
                    >
                      New Appt./Total Chats
                    </span>)}
                    <span
                      onClick={() => changeCurrentReport(5)}
                      className={
                        chartType === 5
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link"
                      }
                      style={{ height: "auto" }}
                    >
                      CIC Report
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordion-1">
            <div className="card mb-3">
              <div className="card-header" id="headingOne-1">
                <h5
                  className="accordion-link sub-menu mb-0"
                  data-toggle="collapse"
                  data-target="#collapseOne-1"
                  aria-expanded="true"
                  aria-controls="collapseOne-1"
                >
                  Client Value
                </h5>
              </div>

              <div
                id="collapseOne-1"
                className="collapse show"
                aria-labelledby="headingOne-1"
                data-parent="#accordion-1"
              >
                {PrivilegeChecker.hasPrivilege(
                  Privileges.ViewClientValueChartObject
                ) && (<div className="card-body">
                  <div className="section pt-2">
                    <span
                      onClick={() => changeCurrentReport(4)}
                      className={
                        chartType === 4
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link"
                      }
                    >
                      New Client Lifetime Value
                    </span>
                  </div>
                </div>)}
              </div>
            </div>
          </div>
          {PrivilegeChecker.hasPrivilege(Privileges.ExportData) && (
            <DashboardReports startDate={dateRange.dateFrom} endDate={dateRange.dateTo} groupIds={groupIds}></DashboardReports>
          )}
          <div id="accordion-2">
            <div className="card pb-4">
              <div className="card-header" id="headingOne-2">
                <h5
                  className="accordion-link sub-menu mb-0"
                  data-toggle="collapse"
                  data-target="#collapseOne-2"
                  aria-expanded="true"
                  aria-controls="collapseOne-2"
                >
                  Chat Archive
                </h5>
              </div>

              <div
                id="collapseOne-2"
                className="collapse show"
                aria-labelledby="headingOne-2"
                data-parent="#accordion-2"
              >
                <div className="card-body">
                  <div className="section pt-2">
                    <span
                      onClick={() => {
                        changeCurrentReport("archive");
                      }}
                      className={
                        chartType === "archive" && searchKeyword == null
                          ? "sub-menu-link active animsition-link"
                          : "sub-menu-link animsition-link"
                      }
                    >
                      All chats
                    </span>
                  </div>
                  <div className="section pt-2 submenu-sub-accordion">
                    <div id="accordion-3">
                      <div className="card">
                        <div className="card-header" id="headingOne-3">
                          <h5
                            className="accordion-link sub-menu mb-0"
                            data-toggle="collapse"
                            data-target="#collapseOne-3"
                            aria-expanded="true"
                            aria-controls="collapseOne-3"
                          >
                            Last searches
                          </h5>
                        </div>

                        <div
                          id="collapseOne-3"
                          className="collapse show"
                          aria-labelledby="headingOne-3"
                          data-parent="#accordion-3"
                        >
                          <div className="card-body">
                            <div className="section pt-2">
                              {lastSearches && lastSearches.map(item =>
                              (<span
                                onClick={() => { changeCurrentReport("archive", item.keyword) }}
                                className={searchKeyword === item.keyword ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}
                              >
                                # {item.keyword}
                              </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              {chartType !== "archive" && (
                <div className="row mt-3 mt-lg-4">
                  <div className="col-auto align-self-center pl-2">
                    <h5 className="mb-0">
                      {chartType === 1
                        ? "Total Chats"
                        : chartType === 2
                          ? "New Appointment Requests"
                          : chartType === 3
                            ? "New Appt./Total Chats"
                            : chartType === 4
                              ? "Client Value"
                              : ""}
                    </h5>
                  </div>
                  <div className="col-12 col-lg align-self-center mt-2 mt-lg-0">
                    <div className="section">
                      <div className="row">
                        <FacilitiesPicker chartType={chartType} groupIds={groupIds} onChange={facilitiesChanged}></FacilitiesPicker>
                        {<>
                          {PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && (reportFilters && Object(reportFilters[chartType]).showAfterHours)  &&  // Here to determine whether to show AfterHours
                            (<><div className="col-auto align-self-center pr-1">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="afterh"
                                  checked={withAfterHours}
                                  onChange={() => {
                                    setWithAfterHours(!withAfterHours)
                                    if (reportFilters) {
                                      reportFilters[chartType].withAfterHours = !withAfterHours;
                                      setReportFilters(reportFilters);
                                      setDataToSessionStorage("reportFilters", reportFilters);
                                    }
                                  }
                                  }
                                ></input>
                                <label className="checkbox mb-0" htmlFor="afterh">
                                  After Hours
                                </label>
                              </div>
                            </div></>)}
                          {showAfterHours && withAfterHours && (
                            <>
                              <div className="col-auto align-self-center pr-1">
                                <div className="htmlForm-group">
                                  <input
                                    type="checkbox"
                                    id="afterhdiff"
                                    checked={withAfterHoursDiff}
                                    onChange={() => {

                                      setWithAfterHoursDiff(!withAfterHoursDiff)
                                      if (reportFilters) {
                                        Object(reportFilters[chartType]).withAfterHoursDiff = !withAfterHoursDiff;
                                        setReportFilters(reportFilters);
                                        setDataToSessionStorage("reportFilters", reportFilters);
                                      }
                                    }
                                    }
                                  />
                                  <label
                                    className="checkbox mb-0"
                                    htmlFor="afterhdiff"
                                  >
                                    Show Diff
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                          {(chartType !== 5 && <div className="col-auto align-self-center pr-1">
                            <div className="htmlForm-group">
                              <input
                                type="checkbox"
                                id="skippartial"
                                checked={showPEPeriod}
                                onChange={() => {
                                  setshowPEPeriod(!showPEPeriod);

                                  if (reportFilters && reportFilters.length) {
                                    reportFilters[chartType].showPEPeriod = !showPEPeriod;
                                    setReportFilters(reportFilters);
                                    setDataToSessionStorage("reportFilters", reportFilters);
                                  }
                                  ///Reset the "Show Diff" checkbox when "Show Partial End Period" is changed.
                                  setWithAfterHoursDiff(false);
                                }}
                              />
                              <label
                                className="checkbox mb-0"
                                htmlFor="skippartial"
                              >
                                Include Partial End Period
                              </label>
                            </div>
                          </div>)}
                          {((reportFilters && Object(reportFilters[chartType]).additionalSeries && (Object(reportFilters[chartType]).additionalSeries || []).length > 0) && chartType !== 5 && <div className="col-auto align-self-center pr-1">
                            <div className="htmlForm-group">
                              <input
                                type="checkbox"
                                id="skipseries"
                                checked={showAdSeries && reportFilters[chartType].showAdSeries}
                                onChange={() => {
                                  if (reportFilters) {
                                    setShowAdSeries(!reportFilters[chartType].showAdSeries);
                                    reportFilters[chartType].showAdSeries = !reportFilters[chartType].showAdSeries;
                                    setReportFilters(reportFilters);
                                    setDataToSessionStorage("reportFilters", reportFilters);
                                  }
                                }}
                              />
                              <label
                                className="checkbox mb-0"
                                htmlFor="skipseries"
                              >
                                Show Series
                              </label>
                            </div>
                          </div>)}
                          <div className="col-auto align-self-center pr-1">
                            {chartType !== 5 && (<div className="form-group">
                              <input
                                type="checkbox"
                                id="trendl"
                                checked={withTrendLine}
                                onChange={() => {
                            
                                    setWithTrendLine(!withTrendLine)  
                                    if (reportFilters) {                           
                                     reportFilters[chartType].withTrendLine = !reportFilters[chartType].withTrendLine;
                                     setReportFilters(reportFilters);
                                     setDataToSessionStorage("reportFilters", reportFilters);
                                  }
                                }
                                }
                              ></input>
                              <label className="checkbox mb-0" htmlFor="trendl">
                                Trend Line
                              </label>
                            </div>)}
                          </div>
                        </>}
                      </div>
                    </div>
                  </div>
                  <div id={"reports-calendar"} className="col-auto align-self-center mt-2 mt-lg-0">
                    <div className="section">
                      <KontakDateRangePicker
                        setPickerRef={setPickerRef}
                        startDate={dateRange.dateFrom}
                        endDate={dateRange.dateTo}
                        onChange={(evt, picker) => {

                          setDateRange({
                            dateFrom: picker.startDate
                              .toDate()
                              .toLocaleDateString("en-US"),
                            dateTo: picker.endDate
                              .toDate()
                              .toLocaleDateString("en-US"),
                          });
                          Object(reportFilters[chartType]).dateRange = {
                            dateFrom: picker.startDate
                              .toDate()
                              .toLocaleDateString("en-US"),
                            dateTo: picker.endDate
                              .toDate()
                              .toLocaleDateString("en-US"),
                          };
                          setReportFilters(reportFilters);
                          setDataToSessionStorage("reportFilters", reportFilters);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {chartType === 1 ? (
                <ReportChart
                  withTrendLine={withTrendLine}
                  withAfterHours={withAfterHours}
                  dateRange={dateRange}
                  isLeads={false}
                  chartName="Total Chats"
                  reportType={1}
                  groupIds={groupIds}
                  isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                  chartRangeType={chartRangeType}
                  showAfterHours={(reportFilters && reportFilters[1].showAfterHours) || props.location.showAfterHours}
                />
              ) : chartType === 2 ? (
                <ReportChart
                  withTrendLine={withTrendLine}
                  withAfterHours={withAfterHours}
                  dateRange={dateRange}
                  isLeads={true}
                  chartName="New Appointment Requests"
                  reportType={2}
                  groupIds={groupIds}
                  isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                  chartRangeType={chartRangeType}
                  showAfterHours={(reportFilters && reportFilters[2].showAfterHours) || props.location.showAfterHours}
                />
              ) : chartType === 3 ? (
                <ReportChart
                  withTrendLine={withTrendLine}
                  withAfterHours={(reportFilters && reportFilters[3].withAfterHours) || withAfterHours}
                  dateRange={dateRange}
                  isLeads={true}
                  chartName="New Appt./Total Chats"
                  reportType={3}
                  groupIds={groupIds}
                  isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                  chartRangeType={chartRangeType}
                  showAfterHours={(reportFilters && reportFilters[3].showAfterHours) || props.location.showAfterHours}
                />
              ) : chartType === 4 ? (
                <ReportChart
                  withTrendLine={withTrendLine}
                  withAfterHours={(reportFilters && reportFilters[4].withAfterHours) || withAfterHours}
                  dateRange={dateRange}
                  isLeads={true}
                  chartName="New Client Lifetime Value"
                  reportType={4}
                  groupIds={groupIds}
                  isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                  chartRangeType={chartRangeType}
                  showAfterHours={(reportFilters && reportFilters[4].showAfterHours) || props.location.showAfterHours}
                />
              )
                : chartType === 5 ? (
                  <CICReport
                    dateRange={dateRange}
                    chartName="New Client Lifetime Value"
                    groupIds={groupIds}
                    reportType={5}
                    chartRangeType={chartRangeType}
                  />
                )
                  : chartType === 6 ? (
                    <ReportChart
                      showPEPeriod={(reportFilters && reportFilters[6].showPEPeriod) || showPEPeriod}
                      withAfterHoursDiff={(reportFilters && reportFilters[6].withAfterHoursDiff) || withAfterHoursDiff}
                      withTrendLine={withTrendLine}
                      withAfterHours={(reportFilters && reportFilters[6].withAfterHours) || withAfterHours}
                      dateRange={dateRange}
                      isLeads={false}
                      chartName="Total Calls"
                      reportType={6}
                      groupIds={groupIds}
                      isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                      chartRangeType={chartRangeType}
                      showAfterHours={(reportFilters && reportFilters[6].showAfterHours) || showAfterHours}
                      additionalSeries={(reportFilters && reportFilters[6].additionalSeries) || props.location.additionalSeries}
                      showAdSeries={(reportFilters && reportFilters[6].showAdSeries) || showAdSeries}
                    />
                  )
                    : chartType === 7 ? (
                      <ReportChart
                        showPEPeriod={(reportFilters && reportFilters[7].showPEPeriod) || showPEPeriod}
                        withAfterHoursDiff={(reportFilters && reportFilters[7].withAfterHoursDiff) || withAfterHoursDiff}
                        withTrendLine={withTrendLine}
                        withAfterHours={(reportFilters && reportFilters[7].withAfterHours) || withAfterHours}
                        dateRange={dateRange}
                        isLeads={false}
                        chartName="Answered Calls"
                        reportType={7}
                        groupIds={groupIds}
                        isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                        chartRangeType={chartRangeType}
                        showAfterHours={(reportFilters && reportFilters[7].showAfterHours) || props.location.showAfterHours}
                        additionalSeries={(reportFilters && reportFilters[7].additionalSeries) || props.location.additionalSeries}
                        showAdSeries={(reportFilters && reportFilters[7].showAdSeries) || showAdSeries}
                      />
                    )
                      : chartType === 8 ? (
                        <ReportChart
                          showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                          withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                          withTrendLine={withTrendLine}
                          withAfterHours={(reportFilters && reportFilters[8].withAfterHours) || withAfterHours}
                          dateRange={dateRange}
                          isLeads={false}
                          chartName="Calls to Voicemail"
                          reportType={8}
                          groupIds={groupIds}
                          isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                          chartRangeType={chartRangeType}
                          showAfterHours={(reportFilters && reportFilters[8].showAfterHours) || props.location.showAfterHours}
                          additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                          showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                        />
                      )
                        : chartType === 9 ? (
                          <ReportChart
                            showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                            withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                            withTrendLine={withTrendLine}
                            withAfterHours={(reportFilters && reportFilters[9].withAfterHours) || withAfterHours}
                            dateRange={dateRange}
                            isLeads={false}
                            chartName="Total Appointments"
                            reportType={9}
                            groupIds={groupIds}
                            isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                            chartRangeType={chartRangeType}
                            showAfterHours={(reportFilters && reportFilters[9].showAfterHours) || props.location.showAfterHours}
                            additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                            showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                          />
                        )
                          : chartType === 10 ? (
                            <ReportChart
                              showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                              withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                              withTrendLine={withTrendLine}
                              withAfterHours={(reportFilters && reportFilters[10].withAfterHours) || withAfterHours}
                              dateRange={dateRange}
                              isLeads={false}
                              chartName="New Client Appointments"
                              reportType={10}
                              groupIds={groupIds}
                              isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                              chartRangeType={chartRangeType}
                              showAfterHours={(reportFilters && reportFilters[10].showAfterHours) || props.location.showAfterHours}
                              additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                              showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                            />
                          )
                            : chartType === 11 ? (
                              <ReportChart
                                showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                                withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                                withTrendLine={withTrendLine}
                                withAfterHours={(reportFilters && reportFilters[11].withAfterHours) || withAfterHours}
                                dateRange={dateRange}
                                isLeads={false}
                                chartName="Returning Client Appointments"
                                reportType={11}
                                groupIds={groupIds}
                                isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                chartRangeType={chartRangeType}
                                showAfterHours={(reportFilters && reportFilters[11].showAfterHours) || props.location.showAfterHours}
                                additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                                showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                              />
                            )
                              : chartType === 12 ? (
                                <ReportChart
                                  showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                                  withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                                  withTrendLine={withTrendLine}
                                  withAfterHours={(reportFilters && reportFilters[12].withAfterHours) || withAfterHours}
                                  dateRange={dateRange}
                                  isLeads={false}
                                  chartName="Average Duration of Calls"
                                  reportType={12}
                                  groupIds={groupIds}
                                  isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                  chartRangeType={chartRangeType}
                                  showAfterHours={(reportFilters && reportFilters[12].showAfterHours) || props.location.showAfterHours}
                                  additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                                  showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                                />
                              )
                                : chartType === 13 ? (
                                  <ReportChart
                                    showPEPeriod={(reportFilters && reportFilters[13].showPEPeriod) || showPEPeriod}
                                    withAfterHoursDiff={(reportFilters && reportFilters[13].withAfterHoursDiff) || withAfterHoursDiff}
                                    withTrendLine={withTrendLine}
                                    withAfterHours={(reportFilters && reportFilters[13].withAfterHours) || withAfterHours}
                                    dateRange={dateRange}
                                    isLeads={false}
                                    chartName="Answered Calls Resulting in Appt."
                                    reportType={13}
                                    groupIds={groupIds}
                                    isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                    chartRangeType={chartRangeType}
                                    showAfterHours={(reportFilters && reportFilters[13].showAfterHours) || props.location.showAfterHours}
                                    additionalSeries={(reportFilters && reportFilters[13].additionalSeries) || props.location.additionalSeries}
                                    showAdSeries={(reportFilters && reportFilters[13].showAdSeries) || showAdSeries}
                                  />
                                ) : chartType === 14 ? (
                                  <ReportChart
                                    showPEPeriod={(reportFilters && reportFilters[chartType].showPEPeriod) || showPEPeriod}
                                    withAfterHoursDiff={(reportFilters && reportFilters[chartType].withAfterHoursDiff) || withAfterHoursDiff}
                                    withTrendLine={withTrendLine}
                                    withAfterHours={(reportFilters && reportFilters[14].withAfterHours) || withAfterHours}
                                    dateRange={dateRange}
                                    isLeads={false}
                                    chartName="New Client Calls Resulting in Appointments"
                                    reportType={14}
                                    groupIds={groupIds}
                                    isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                    chartRangeType={chartRangeType}
                                    showAfterHours={(reportFilters && reportFilters[14].showAfterHours) || props.location.showAfterHours}
                                    additionalSeries={(reportFilters && reportFilters[chartType].additionalSeries) || props.location.additionalSeries}
                                    showAdSeries={(reportFilters && reportFilters[chartType].showAdSeries) || showAdSeries}
                                  />
                                ) : chartType === 15 ? (
                                  <ReportChart
                                    showPEPeriod={(reportFilters && reportFilters[15].showPEPeriod) || showPEPeriod}
                                    withAfterHoursDiff={(reportFilters && reportFilters[15].withAfterHoursDiff) || withAfterHoursDiff}
                                    withTrendLine={withTrendLine}
                                    withAfterHours={(reportFilters && reportFilters[15].withAfterHours) || withAfterHours}
                                    dateRange={dateRange}
                                    isLeads={false}
                                    chartName="Returning Client Calls Resulting in Appointments"
                                    reportType={15}
                                    groupIds={groupIds}
                                    isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                    chartRangeType={chartRangeType}
                                    showAfterHours={(reportFilters && reportFilters[15].showAfterHours) || props.location.showAfterHours}
                                    additionalSeries={(reportFilters && reportFilters[15].additionalSeries) || props.location.additionalSeries}
                                    showAdSeries={(reportFilters && reportFilters[15].showAdSeries) || showAdSeries}
                                  />
                                ) : chartType === 16 ? (
                                  <ReportChart
                                    showPEPeriod={(reportFilters && reportFilters[16].showPEPeriod) || showPEPeriod}
                                    withAfterHoursDiff={(reportFilters && reportFilters[16].withAfterHoursDiff) || withAfterHoursDiff}
                                    withTrendLine={withTrendLine}
                                    withAfterHours={(reportFilters && reportFilters[16].withAfterHours) || withAfterHours}
                                    dateRange={dateRange}
                                    isLeads={false}
                                    chartName="Average Duration of Calls"
                                    reportType={16}
                                    groupIds={groupIds}
                                    isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                    chartRangeType={chartRangeType}
                                    showAfterHours={(reportFilters && reportFilters[16].showAfterHours) || props.location.showAfterHours}
                                    additionalSeries={(reportFilters && reportFilters[16].additionalSeries) || props.location.additionalSeries}
                                    showAdSeries={(reportFilters && reportFilters[16].showAdSeries) || showAdSeries}
                                  />
                                ) : chartType === 17 ? (
                                  <ReportChart
                                    showPEPeriod={(reportFilters && reportFilters[17].showPEPeriod) || showPEPeriod}
                                    withAfterHoursDiff={(reportFilters && reportFilters[17].withAfterHoursDiff) || withAfterHoursDiff}
                                    withTrendLine={withTrendLine}
                                    withAfterHours={(reportFilters && reportFilters[17].withAfterHours) || withAfterHours}
                                    dateRange={dateRange}
                                    isLeads={false}
                                    chartName="Missed Calls"
                                    reportType={17}
                                    groupIds={groupIds}
                                    isLineChart={props.location.isLineChart == null ? true : props.location.isLineChart}
                                    chartRangeType={chartRangeType}
                                    showAfterHours={(reportFilters && reportFilters[17].showAfterHours) || props.location.showAfterHours}
                                    additionalSeries={(reportFilters && reportFilters[17].additionalSeries) || props.location.additionalSeries}
                                    showAdSeries={(reportFilters && reportFilters[17].showAdSeries) || showAdSeries}
                                  />
                                )
                                  : chartType === "archive" ? (
                                    <ChartArchive searchKeyword={searchKeyword} updateArchive={getSearchArchive} />
                                  ) : (
                                    <></>
                                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
