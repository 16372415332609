import React, { useState, useEffect } from "react";
import "../style.css";
import 'intro.js/introjs.css';
import Dashboard from "./dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Authentication from "./authentication.js";
import Reports from "./reports.js";
import Cookies from "js-cookie";
import {
  isAuthenticated,
  getCurrentUserName,
  getCurrentCompanyLogo,
  getCurrentUserAvatar,
  getIsEnableAppDirect,
  isKontakAdmin,
} from "../authentification";
import Profile from "./profile";
import ProfileCategory from "./profile.category";
import Regions from "./regions";
import AppointmentDirect from "./appointmentDirect"
import PawPass from './pawPass';
import Locations from "./locations";
import Team from "./team";
import UserProfile from "./userProfile";
import Releases from "./releases";
import Billing from "./billing";
import Company from "./company";
import EditLocation from "./editLocation";
import AdminPanel from "./adminPanel";
import $ from "jquery";
import ActivationPage from "./activationPage";
import KnowledgeBaseWidget from "../pages/knowledgeBaseWidget";
import { Steps } from 'intro.js-react';
import { ajaxCaller, authHeaders } from "../ajaxCaller";
import { useGlobal } from "./components/globalIntroState";
import KontakConfig from "./kontakConfig";
import kontakDni from "./kontakDni";
import EditDni from "./editDni";
import { Fragment } from "react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const App = () => {
  let defaultVersion = 'https://retriever';
  let domain = 'getkontak.com';


  if (window.location.hostname.includes('dev.getkontak.com') || window.location.hostname.includes('kontakuidev.getkontak.com') || window.location.hostname.includes('localhost')) {
    defaultVersion = 'https://cr-demo';
    domain = 'getkontak.com';
  }

  if (window.location.hostname.includes('kontakuiprod-stage2.azurewebsites.net')) {
    defaultVersion = 'https://cr-staging';
    domain = 'getkontak.com';
  }

  let version = window.PHONES_URL || `${defaultVersion}.${domain}`;

  const [currentPage] = useState(window.location.pathname);
  const [authenticated] = useState(isAuthenticated());
  const [avatar] = useState(getCurrentUserAvatar());
  const [companyLogo] = useState(getCurrentCompanyLogo());
  const [isShowAppDirect] = useState(!isKontakAdmin() && getIsEnableAppDirect());
  const isShowPasPass = "false"; // TODO: Fill it after discussing how to restring the PawPass
  const [userIntroEnabled, setUserIntroEnabled] = useState(false); // note enable steps ONLY if they are not finished\
  const [currentIntroStep, setCurrentIntroStep] = useState(null);
  const [groups] = useState(null);
  const [introId, setIntroId] = useState(null);
  const [globalState, globalActions] = useGlobal();

  const getUserIntro = () => {
    if (authHeaders.values.length > 0) {
      ajaxCaller("UserIntro/UserIntro/0", {
        method: "get",
        headers: authHeaders,
      }).then((response) => {
        if (response.isError) {
        } else {
          setUserIntroEnabled(!response.finished);
          setCurrentIntroStep(response.currentStep);
          setIntroId(response.id);
        }
      });
    }
  }

  const steps = [
    {
      element: '#empty',
      intro: '<p style="text-align:center;font-weight: bold;">Welcome to PetDesk Phones</p><p style="text-align:center;">We hope you will find it useful and exciting. </p><div> <p style="text-align:center"> Please take a few minutes and follow these tooltips by clicking “next”. You will become familiar with the functionality. You can, at any time, return to the tool tips by clicking on the ? on the top right of the screen <span style="display:inline-block" class="q-mark relative-pos"> ? </span>, or contact us at <b> support@getkontak.com </b> for a personal tour.. Enjoy!  </p></div>',
      position: 'right',
      tooltipClass: 'intro-main-welcome',  // can be used for changing styles
      //highlightClass: 'myHighlightClass', // can be used for changing styles when it is highlighted
    },
    {
      element: '#nav-dashboard-page',
      intro: 'Summary actionable data in a visual format.  Customize it with the most important KPIs. ',
      position: 'right',
      //tooltipClass: 'myTooltipClass',  // can be used for changing styles
      //highlightClass: 'myHighlightClass', // can be used for changing styles when it is highlighted
    },
    {
      element: '#nav-reports-page',
      intro: 'Detailed reporting section. Dig deeper into analytics and export valuable data. ',
      position: 'right',
    },
    // {
    //   element: '#appointment-direct',
    //   intro: 'Manage scheduled appointments, staff availability and set up appointment preferences.  ',
    //   position: 'right',
    // },
    {
      element: '#profile-page',
      intro: 'Provide up to date information for agents to handle visitor inquiries accurately. Keep current for your clients’ best experience! ',
      position: 'right',
    },
    {
      element: '#pas-pass-button',
      intro: 'Manage your Pet Health Savings Plan. ',   // Note this intro title should be appeared by flag when appointment direct is enabled
      position: 'right',
    },
    {
      element: '#settings-page',
      intro: 'Manage users, upload a logo, configure regions & locations and more. ',
      position: 'right',
    },
    {
      element: '#filter-element',
      intro: 'Use filters to toggle between locations. View single or multiple locations, during or after hours, and enable trendline.',
      position: 'right',
      highlightClass: 'intro-main-filters', // can be used for changing styles when it is highlighted
    },
    {
      element: '#settings-dashboard',
      intro: 'Add, edit or delete widgets, set default graph types and time ranges.',
      position: 'right',
    }
  ];


  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  const finishUserIntro = () => {
    ajaxCaller("UserIntro/FinishIntro/" + introId, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(false);
      }
    });
  }

  const changeCurrentStep = (step) => {
    ajaxCaller("UserIntro/UpdateStep/", {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        id: introId,
        currentStep: step
      }),
    }).then((response) => {
      if (response.isError) {
      } else {
      }
    });
  }

  const repeatIntro = (introType) => {
    ajaxCaller("UserIntro/RepeatIntro/" + introType, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        globalActions.addToCounter(1);
      }
    });

  }

  useEffect(() => {
    if (!isKontakAdmin() && (currentPage === "/" || currentPage === "/dashboard")) {
      getUserIntro();
    }
    // getGroups();
  }, [currentPage, globalState.counter]);

  const extractIntroTypeFromPage = () => {
    switch (currentPage) {
      case '/': return 0
      case '/dashboard': return 0;
      case '/reports': return 1
      case '/profile': return 2
      case (currentPage.match('profile/([\\w-]+)$') || {}).input: return 2
      case (currentPage.match('profile/([\\w-]+)/([\\w-]+)') || {}).input: return 3
      case (currentPage.match('settings/([\\w-]+)$') || {}).input: return 4
      default: return 0
    }
  }

  const onExitStep = () => {
    finishUserIntro();
  };

  const onChangeStep = (step) => {
    if (step != null && introId) {
      changeCurrentStep(step)
    }
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {authenticated && (currentIntroStep != null) && userIntroEnabled && (<Steps
        enabled={userIntroEnabled}
        steps={steps}
        initialStep={currentIntroStep}
        onExit={onExitStep}
        onChange={onChangeStep}
      />)}
      <Router>
        {(currentPage === "/" || currentPage !== "/authentication") &&
          !authenticated && (
            <div>
              <Route
                path="/"
                component={
                  !authenticated &&
                  currentPage !== "/knowledgeBaseWidget" &&
                  !currentPage.includes("passwordConfirm") &&
                  Authentication
                }
              />
              <Route
                exact path="/passwordConfirm/:secretKey"
                component={
                  !authenticated &&
                  currentPage !== "/authentication" &&
                  ActivationPage
                }
              />
            </div>
          )}

        {!authenticated && currentPage === "/knowledgeBaseWidget" && (
          <div
            style={{
              display:
                currentPage === "/" || currentPage === "/dashboard"
                  ? "none"
                  : "",
            }}
          >
            <KnowledgeBaseWidget />
          </div>
        )}

        {authenticated && (
          <>
            {currentPage !== "/knowledgeBaseWidget" && (<div className="left-side-nav">
              <div className="left-logo">
                <img src={require("../img/logo-k.png")} alt="logo" />
              </div>
              <a href="/dashboard">
                <div
                  id="nav-dashboard-page"
                  className={currentPage === "/dashboard" || currentPage === "/" ? "left-side-nav-link active" : "left-side-nav-link"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="Dashboard"
                >
                  <img src={require("../img/link1.svg")} alt="logo" />
                </div>
              </a>
              <a href="/reports">
                <div
                  id="nav-reports-page"
                  className={currentPage === "/reports" ? "left-side-nav-link bar-tooltip active" : "left-side-nav-link bar-tooltip"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="Reports"
                >
                  <img src={require("../img/link2.svg")} alt="logo" />
                </div>
              </a>
              <a href="/profile">
                <div
                  id="profile-page"
                  className={currentPage.includes("profile") ? "left-side-nav-link active" : "left-side-nav-link"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="Profile"
                >
                  <img src={require("../img/link3.svg")} alt="logo" />
                </div>
              </a>
              {isShowAppDirect === "true" ? (<a href="/appointmentdirect">
                <div
                  id="appointment-direct"
                  className={currentPage === "/appointmentdirect" ? "left-side-nav-link active" : "left-side-nav-link"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="Appointment Direct"
                >
                  <img src={require("../img/appointmentdirect.svg")} alt="logo" />
                </div>
              </a>) : (<></>)}
              {isShowPasPass === "true" ? (<a href="/pawpass">
                <div
                  id="pas-pass-button"
                  className={currentPage === "/pawpass" ? "left-side-nav-link active" : "left-side-nav-link"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="PawPass"
                >
                  <img src={require("../img/pawPassIcon.png")} alt="logo" />
                </div>
              </a>) : (<></>)}
              <a href="/settings/team">
                <div
                  id="settings-page"
                  className={currentPage.includes("settings") ? "left-side-nav-link active" : "left-side-nav-link"}
                  data-toggle="tooltip"
                  data-offset="0 5"
                  data-placement="right"
                  title="Settings"
                >
                  <img src={require("../img/link4.svg")} alt="logo" />
                </div>
              </a>
            </div>)}

            <div id="globalSpinner" className="load"></div>

            {currentPage !== "/knowledgeBaseWidget" && (<div className="header">
              <div className="container-fluid m-0 p-0">
                <div className="row justify-content-center header-height">
                  <div className="col align-self-center">
                    {companyLogo != null && companyLogo !== " " && companyLogo !== "" && companyLogo !== "null" && (
                      <div className="client-logo ml-5 pl-3 pl-lg-4 d-none d-sm-block">
                        <img id="company-logo" src={
                          "https://kontakstorage.blob.core.windows.net/company-logos/" + companyLogo
                        } style={{ display: (companyLogo != null && companyLogo !== " " && companyLogo !== "" && companyLogo !== "null") === true ? "" : "none" }} alt="logo" />
                      </div>)}
                  </div>
                  {/* hide until we dont ON intro */}
                  <div
                    style={{ marginTop: "18px", marginRight: '20px', cursor: 'pointer' }}
                    className="relative-pos"
                    onClick={() => {
                      window.open(`${version}`, '_blank')
                    }}
                  >
                    PetDesk Phones
                  </div>
                  {false && <>
                    {groups.length === 1 ? (
                      <div
                        style={{ marginTop: "18px", marginRight: '20px', cursor: 'pointer' }}
                        className="relative-pos"
                        onClick={() => {
                          window.open(`${version}/${groups[0].groupKey}`, '_blank')
                        }}
                      >
                        PetDesk Phones
                      </div>
                    ) : (
                      <div className="dropdown navbar mr-3 mr-lg-4">
                        <div
                          className="petdesk-phones"
                          type="button"
                          id="dropdownKontakRetriever"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="petdesk-phones">
                            PetDesk Phones
                          </div>
                        </div>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{ minWidth: "190px", marginTop: '0px' }}
                          aria-labelledby="dropdownKontakRetriever"
                        >
                          {groups.map((group) => (
                            <Fragment key={`link-${group.id}`}>
                              <a
                                href={`${version}/${group.groupKey}`}
                                className="link-dark font-size-14 line-height-14 mb-2 font-weight-500"
                                key={`link-${group.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {group.name}
                              </a>

                              <div className="clearfix" key={`clearfix-${group.id}`}></div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                  }
                  {!isKontakAdmin() && (
                    <div
                      style={{ marginTop: "23px" }}
                      className="q-mark relative-pos"
                      data-toggle="tooltip"
                      data-offset="0 5"
                      data-placement="top"
                      title="Launch tooltips"
                      onClick={() => {
                        let introType = extractIntroTypeFromPage();
                        repeatIntro(introType);
                      }}
                    >
                      ?
                    </div>
                  )}
                  <div className="col-auto align-self-center">
                    <div className="dropdown navbar mr-3 mr-lg-4">
                      <div
                        className="user-section"
                        type="button"
                        id="dropdownUser"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="user-img">
                          <img id="nav-bar-avatar"
                            src={
                              (avatar == null || avatar === " " || avatar === "null" || avatar === "")
                                ? "https://kontakstorage.blob.core.windows.net/avatars/defaultLogo.svg"
                                : "https://kontakstorage.blob.core.windows.net/avatars/" + avatar
                            }
                            className="mr-1"
                            alt="user"
                          />
                        </div>
                        <span type="text" id="nav-bar-userName" className="mr-1">{getCurrentUserName()}</span>
                        <div className="down-img">
                          <img src={require("../img/down.svg")} alt="down" />
                        </div>
                      </div>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownUser"
                      >
                        <a
                          href="/user"
                          className="link-dark font-size-14 line-height-14 mb-2 font-weight-500"
                        >
                          View Profile
                        </a>
                        <div className="clearfix"></div>
                        <a
                          href="index.html"
                          className="link-dark font-size-14 line-height-14 mb-2 font-weight-500"
                        >
                          Help
                        </a>
                        <div className="clearfix"></div>
                        <a
                          href="./releases"
                          className="link-dark font-size-14 line-height-14 mb-2 font-weight-500"
                        >
                          What's New
                        </a>
                        <div className="clearfix"></div>
                        <a
                          href="./"
                          onClick={() => {
                            Cookies.remove('access_token');
                            Cookies.remove('logged_in_date');
                            Cookies.remove('role_id');
                            Cookies.remove('user_id');
                          }}
                          className="link-dark font-size-14 line-height-14 font-weight-500"
                        >
                          <img
                            src={require("../img/logout.svg")}
                            className="mr-1"
                            alt="logout"
                          />
                          Sign Out
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}


            <div>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/profile/:groupId?" component={Profile} />
              <Route exact path="/profile/:groupId/:categoryId" component={ProfileCategory} />
              <Route path="/settings/regions" component={Regions} />
              <Route exact path="/settings/locations" component={Locations} />
              <Route exact path="/settings/locations/:groupId" component={EditLocation} />
              <Route path="/settings/team" component={Team} />
              <Route path="/settings/billing" component={Billing} />
              <Route path="/user" component={UserProfile} />
              <Route path="/releases" component={Releases} />
              <Route path="/settings/company" component={Company} />
              <Route path="/settings/admin-panel" component={AdminPanel} />
              <Route exact path="/appointmentdirect" component={AppointmentDirect} />
              <Route exact path="/pawpass" component={PawPass} />
              <Route path="/knowledgeBaseWidget" component={KnowledgeBaseWidget} />
              <Route path="/settings/kontakConfig" component={KontakConfig} />
              <Route exact path="/settings/kontakDni" component={kontakDni} />
              <Route exact path="/settings/kontakDni/:dniUrlId" component={EditDni} />
            </div>
          </>
        )}
      </Router>
    </QueryClientProvider>
  );
};

export default App;
