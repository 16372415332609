import Cookies from "js-cookie";
window.$Cookies = Cookies
export class PrivilegeChecker {
    static hasPrivilege(privilege) {
        const privileges = JSON.parse(Cookies.get("privileges"));
        return privileges.includes(privilege);
    }
}

export class Privileges {
    static ViewAfterHoursTrendLine = 'ViewAfterHoursTrendLine';
    static ExportData = "ExportData";
    static ViewChatsChartObject = "ViewChatsChartObject";
    static ViewLeadsChartObject = "ViewLeadsChartObject";
    static ViewConversionToLeadsChartObject = "ViewConversionToLeadsChartObject";
    static ViewClientValueChartObject = "ViewClientValueChartObject";
    static ViewEditWorkHours = "ViewEditWorkHours";
    static ViewEditClientValue = "ViewEditClientValue";
    static ViewEditUsersAndRoles = "ViewEditUsersAndRoles";
    static ViewEditFacilityAndRegions = "ViewEditFacilityAndRegions";
    static ViewCalls = "ViewCalls";
    static ViewAppointments = "ViewAppointments";
}