import React, { useEffect } from "react";
import $ from "jquery";


const MessageModal = (props) => {
  const setAndShowPrompt = () => {
    if (props.promptMessage && props.promptMessage !== "" && props.promptMessage != null) {
      $('#modalPrompt').modal('toggle');
    }
  }
  useEffect(setAndShowPrompt, [props.promptMessage])

  //useEffect(setAndShowPrompt, [])

  return (
    <>
      <div
        className="modal fade"
        id="modalPrompt"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalCreateRole"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body py-3">
              <div className="container-fluid">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-4 font-weight-700">Operation successful!</h5>
                  </div>
                </div>
                {props.promptMessage && (<h6 className="alert alert-success" role="alert">{props.promptMessage}</h6>)}
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                      // onClick={props.clearMessage}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageModal;