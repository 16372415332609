import React, { useState, useEffect } from "react";
import { PrivilegeChecker, Privileges } from "../../privilegeChecker";
import { isKontakAdmin } from "../../authentification";
import { Steps } from 'intro.js-react';
import { useGlobal } from "./globalIntroState";
import { ajaxCaller, authHeaders } from "../../ajaxCaller";

const SettingsPageNavBar = (props) => {
  const [navBarIsOpened, setNavBarIsOpened] = useState(true);
  const [isShowIntros, setIsShowIntros] = useState(false);
  useEffect(() => {
    if (navBarIsOpened) {
      document.body.className = "opened-sub-nav";
      setIsShowIntros(true)
    } else {
      document.body.className = "app";
    }
  }, [navBarIsOpened]);

  const steps = [
    PrivilegeChecker.hasPrivilege(
      Privileges.ViewEditUsersAndRoles
    ) === true ?
    {
      element: '#section-team',
      intro: 'Manage team members, create and assign roles to them, and invite them to login to PetDesk Phones.  ',
      position: 'right',
    } : null,
    PrivilegeChecker.hasPrivilege(
      Privileges.ViewEditFacilityAndRegions
    ) === true ? {
      element: '#section-locations',
      intro: 'All settings related to your location, or multiple locations depending on the size of your business.  ',
      position: 'right',
    } : null,
    PrivilegeChecker.hasPrivilege(
      Privileges.ViewEditFacilityAndRegions
    ) === true ? {
      element: '#section-regions',
      intro: 'Group multiple locations into regions.',
      position: 'right',
    } : null,
    {
      element: '#section-company',
      intro: ' Personalize your account with your logo.',
      position: 'right',
      tooltipClass: 'intro-settings',  // can be used for changing styles
      highlightClass: 'intro-settings-hightlight', // can be used for changing styles when it is highlighted
    },
    {
      element: '#section-billing',
      intro: 'See all your invoices and manage subscriptions (coming soon). ',
      position: 'right',
    }
  ].filter(x => x !== null);
  
  const [globalState] = useGlobal();
  const [userIntroEnabled, setUserIntroEnabled] = useState(false); // note enable steps ONLY if they are not finished\
  const [currentIntroStep, setCurrentIntroStep] = useState(null);
  const [introId, setIntroId] = useState(null); 

  const changeCurrentStep = (step) => {
    ajaxCaller("UserIntro/UpdateStep/", {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        id: introId,
        currentStep: step
       }),
    }).then((response) => {
      if (response.isError) {
      } else {
      }
    });
  }

  const finishUserIntro = () => {
    ajaxCaller("UserIntro/FinishIntro/" + introId, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(false);
      }
    });
  }
  
  const getUserIntro = () => {
    ajaxCaller("UserIntro/UserIntro/4", {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(!response.finished);
        setCurrentIntroStep(response.currentStep);
        setIntroId(response.id);
      }
    });
  }

  useEffect(() => {
    if(!isKontakAdmin())
    {
      getUserIntro();
    }
  }, [globalState.counter])

  const onExitStep = () => {
     finishUserIntro();
 };

 const onChangeStep = (step) => {
  if(step != null)
  {
    changeCurrentStep(step)
  }
 };

  return (
    <>  {isShowIntros && userIntroEnabled && (currentIntroStep != null) && <Steps
      enabled={userIntroEnabled}
      steps={steps}
      initialStep={currentIntroStep}
      onExit={onExitStep}
      onChange={onChangeStep}
    />}
    <div className="left-side-sub-nav">
     
      <div
        className="sub-nav-close"
        onClick={() => setNavBarIsOpened(!navBarIsOpened)}
      >
        <img src={require("../../img/left.svg")} alt="" />
      </div>
      <div className="left-side-title">Settings</div>
      <div  className="section pt-3">
        <a id={"section-company"} href="company" className={props.pageName === "company" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          General
      </a>
        <a  id={"section-billing"} href="billing" className={props.pageName === "billing" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Billing
      </a>
        {PrivilegeChecker.hasPrivilege(
          Privileges.ViewEditUsersAndRoles
        ) && (<a  id={"section-team"} href="team" className={props.pageName === "team" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Team
        </a>)}

        {PrivilegeChecker.hasPrivilege(
          Privileges.ViewEditFacilityAndRegions
        ) && (<a  id={"section-regions"} href="regions" className={props.pageName === "regions" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Regions
        </a>)}
        {PrivilegeChecker.hasPrivilege(
          Privileges.ViewEditFacilityAndRegions
        ) && (<a  id={"section-locations"} href="locations" className={props.pageName === "locations" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Locations
        </a>)}
        {/* {PrivilegeChecker.hasPrivilege(
          Privileges.ViewChatsChartObject
        ) && (<a  id={"section-locations"} href="widgets" className={props.pageName === "widgets" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Widgets
        </a>)} */}
        {isKontakAdmin() && (<a href="admin-panel" className={props.pageName === "admin-panel" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          Admin Panel
        </a>)}
        {isKontakAdmin() && (<a href="kontakConfig" className={props.pageName === "kontakConfig" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          PetDesk Configuration
        </a>)}
        {isKontakAdmin() && (<a href="kontakDni" className={props.pageName === "kontakDni" ? "sub-menu-link active animsition-link" : "sub-menu-link animsition-link"}>
          DNI
        </a>)}

      </div>
    </div>
    </>
  )
}

export default SettingsPageNavBar;