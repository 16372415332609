// NOTE: Don't forget to bind it before using: this.handleInputChange = this.handleInputChange.bind(this);
export function inputChange(event, callBack) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    if (callBack != null) {
      callBack();
    }
  }

  export function currentDateInUtcAsString() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    var date = yyyy + '-' + mm + '-' + dd + "T00:00:00Z";

    return date;
  }

  export function firstDateOfCurrentMonth() {
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    var date = yyyy + '-' + mm + '-01T00:00:00Z';

    return date;
  }