import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const doNothing = () => {};

const TextInput = forwardRef(({
  label,
  value,
  onChange = doNothing,
  placeholder = "",
  disabled = false,
  autoComplete = true,
  children,
  onBlur,
  errorMsg
}, ref) => (
  <div className="row mt-3">
    <div className="col-md-3 col-xl-2 align-self-center pr-sm-0">
      <p className="mb-2 mb-md-0 font-weight-500">{label}</p>
    </div>
    <div className="col-md-5 col-xl-4 align-self-center">
      <div className="form-group">
        <input
          type="text"
          className="form-style border-0 small-40"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete ? "on" : "off"}
          disabled={disabled}
          onBlur={onBlur}
          ref={ref}
        />
        {children}
      </div>
      {
        errorMsg && (
          <div className="form-group error">
            <label>
                {errorMsg}
            </label>
          </div>
        )
      }
    </div>
  </div>
));

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([undefined])
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  errorMsg:PropTypes.string
};

export default TextInput;
