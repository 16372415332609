import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import Dragula from "react-dragula";
import $ from "jquery";
import Select2 from "react-select2-wrapper";
import FacilitiesPicker from "./components/facilitiesPicker";
import { PrivilegeChecker, Privileges } from "../privilegeChecker";
import EditMode from "./components/Dashboard/editMode";
import ChartsArea from "./components/Dashboard/chartsArea";
import EditButton from "./components/Dashboard/parts/editButton";

const Dashboard = () => {
  const [withAfterHours, setWithAfterHours] = useState(false);
  const [withAfterHoursDiff, setWithAfterHoursDiff] = useState(false);
  const [showPEPeriod, setshowPEPeriod] = useState(false);
  const [showAdSeries, setShowAdSeries] = useState(false);
  const [withTrendLine, setWithTrendLine] = useState(false);
  const [allWidgets, setAllWidgets] = useState([]);

  const getItems = () => {
    ajaxCaller("Widget/GetUserWidgets", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (response.isError) {
      } else {
        let widgets = [];

        response && response.forEach(item => {
          if (PrivilegeChecker.hasPrivilege(Privileges.ViewChatsChartObject) &&
            item.widgetTypeId.toString() === "1") {
            widgets.push(item);
          }
          if (
            PrivilegeChecker.hasPrivilege(Privileges.ViewLeadsChartObject) &&
            item.widgetTypeId.toString() === "2"
          ) {
            widgets.push(item);
          }
          if (
            PrivilegeChecker.hasPrivilege(
              Privileges.ViewConversionToLeadsChartObject
            ) &&
            item.widgetTypeId.toString() === "3"
          ) {
            widgets.push(item);
          }
          if (
            PrivilegeChecker.hasPrivilege(Privileges.ViewClientValueChartObject) &&
            item.widgetTypeId.toString() === "4"
          ) {
            widgets.push(item);
          }
          if (
            parseInt(item.widgetTypeId.toString()) >= 5
          ) {
            widgets.push(item);
          }
        })

        setAllWidgets(widgets);
      }
    });
  };

  const updateWidgets = (newWidgets) => {
    ajaxCaller("Widget/UpdateWidgets", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify(newWidgets),
    }).then((response) => {
      if (response.isError) {
      } else {
        window.location.reload();
        //setAllWidgets(newWidgets);
        //editModeClick();
      }
    });
  };

  const [rightColumnElements, setRightColumnElements] = useState([]);
  const [leftColumnElements, setLeftColumnElements] = useState([]);
  const reorderWigets = () => {
    let reorderedWidgets = [];

    for (let index = 0; index < rightColumnElements.length; index++) {
      let id = rightColumnElements[index];
      reorderedWidgets.push({ id: id, rowIndex: index, columnIndex: 1 });
    }

    for (let index = 0; index < leftColumnElements.length; index++) {
      let id = leftColumnElements[index];
      reorderedWidgets.push({ id: id, rowIndex: index, columnIndex: 0 });
    }

    let newWidgets = [...allWidgets];

    newWidgets.forEach(function (item) {
      reorderedWidgets.forEach(function (element) {
        console.log(element.id.toString())
        if ("widget_" + item.id === element.id.toString()) {
          item.rowIndex = element.rowIndex;
          item.columnIndex = element.columnIndex;
        }
      });
    });

    if (newWidgets.length > 0) {
      updateWidgets(newWidgets);
    }
  };

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  useEffect(() => {
    document.body.classList.add("opened-filter");
  }, [])

  const [filterOpened, setFilterOpened] = useState(true);
  const toggleFilter = () => {
    setFilterOpened(!filterOpened);
    if (filterOpened) {
      document.body.classList.remove("opened-filter");
    } else {
      document.body.classList.add("opened-filter");
    }
  };

  const [dragular, setDragular] = useState(null);
  const setupDrake = () => {
    let leftColumnContainer = document.getElementById("leftContainer");
    let rightColumnContainer = document.getElementById("rightContainer");
    let drake = Dragula([leftColumnContainer, rightColumnContainer], {
      moves: (el, target, source, sibling) => {
        return editMode;
      },
    });
    drake.on("drop", (el, target, source, sibling) => {
      let lc = Array.prototype.slice.call(
        document.getElementById("leftContainer").children
      ).map(x => x.firstChild.id);
      let rc = Array.prototype.slice.call(
        document.getElementById("rightContainer").children
      ).map(x => x.firstChild.id);
      setLeftColumnElements(lc);
      setRightColumnElements(rc);

      let reorderedWidgets = [];

      for (let index = 0; index < rightColumnElements.length; index++) {
        let id = rightColumnElements[index];
        reorderedWidgets.push({ id: id, rowIndex: index, columnIndex: 1 });
      }

      for (let index = 0; index < leftColumnElements.length; index++) {
        let id = leftColumnElements[index];
        reorderedWidgets.push({ id: id, rowIndex: index, columnIndex: 0 });
      }

      let nw = [...allWidgets];
      nw.forEach(function (item) {
        reorderedWidgets.forEach(function (element) {
          if ("widget_" + item.id === element.id.toString()) {
            item.rowIndex = element.rowIndex;
            item.columnIndex = element.columnIndex;
          }
        });
      });
      setAllWidgets(nw);
    });

    setDragular(drake);
  };

  const [editMode, setEditMode] = useState(false);
  const editModeClick = (event) => {
    if (event != null) {
      event.preventDefault();
    }

    setEditMode(!editMode);
    if (editMode) {
      document.body.classList.remove("home-d-box-edit");
    } else {
      document.body.classList.add("home-d-box-edit");
    }
  };

  const saveWidgetsPositions = () => {
    reorderWigets();
    clearSingleWidgetFields();
  };

  const [groupIds, setGroupIds] = useState(null);
  const facilitiesChanged = (regionFacilities) => {
    let newGroupIds = [];
    regionFacilities.forEach((region) => {
      region.groups.forEach((group) => {
        if (group.checked) {
          newGroupIds.push(group.id);
        }
      });
    });
    setGroupIds(newGroupIds);
  };

  const editModeCallback = () => {
    if (editMode) {
      setupDrake();
    } else {
      dragular && dragular.destroy();
    }
  };
  useEffect(editModeCallback, [editMode]);

  const hookCallback = () => {
    if (allWidgets.length === 0) {
      getItems();
    }
  };
  useEffect(hookCallback, []);

  const [widgetTypes, setWidgetTypes] = useState(null);
  const getWidgetTypes = () => {
    ajaxCaller("Widget/GetWidgetTypes", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        let filteredTypes = response.filter((x) => {
          if (
            PrivilegeChecker.hasPrivilege(Privileges.ViewChatsChartObject) &&
            x.id === 1
          ) {
            return x;
          }
          if (
            PrivilegeChecker.hasPrivilege(Privileges.ViewLeadsChartObject) &&
            x.id === 2
          ) {
            return x;
          }
          if (
            PrivilegeChecker.hasPrivilege(
              Privileges.ViewConversionToLeadsChartObject
            ) &&
            x.id === 3
          ) {
            return x;
          }
          if (
            PrivilegeChecker.hasPrivilege(
              Privileges.ViewClientValueChartObject
            ) &&
            x.id === 4
          ) {
            return x;
          }
          if (
            x.id >= 5 && x.id <= 7
          ) {
            return x;
          }
          if (
            x.id >= 7
          ) {
            return x;
          }
          return null;
        });
        setWidgetTypes(filteredTypes);
      } else {
      }
    });
  };

  useEffect(() => {
    getWidgetTypes();
  }, []);

  const [singleWidgetTypeId, setSingleWidgetTypeId] = useState(null);
  const [singleWidgetIsExpanded, setSingleWidgetIsExpanded] = useState(false);
  const [
    singleWidgetLastPeriodRange,
    setSingleWidgetLastPeriodRange,
  ] = useState(1);
  const [singleWidgetPeriodType, setSingleWidgetPeriodType] = useState(null);
  const [singleWidgetIsLineChart, setSingleWidgetIsLineChart] = useState(true);
  const [showXLabels, setShowXLabels] = useState();
  const [showYLabels, setShowYLabels] = useState();
  const [editWidgetId, setEditWidgetId] = useState(null);
  const clearSingleWidgetFields = () => {
    setSingleWidgetTypeId(null);
    setSingleWidgetIsExpanded(false);
    setSingleWidgetLastPeriodRange(1);
    setSingleWidgetPeriodType(null);
    setSingleWidgetIsLineChart(true);
    setShowXLabels(true);
    setShowYLabels(true);
  };
  const saveSingleWidget = () => {
    ajaxCaller("Widget/AddWidget", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        rowIndex:
          allWidgets.filter(x => x.columnIndex === 0).length > 0
            ? allWidgets.filter(x => x.columnIndex === 0)[allWidgets.filter(x => x.columnIndex === 0).length - 1].rowIndex + 1
            : 0,
        columnIndex: 0,
        lastPeriodRange: singleWidgetLastPeriodRange,
        periodType: singleWidgetPeriodType,
        widgetTypeId: singleWidgetTypeId,
        isExpanded: singleWidgetIsExpanded,
        isLineChart: singleWidgetIsLineChart,
        showXLabels: showXLabels,
        showYLabels: showYLabels

      }),
    }).then((response) => {
      if (!response.isError) {
        clearSingleWidgetFields();
        getItems();
      } else {
      }
    });
  };

  const selectForEdit = (widget) => {
    setEditWidgetId(widget.id);
    setSingleWidgetIsExpanded(widget.isExpanded);
    setSingleWidgetLastPeriodRange(widget.lastPeriodRange);
    setSingleWidgetPeriodType(widget.periodType);
    setSingleWidgetIsLineChart(widget.isLineChart);
    setSingleWidgetTypeId(widget.widgetTypeId);
    setShowXLabels(widget.showXLabels);
    setShowYLabels(widget.showYLabels)
  };

  const updateWidget = () => {
    let newWidgets = [...allWidgets];
    newWidgets.forEach(function (item) {
      if (item.id.toString() === editWidgetId.toString()) {
        item.widgetTypeId = singleWidgetTypeId;
        item.isExpanded = singleWidgetIsExpanded;
        item.isLineChart = singleWidgetIsLineChart;
        item.showXLabels = showXLabels;
        item.showYLabels = showYLabels;
        item.lastPeriodRange = singleWidgetLastPeriodRange;
        item.periodType = singleWidgetPeriodType;
        item.widgetType =
          widgetTypes &&
          widgetTypes.find(
            (x) => x.id.toString() === singleWidgetTypeId.toString()
          );
      }
    });

    setAllWidgets(newWidgets);
  };

  const saveDisabled = () => {
    return (
      singleWidgetPeriodType == null ||
      singleWidgetLastPeriodRange < 1 ||
      singleWidgetPeriodType === "" ||
      singleWidgetTypeId === ""
    );
  };

  const getWidgetTypesForAdd = () => {
    if (!widgetTypes) {
      return [];
    }

    let uniqTypes = widgetTypes.filter((x) => {
      if (
        allWidgets.find((w) => w.widgetTypeId.toString() === x.id.toString())
      ) {
        return false;
      } else {
        return true;
      }
    });

    return uniqTypes == null
      ? []
      : uniqTypes.map((x) => {
        return { id: x.id, text: x.name };
      });
  };

  const getWidgetTypesForEdit = () => {
    if (!widgetTypes) {
      return [];
    }

    let uniqTypes = widgetTypes.filter((x) => {
      return !allWidgets.find(
        (w) =>
          w.id !== editWidgetId &&
          w.widgetTypeId.toString() === x.id.toString()
      );
    });

    return uniqTypes == null
      ? []
      : uniqTypes.map((x) => {
        return { id: x.id, text: x.name };
      });
  };

  return (
    <>
      {!editMode && (
        <EditMode
          getWidgetTypesForAdd={getWidgetTypesForAdd}
          singleWidgetTypeId={singleWidgetTypeId}
          setSingleWidgetTypeId={setSingleWidgetTypeId}
          singleWidgetIsExpanded={singleWidgetIsExpanded}
          setSingleWidgetIsExpanded={setSingleWidgetIsExpanded}
          singleWidgetLastPeriodRange={singleWidgetLastPeriodRange}
          setSingleWidgetLastPeriodRange={setSingleWidgetLastPeriodRange}
          singleWidgetPeriodType={singleWidgetPeriodType}
          setSingleWidgetPeriodType={setSingleWidgetPeriodType}
          singleWidgetIsLineChart={singleWidgetIsLineChart}
          setSingleWidgetIsLineChart={setSingleWidgetIsLineChart}
          showXLabels={showXLabels}
          setShowXLabels={setShowXLabels}
          showYLabels={showYLabels}
          setShowYLabels={setShowYLabels}
          clearSingleWidgetFields={clearSingleWidgetFields}
          saveDisabled={saveDisabled}
          saveSingleWidget={saveSingleWidget}

        />
      )}
      <div
        className="modal modal-metric fade"
        id="editMetric"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editMetric"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-dialog-scrollable modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-4 font-weight-700">Edit Metric</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-5 align-self-center">
                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                      Metric
                    </p>
                  </div>
                  <div className="col-sm-7 pl-sm-0">
                    <div className="section z-bigger-20">
                      <Select2
                        className="input-select-style no-search bigger-text"
                        data-placeholder="period"
                        data-allow-clear="1"
                        data={getWidgetTypesForEdit()}
                        defaultValue={singleWidgetTypeId}
                        options={{
                          placeholder: "period",
                          theme: "bootstrap4",
                          minimumResultsForSearch: -1,
                        }}
                        onChange={(event) =>
                          setSingleWidgetTypeId(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-5">
                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">Type</p>
                  </div>
                  <div className="col-sm-7 pl-sm-0 img-wrap">
                    <div className="htmlForm-group mb-2">
                      <input
                        type="radio"
                        id="type-1"
                        checked={!singleWidgetIsExpanded}
                        name="type-edit"
                        onChange={() => setSingleWidgetIsExpanded(false)}
                      />
                      <label className="radio radio-gray mb-0" htmlFor="type-1">
                        Show the current value
                      </label>
                    </div>
                    <img src={require("../img/type-1.svg")} alt="type-1" />
                    <div className="htmlForm-group mb-2 mt-2 pt-1">
                      <input
                        type="radio"
                        id="type-2"
                        name="type-edit"
                        checked={singleWidgetIsExpanded}
                        onChange={() => setSingleWidgetIsExpanded(true)}
                      />
                      <label className="radio radio-gray mb-0" htmlFor="type-2">
                        Show the current value + mini chart
                      </label>
                    </div>
                    <img src={require("../img/type-2.svg")} alt="type-2" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-5">
                    <p className="mb-2 mb-sm-0 size-14 font-weight-700 pt-fix-12">
                      Date range
                    </p>
                  </div>
                  <div className="col-sm-7 pl-sm-0">
                    <div className="row">
                      <div className="col-auto pr-2 align-self-center">
                        <p className="mb-0 size-14">Previous</p>
                      </div>
                      <div className="col-auto px-0">
                        <div className="form-group">
                          <input
                            type="number"
                            name="number-period"
                            id="number-period"
                            className="form-style number-box"
                            htmlFor="1"
                            min="1"
                            max="99"
                            value={singleWidgetLastPeriodRange}
                            onChange={(event) =>
                              setSingleWidgetLastPeriodRange(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col pl-1">
                        <div className="section z-bigger-20">
                          <Select2
                            className="input-select-style no-search bigger-text"
                            data-placeholder="period"
                            data-allow-clear="1"
                            data={[
                              { id: 1, text: "Days" },
                              { id: 2, text: "Weeks" },
                              { id: 3, text: "Months" },
                              { id: 4, text: "Quarters" },
                              { id: 5, text: "Years" },
                            ]}
                            options={{
                              placeholder: "period",
                              theme: "bootstrap4",
                              minimumResultsForSearch: -1,
                            }}
                            value={singleWidgetPeriodType || null}
                            onChange={(event) => {
                              setSingleWidgetPeriodType(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-5">
                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">
                      Chart type
                    </p>
                  </div>
                  <div className="col-sm-2 pl-sm-0">
                    <div className="htmlForm-group">
                      <input
                        type="radio"
                        id="charttype-1"
                        name="charttype-edit"
                        checked={singleWidgetIsLineChart}
                        onChange={() => setSingleWidgetIsLineChart(true)}
                      />
                      <label
                        className="radio radio-gray mb-0"
                        htmlFor="charttype-1"
                      >
                        Line
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 pl-sm-0">
                    <div className="htmlForm-group">
                      <input
                        type="radio"
                        id="charttype-2"
                        name="charttype-edit"
                        checked={!singleWidgetIsLineChart}
                        onChange={() => setSingleWidgetIsLineChart(false)}
                      />
                      <label
                        className="radio radio-gray mb-0"
                        htmlFor="charttype-2"
                      >
                        Bar
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-5">
                    <p className="mb-2 mb-sm-0 size-14 font-weight-700">Show Axis Labels</p>
                  </div>
                  <div className="col-sm-3 pl-sm-0">
                    <div className="htmlForm-group">
                      <input
                        type="checkbox"
                        id="chartxlabels"
                        name="chartlabels-edit-x"
                        checked={showXLabels}
                        onChange={() => setShowXLabels(!showXLabels)}
                      />
                      <label className="checkbox checkbox-gray mb-0" htmlFor="chartxlabels">
                        X-Axis
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-3 pl-sm-0">
                    <div className="htmlForm-group">
                      <input
                        type="checkbox"
                        id="chartylabels"
                        name="chartlabels-edit-y"
                        checked={showYLabels}
                        onChange={() => setShowYLabels(!showYLabels)}
                      />
                      <label className="checkbox checkbox-gray mb-0" htmlFor="chartylabels">
                        Y-Axis
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                      onClick={() => clearSingleWidgetFields()}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      disabled={saveDisabled()}
                      onClick={() => updateWidget()}
                      className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row pt-2 mt-1">
                <div className="col hide-on-edit">
                  <div className="row">
                    <div className="col-md-auto align-self-center pr-1">
                      <div className="filter-button" onClick={toggleFilter}>
                        <img
                          src={require("../img/filter.svg")}
                          className="mr-1"
                          alt="filter"
                        />
                        <span className="close-text">Close</span>
                        <span className="scnd-text">Filter</span>
                      </div>
                    </div>
                    <div className="col-md align-self-center">
                      <div className="filter-options section">
                        <div id="filter-element" className="row">
                          <FacilitiesPicker
                            onChange={facilitiesChanged}
                          />
                          {PrivilegeChecker.hasPrivilege(
                            Privileges.ViewAfterHoursTrendLine
                          ) &&
                            (
                              <>
                                <div className="col-auto align-self-center pr-1">
                                  <div className="htmlForm-group">
                                    <input
                                      type="checkbox"
                                      id="afterh"
                                      checked={withAfterHours}
                                      onChange={() =>
                                        setWithAfterHours(!withAfterHours)
                                      }
                                    />
                                    <label
                                      className="checkbox mb-0"
                                      htmlFor="afterh"
                                    >
                                      After Hours
                                    </label>
                                  </div>
                                </div>
                                {withAfterHours && (
                                  <>
                                    <div className="col-auto align-self-center pr-1">
                                      <div className="htmlForm-group">
                                        <input
                                          type="checkbox"
                                          id="afterhdiff"
                                          checked={withAfterHoursDiff}
                                          onChange={() =>
                                            setWithAfterHoursDiff(!withAfterHoursDiff)
                                          }
                                        />
                                        <label
                                          className="checkbox mb-0"
                                          htmlFor="afterhdiff"
                                        >
                                          Show Diff
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col-auto align-self-center pr-1">
                                  <div className="htmlForm-group">
                                    <input
                                      type="checkbox"
                                      id="skippartial"
                                      checked={showPEPeriod}
                                      onChange={() => {
                                        setshowPEPeriod(!showPEPeriod);
                                        // Reset the "Show Diff" checkbox when "Show Partial End Period" is changed.
                                        setWithAfterHoursDiff(false);
                                      }}
                                    />
                                    <label
                                      className="checkbox mb-0"
                                      htmlFor="skippartial"
                                    >
                                      Include Partial End Period
                                    </label>
                                  </div>
                                </div>
                                <div className="col-auto align-self-center pr-1">
                                  <div className="htmlForm-group">
                                    <input
                                      type="checkbox"
                                      id="skipseries"
                                      checked={showAdSeries}
                                      onChange={() => {
                                        setShowAdSeries(!showAdSeries);                                      
                                      }}
                                    />
                                    <label
                                      className="checkbox mb-0"
                                      htmlFor="skipseries"
                                    >
                                      Show Series
                                    </label>
                                  </div>
                                </div>
                                <div className="col-auto align-self-center pr-1">
                                  <div className="htmlForm-group">
                                    <input
                                      type="checkbox"
                                      id="trendl"
                                      checked={withTrendLine}
                                      onChange={() =>
                                        setWithTrendLine(!withTrendLine)
                                      }
                                    />
                                    <label
                                      className="checkbox mb-0"
                                      htmlFor="trendl"
                                    >
                                      Trend Line
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <EditButton saveWidgetsPositions={saveWidgetsPositions} editModeClick={editModeClick} />
              </div>
              {groupIds && (
                <ChartsArea
                  widgetTypes={widgetTypes}
                  allWidgets={allWidgets}
                  editMode={editMode}
                  selectForEdit={selectForEdit}
                  withTrendLine={withTrendLine}
                  withAfterHours={withAfterHours}
                  withAfterHoursDiff={withAfterHoursDiff}
                  showPEPeriod={showPEPeriod}
                  showAdSeries={showAdSeries}
                  groupIds={groupIds}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
