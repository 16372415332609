import React from "react";

export default function EditButton({...props}) {
    return (
        <div className="col-auto align-self-md-center">
            <div className="edit-buttons-wrap">
                <button
                    type="button"
                    className="edit-chart-button-done btn btn-blue btn-width-80 btn-height-30 border-2 font-size-13 ml-1"
                    onClick={props.saveWidgetsPositions}
                >
                    SAVE
                </button>
            </div>
            <div id="settings-dashboard" className="dropdown navbar hide-on-edit">
                <button
                    className="customize-button"
                    type="button"
                    id="dropdownCustomize"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle-second="tooltip"
                    data-offset="0 5"
                    data-placement="left"
                    title="Customize your dashboard"
                >
                    <img
                        src={require("../../../../img/customize-dashboard.svg")}
                        alt="customize-dashboard"
                    />
                </button>
                <div
                    className="dropdown-menu dropdown-customize dropdown-menu-right"
                    aria-labelledby="dropdownCustomize"
                >
                    <span
                        onClick={props.editModeClick}
                        className="cursor-pointer edit-chart-button link-dark font-size-14 line-height-14 mb-2 font-weight-500"
                    >
                        Edit Mode
                    </span>
                    <div className="clearfix" />
                    <span
                        data-toggle="modal"
                        data-target="#addMetric"
                        className="cursor-pointer link-dark font-size-14 line-height-14 font-weight-500"
                    >
                        Add Metric
                    </span>
                </div>
            </div>
        </div>
    );
}
