import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin, getCurrentCompanyId } from "../authentification";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SettingsPageNavBar from "./components/settingsPageNavBar";
import ErrorModal from './components/errorModal';

const KontakDni = (props) => {
  const [newDniUrlName, setNewDniName] = useState("");
  const [newDniUrl, setNewDniUrl] = useState("");
  const [isKontakAdminHere] = useState(isKontakAdmin());
  const [currentCompanyId] = useState(getCurrentCompanyId());

  const [isLinkVisible, setIsLinkVisible] = useState(false);

  const clearFields = () => {
    setNewDniName("");
    setNewDniUrl("");
  }

  const [errorMessage, setErrorMessage] = useState(null);

  const clearError = () => {
    setErrorMessage("");
  };

  const createDniUrl = () => {
    clearError();
    const createGroupJson = {
      companyid: currentCompanyId,
      name: newDniUrlName,
      url: newDniUrl,

    };

    ajaxCaller("DniUrl/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "post",
      body: JSON.stringify(createGroupJson),
    }).then((response) => {
      if (!response.isError) {
        clearFields();
        //setDniUrls((prev) => [...prev].concat(response));
        window.location = "/settings/kontakDni";
      } else {
        console.log(response);
        setErrorMessage(response.message);
      }
    });
  };

  const [dniurls, setDniUrls] = useState(null);

  const getDniUrls = () => {
    clearError();
    ajaxCaller("DniUrl/", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        if (response && response.length >= 1) {
          setIsLinkVisible(true);
          setDniUrls(response);
          setEnvScriptLinkByUrl(getEnvScriptLinkByUrl);
        }
        else {
          setIsLinkVisible(false);
          setDniUrls([]);
        }
      } else {
        console.log(response);
        setErrorMessage(response.message);
      }
    });
  };

  const getDniUrlsCallback = () => {
    if (dniurls == null) {
      getDniUrls();
      getCompany();
    }
  }

  useEffect(getDniUrlsCallback, []);

  const removeDniUrl = (id) => {
    clearError();
    ajaxCaller("DniUrl/" + id, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "delete",
    }).then((response) => {
      if (!response.isError) {
        getDniUrls();
      } else {
        setErrorMessage(response.message);
      }
    });
  }

  const [companyName, setCompanyName] = useState("");
  // const [companyLogo, setCompanyLogo] = useState(getCurrentCompanyLogo());
  // const [companyId, setCompanyId] = useState("");

  const getCompany = () => {
    clearError();
    ajaxCaller("Company/GetCurrentUserCompany/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setCompanyName(response.name);
        // setCompanyLogo(response.logo);
        // setCompanyId(response.id);
      } else {
        setErrorMessage(response.message);
      }
    });
  };

  const [scriptLinkByUrl, setEnvScriptLinkByUrl] = useState("");
  const getEnvScriptLinkByUrl=() => {
    var location= window.location.href;
    if (location.includes('kontakuidev') ||location.includes('dev.getkontak') || location.includes('dev.') || location.includes('localhost') ){
      return '<script type="text/javascript" charset="utf-8" src="https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni_dev.js"></script>'
    }
    else if (location.includes('stage.')){
      return '<script type="text/javascript" charset="utf-8" src="https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni_stage.js"></script>'
    }
    else if (location.includes('stage2.') || location.includes('uat.') ){
      return '<script type="text/javascript" charset="utf-8" src="https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni_uat.js"></script>'
    }
    else { 
      return '<script type="text/javascript" charset="utf-8" src="https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni.js"></script>'
    }
  }

  return (
    <>
      <SettingsPageNavBar pageName="kontakDni" />
      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">DNI - {companyName}</h5>
                </div>
              </div>
              {
                isKontakAdminHere && (<div className="row">
                  <div className="col-12">
                    <div className="section background-white p-3 p-lg-4">
                      <div className="row">
                        <div className="col-md-auto align-self-center">
                          <p className="mb-2 mb-md-0 font-weight-500">
                            Add a new website
                          </p>
                        </div>
                        <div className="col-md-4 col-xl-4 mb-3 mb-md-0 align-self-center pr-md-0">
                          <div className="form-group">
                            <input
                              type="text"
                              value={newDniUrlName}
                              onChange={(event) =>
                                setNewDniName(event.target.value)
                              }
                              className="form-style border-0 small-40"
                              placeholder="Web Site Name..."
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-5 mb-3 mb-md-0 align-self-center pr-md-0">
                          <div className="form-group">
                            <input
                              type="text"
                              value={newDniUrl}
                              onChange={(event) =>
                                setNewDniUrl(event.target.value)
                              }
                              className="form-style border-0 small-40"
                              placeholder="Web Site Url..."
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md-auto align-self-center pl-md-2">
                          <button
                            onClick={() => createDniUrl()}
                            disabled={newDniUrl === "" || newDniUrlName === "" || currentCompanyId === null}
                            className="btn btn-blue btn-width-80 btn-height-40 border-1 font-size-16"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)
              }
              <div className="row mt-2 pt-1">
                <div className="col-12 chat-archive-table">
                  <div className="section background-white">
                    <div className="row py-2">
                      <div className="col align-self-center">
                        <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                          Websites
                        </p>
                      </div>        
                      <div className="col-auto">
                      {isLinkVisible && (
                        <span
                          className="link-download font-size-13 mr-2 pr-1"
                        >
                            {/* <a href={"https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni_dev.js"} 
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  data-trigger="hover"
                                  title="Download the DNI script" 
                            target="_blank" 
                            rel="noopener noreferrer">
                            <code>{'<script type="text/javascript" charset="utf-8" src="https://kontakstorage.blob.core.windows.net/dni-script/kontak_dni_dev.js"></script>'}</code>
                            </a>  */}
                            <code>{scriptLinkByUrl}</code>
                            
                          
                          <CopyToClipboard text={scriptLinkByUrl}>
                            <button
                              type="submit"
                              style={{ cursor: "pointer", marginRight: "5px" }}
                              className="btn btn-regions "
                              data-toggle="tooltip"
                              data-placement="top"
                              data-trigger="hover"
                              title="Copy the link to embed the DNI Script"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width="18px"
                                height="18px"
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                              </svg>
                            </button>
                          </CopyToClipboard>
                        </span>
                           )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className={isKontakAdminHere ? "col-lg-4 pr-0" : "col-lg-4 pr-0"}>
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Website Name
                          </p>
                        </div>
                      </div>
                      {isKontakAdminHere && (<div className="col-xl-6 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            URL
                          </p>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>
                <div id="container" className="col-12 profile-category-wrap-move">
                  {dniurls &&
                    dniurls.map((dniurl) => (
                      <div key={dniurl.id}>
                        <div
                          className="modal fade"
                          id={"modalDelete_" + dniurl.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalDelete"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div className="col-12 text-center">
                                      <h5 className="mb-2 pb-1">Delete DNI</h5>
                                    </div>
                                    <div className="col-12 text-center">
                                      <p className="mb-0">
                                        You are about to delete a DNI:{" "}
                                        <strong>{dniurl.name}</strong>
                                      </p>
                                      <p className="mb-0">
                                        Are you sure you wish to continue?
                                      </p>
                                    </div>
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-1 font-size-14 mr-1"
                                      >
                                        CANCEL
                                      </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => removeDniUrl(dniurl.id)}
                                        className="btn btn-red btn-width-130 btn-height-40 border-1 font-size-14 ml-1"
                                      >
                                        DELETE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={"dniurl_" + dniurl.id}
                          style={{ cursor: "auto" }}
                          className="section profile-category-wrap"
                        >
                          <div className="row">
                            <div className={isKontakAdminHere ? "col-lg-4 pr-xl-0" : "col-lg-4 pr-xl-0"}>
                              <div className="profile-box-white profile-box-white-bor-right" >
                                <p className="mb-0 font-size-15">{dniurl.name}</p>
                              </div>
                            </div>
                            {isKontakAdminHere && (<div className="col-xl-6 pr-xl-0">
                              <div style={{ overflowWrap: "anywhere" }} className="profile-box-white profile-box-white-bor-right">
                                <CopyToClipboard
                                  text={
                                    dniurl.url
                                  }
                                >
                                  <button
                                    type="submit"
                                    style={{ cursor: "pointer", marginRight: "5px" }}
                                    className="btn btn-regions "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-trigger="hover"
                                    title="Copy"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      width="18px"
                                      height="18px"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                    </svg>
                                  </button>
                                </CopyToClipboard>
                                <p className="mb-0 font-size-15">{dniurl.url}</p>
                              </div>
                            </div>)}
                            <div className="col-2 pl-xl-0">
                              <div className="profile-box-white profile-box-white-bor-right justify-content-xl-end">
                                <a href={`/settings/kontakDni/${dniurl.id}`} className="btn btn-blue btn-width-80 btn-height-36 border-1 font-size-14 mr-1 mt-2 mt-xl-0 animsition-link">Edit</a>
                                {isKontakAdminHere && (
                                  <button type="button"
                                    data-toggle="modal"
                                    data-target={"#modalDelete_" + dniurl.id}
                                    className="btn btn-red btn-box-36 border-1 mt-2 mt-xl-0">
                                    <img src={require("../img/bin.svg")} alt="" />
                                  </button>)}
                                <ErrorModal errorMessage={errorMessage} clearError={clearError} />
                                <div className="modal fade"
                                  id="modalError"
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="modalError"
                                  aria-hidden="true">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KontakDni;
