import React, { useState, useEffect } from "react";
import { ajaxCaller, ajaxNonJsonCaller, authHeaders } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../authentification";
import $ from "jquery";
import { Steps } from 'intro.js-react';
import { useGlobal } from "./components/globalIntroState";
import Autocomplete from "react-autocomplete";

const Profile = (props) => {
  const [categoryNameToDelete, setCategoryNameToDelete] = useState(null);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [groups, setGroups] = useState(null);
  const [groupId, setGroupId] = useState(props.match.params.groupId);
  const [isKontakAdminHere] = useState(isKontakAdmin());
  const [searchFacilityText, setSearchFacilityText] = useState('');

  const getGroups = () => {
    ajaxCaller("Group/GetGroups?Simple=true", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((groups) => {
      if (!groups.isError) {
        setGroups(groups);
        if(groupId == null) {
          if (groups != null && groups.length > 0) {
            setGroupId(groups[0].id);
            configureSearch(groups[0].id);
          }
        }
        else {
          configureSearch(groupId, groups);
        }        
      }
    });
  };

  const configureSearch = (groupId, groups) => {
    console.log(groups);
    if (groups != null && (searchFacilityText === '' || searchFacilityText == null)){
      const selectedGroup = groups.filter((group) => group.id.toString() === groupId.toString());
      if (selectedGroup != null && selectedGroup.length >= 1) {
        setSearchFacilityText(selectedGroup[0].name);
      }  
    } 
  }

  // function onGroupSelected(event) {
  //   const groupId = $(event.target).val();
  //   setGroupId(groupId);
  // }

  function onGroupSelected(selectedGroupName) {
    const searchedGroup = groups.filter((group) => group.name === selectedGroupName);
    if (searchedGroup.length >= 1) {
      const group = searchedGroup[0];
      setSearchFacilityText(selectedGroupName);
      setGroupId(group.id);
    }
  }

  const categoriesHookCallback = () => {
    if (groupId != null) {
      getCategories(groupId);
    }
  };

  useEffect(categoriesHookCallback, [groupId]);

  useEffect(() => {
    if (groups == null) {
      getGroups();
    }
  });

  const [categories, setCategories] = useState(null);
  const [ccLeadsEmails, setCCLeadsEmails] = useState([]);
  const [sendLeadsEmails, setSendLeadsEmails] = useState([]);
  const getCategories = (groupId) => {
    if (groupId === -1) {
      setCategories(null);
    } else {
      ajaxCaller("QuestionCategory/Facility/" + groupId, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }).then((response) => {
        if (!response.isError) {

          if (groups == null) {
            return;
          }
          var group =
            groups &&
            groups.find((x) => x.id.toString() === groupId.toString());
          setCCLeadsEmails(
            group.ccLeadsTo == null || group.ccLeadsTo === ""
              ? []
              : group.ccLeadsTo.split(",")
          );
          setSendLeadsEmails(
            group.sendLeadsTo == null || group.sendLeadsTo === ""
              ? []
              : group.sendLeadsTo.split(",")
          );
          setCategories(response.categories);
          setFirstCategoryId(response.categories[0].id)
        }
      });
    }
  };


  const changeIsHighlighted = (id) => {
    ajaxCaller("QuestionCategory/ChangeIsHighlighted/" + id, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setCategories(
          categories.map((category) => {
            if (category.id === id) {
              category.id = id;
              category.isHighlighted = !category.isHighlighted;
            }
            return category;
          })
        );
      }
    });
  };

  const changeVisibleInCICReport = (id) => {
    ajaxCaller("QuestionCategory/ChangeVisibilityForReport/" + id, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setCategories(
          categories.filter((category) => {
            if (category.id === id) {
              category.id = id;
              category.displayInCICReport = !category.displayInCICReport;
            }
            return category;
          })
        );
      }
    });
  };

  const [categoryName, setCategoryName] = useState("");
  const addCategory = () => {
    ajaxCaller("QuestionCategory/SubmitCategory", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        name: categoryName,
        groupId: groupId,
      }),
    }).then((response) => {
      //TODO need add error handling
      if (!response.isError) {
        setCategories(categories.concat(response));
        setCategoryName("");
      }
    });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };


  const getCategoriesCallback = () => {
    if (props.match.params.groupId != null) {
      getCategories(props.match.params.groupId);
    }
  }
  useEffect(getCategoriesCallback, [groups])

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  const removeCategory = () => {
    ajaxCaller("QuestionCategory/" + categoryIdToDelete, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setCategories(categories.filter((a) => a.id !== categoryIdToDelete));
      } else {
        //TODO error handling
      }
    });
  };

  const addSendLeadsEmail = (event) => {
    if (
      event.target.value === "" ||
      event.target.value.length < 2 ||
      !validateEmail(event.target.value)
    ) {
      return;
    }
    var key = event.which;
    if (key === 13 || key === 14) {
      var newLeadsEmails = [...sendLeadsEmails];
      newLeadsEmails.push(event.target.value);
      setSendLeadsEmails(newLeadsEmails);
      saveEmailsChanges(newLeadsEmails, ccLeadsEmails);
      event.target.value = "";
    }
  };

  const addCCLeadsEmail = (event) => {
    if (
      event.target.value === "" ||
      event.target.value.length < 2 ||
      !validateEmail(event.target.value)
    ) {
      return;
    }
    var key = event.which;
    if (key === 13 || key === 14) {
      var newLeadsEmails = [...ccLeadsEmails];
      newLeadsEmails.push(event.target.value);

      setCCLeadsEmails(newLeadsEmails);
      saveEmailsChanges(sendLeadsEmails, newLeadsEmails);
      event.target.value = "";
    }
  };

  const removeCCLeadsEmail = (index) => {
    var newLeadsEmails = [...ccLeadsEmails];
    newLeadsEmails.splice(index, 1);
    setCCLeadsEmails(newLeadsEmails);
    saveEmailsChanges(sendLeadsEmails, newLeadsEmails);
  };

  const removeSendLeadsEmail = (index) => {
    var newLeadsEmails = [...sendLeadsEmails];
    newLeadsEmails.splice(index, 1);
    setSendLeadsEmails(newLeadsEmails);
    saveEmailsChanges(newLeadsEmails, ccLeadsEmails);
  };

  const saveEmailsChanges = (leadsEmails, ccEmails) => {
    var ccLeadsLine = "";
    var sendLeadLine = "";

    ccEmails.forEach((email) => {
      if (email.length > 1) {
        ccLeadsLine = email + "," + ccLeadsLine;
      }
    });

    leadsEmails.forEach((email) => {
      if (email.length > 1) {
        sendLeadLine = email + "," + sendLeadLine;
      }
    });

    ccLeadsLine = ccLeadsLine.slice(0, -1);
    sendLeadLine = sendLeadLine.slice(0, -1);

    ajaxCaller("Group/UpdateLeadsTo/" + groupId, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        ccLeadsTo: ccLeadsLine,
        sendLeadsTo: sendLeadLine,
      }),
    }).then((group) => {
      if (!group.isError) {
        setCCLeadsEmails(
          group.ccLeadsTo == null || group.ccLeadsTo === ""
            ? []
            : group.ccLeadsTo.split(",")
        );
        setSendLeadsEmails(
          group.sendLeadsTo == null || group.sendLeadsTo === ""
            ? []
            : group.sendLeadsTo.split(",")
        );
      }
    });
  };

  const [editedCategoryName, setEditedCategoryName] = useState("");
  const updateCategoryName = (id) => {
    ajaxCaller("QuestionCategory/" + id, {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        name: editedCategoryName,
      }),
    }).then((response) => {
      if (!response.isError) {
        getCategories(groupId);
      }
    });
  };

  const dowloadQuestionCatergories = () => {
    ajaxNonJsonCaller("QuestionCategory/Export/" + groupId, {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      if (response.ok) {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Profile Export for " +groups.find(x => x.id.toString() === groupId.toString()).name+".pdf";
          a.click();
        });
      }
    });
  };

  const [firstCategoryId, setFirstCategoryId] = useState(null);
  const steps = [
    {
      element: '.q',
      intro: '<p style="text-align:center; font-weight: bold;">Profile Section </p><p style="text-align:center"> This section contains information about your business our agents rely on to successfully handle your visitors’ questions and needs. The information stored here has been automatically transferred from the intake form you completed during the onboarding process. To ensure our agents can do the best job, <span style="font-weight: bold;"> please keep this info regularly updated.</span> Thanks. Let’s start! </p>',
      position: 'right',
      tooltipClass: 'intro-profile-main',  // can be used for changing styles
      //highlightClass: 'myHighlightClass', // can be used for changing styles when it is highlighted
    },
    {
      element: '.select2-container',
      intro: 'If your business has multiple locations, you are able to switch between them using the drop-down (If you don’t have multiple locations, you will see your business name displayed here).',
      position: 'right',
      //tooltipClass: 'myTooltipClass',  // can be used for changing styles
      //highlightClass: 'myHighlightClass', // can be used for changing styles when it is highlighted
    },
    {
      element: '#profile-appointment-request',
      intro: 'Set up emails for the recipients of the appointment requests and other necessary information generated by our agents. *Note, if you have multiple locations, these are set up for each location individually. ',
      position: 'right',
      //tooltipClass: 'myTooltipClass',  // can be used for changing styles
      //highlightClass: 'myHighlightClass', // can be used for changing styles when it is highlighted
    },
    {
      element: '#profile-categories',
      intro: 'Bellow you will see a list of categories, each containing questions and answers about your business. You can access them by clicking “Open Category“, or use the “All Questions“ option to search via keywords across all categories. ',
      position: 'right'
    },
    {
      element: '#category_' + firstCategoryId,
      intro: 'Please know that some Q&As (i.e. services, pricing) may fall into multiple categories. When updating info regarding a specific service, the safest way is to use keyword search functionality. ',
      position: 'right',
      tooltipClass: 'intro-profile-categories',  // can be used for changing styles
      highlightClass: 'intro-profile-categories-hightlight', // can be used for changing styles when it is highlighted
    }
  ];

  const [globalState] = useGlobal();
  const [userIntroEnabled, setUserIntroEnabled] = useState(false); // note enable steps ONLY if they are not finished\
  const [currentIntroStep, setCurrentIntroStep] = useState(null);
  const [introId, setIntroId] = useState(null); 

  const changeCurrentStep = (step) => {
    ajaxCaller("UserIntro/UpdateStep/", {
      method: "put",
      headers: authHeaders,
      body: JSON.stringify({
        id: introId,
        currentStep: step
       }),
    }).then((response) => {
      if (response.isError) {
      } else {
      }
    });
  }

  const finishUserIntro = () => {
    ajaxCaller("UserIntro/FinishIntro/" + introId, {
      method: "put",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(false);
      }
    });
  }
  
  const getUserIntro = () => {
    ajaxCaller("UserIntro/UserIntro/2", {
      method: "get",
      headers: authHeaders,
    }).then((response) => {
      if (response.isError) {
      } else {
        setUserIntroEnabled(!response.finished);
        setCurrentIntroStep(response.currentStep);
        setIntroId(response.id);
      }
    });
  }

  useEffect(() => {
    if(!isKontakAdmin())
    {
      getUserIntro();
    }
  }, [globalState.counter])

  const onExitStep = () => {
     finishUserIntro();
 };

 const onChangeStep = (step) => {
  if(step != null)
  {
    changeCurrentStep(step)
  }
 };

  return (
    <>
    {groupId && userIntroEnabled && firstCategoryId && (currentIntroStep != null) && (<Steps
        enabled={userIntroEnabled}
        steps={steps}
        initialStep={currentIntroStep}
        onExit={onExitStep}
        onChange={onChangeStep}
      />)}
    <div  className="section sec-move-left">
      <div
        className="modal fade"
        id="modalDelete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalDelete"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    <h5 className="mb-2 pb-1">Delete category</h5>
                  </div>
                  <div className="col-12 text-center">
                    <p className="mb-0">
                      You are about to delete the category:
                      <br />
                      <strong>{categoryNameToDelete}</strong>
                    </p>
                    <p className="mb-0">Are you sure you wish to continue?</p>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      aria-label="Close"
                      data-dismiss="modal"
                      className="btn btn-red btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                      onClick={() => removeCategory()}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4 mb-2 pb-1">
              <div className="col-auto align-self-center">
                <h5 className="mb-2 mb-sm-0">Client Profile</h5>
              </div>
              <div  className="col-sm-auto">
                <div  className="section z-bigger-20 height-40-style select-white min-width-450">
                {
                  groups && (
                    <Autocomplete 
                      width={400}
                      getItemValue={(item) => item.name}
                      items={groups.filter((group) => group.name.includes(searchFacilityText))}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white', marginTop: "10px", marginLeft: "5px", cursor: "default" }}>
                          {item.name}
                        </div>                        
                      }
                      value={searchFacilityText}
                      onChange={(e) => setSearchFacilityText(e.target.value)}
                      onSelect={(val) => onGroupSelected(val)}
                      inputProps={{onClick: () => setSearchFacilityText(''), style: { width: '1000px' }}}
                    />
                  )
                }
                </div>
              </div>
            </div>
          </div>
          {groupId && (
            <div className="container-fluid z-bigger-10 ">
              <div className="row">
                <div className="col-12">
                  <div id="profile-appointment-request" className="section background-white p-3 p-lg-4">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-3">Appointment request</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 col-xl-1">
                        <p className="mb-2 mb-md-0 font-weight-500">Send to:</p>
                      </div>
                      <div className="col-md align-self-center">
                        <div className="section z-bigger-10">
                          <div htmlFor="tag-typer" className="for-tags-typer">
                            <div className="tags-section">
                              {sendLeadsEmails &&
                                sendLeadsEmails.map((email, index) => (
                                  <span className="tag-button">
                                    <span
                                      className="close-button"
                                      onClick={() =>
                                        removeSendLeadsEmail(index)
                                      }
                                    >
                                      &times;
                                    </span>
                                    {email}
                                  </span>
                                ))}
                              <span>
                                <input
                                  className="form-style p-0 tag-typer"
                                  id="tag-typer"
                                  type="text"
                                  onKeyPress={(event) =>
                                    addSendLeadsEmail(event)
                                  }
                                  placeholder="Type an email address and press ENTER..."
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-md-2 col-xl-1">
                        <p className="mb-2 mb-md-0 font-weight-500">CC to:</p>
                      </div>
                      <div className="col-md align-self-center">
                        <div className="section z-bigger-10">
                          <label
                            htmlFor="tag-typer-2"
                            className="for-tags-typer"
                          >
                            <div className="tags-section">
                              {ccLeadsEmails &&
                                ccLeadsEmails.map((email, index) => (
                                  <span className="tag-button">
                                    <span
                                      className="close-button"
                                      onClick={() => removeCCLeadsEmail(index)}
                                    >
                                      &times;
                                    </span>
                                    {email}{" "}
                                  </span>
                                ))}
                              <input
                                className="form-style p-0 tag-typer"
                                id="tag-typer-2"
                                type="text"
                                onKeyPress={(event) => addCCLeadsEmail(event)}
                                placeholder="Type an email address and press ENTER..."
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isKontakAdminHere && (
                <div className="row mt-2 pt-1">
                  <div className="col-12">
                    <div className="section background-white p-3 p-lg-4">
                      <div className="row">
                        <div className="col-md-auto align-self-center">
                          <p className="mb-2 mb-md-0 font-weight-500">
                            Add a category
                          </p>
                        </div>
                        <div className="col-md-5 col-lg-4 col-xl-3">
                          <div className="form-group">
                            <input
                              type="text"
                              id="categoryName"
                              className="form-style border-0 small-40 font-weight-400 font-italic"
                              value={categoryName}
                              onChange={(event) =>
                                setCategoryName($(event.target).val())
                              }
                              placeholder="Name your category..."
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-md mt-3 mt-md-0 pl-md-0">
                          <button
                            onClick={() => addCategory()}
                            disabled={
                              categoryName == null ||
                              categoryName === "" ||
                              categoryName === " "
                            }
                            className="btn btn-blue btn-width-80 btn-height-40 border-0 font-size-16"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div id="profile-categories" className="row mt-2 pt-1">
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div
                        className={
                          isKontakAdminHere ? "col-lg-6 pr-0" : "col-lg-10 pr-0"
                        }
                      >
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Category
                          </p>
                          <a
                            href={"/profile/" + groupId + "/all"}
                            className="ml-4 font-size-14 font-weight-700 animsition-link"
                          >
                            All Questions
                            </a>
                            {isKontakAdminHere && (<h5
              className="accordion-link download sub-menu mb-0"
              onClick={() => dowloadQuestionCatergories()}
            >
              &#0;
                </h5>)}
                        </div>
                        
                      </div>
                      {isKontakAdminHere && (
                        <div className="col-lg-2 px-0">
                          <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                            <p className="mb-0 font-size-14 font-weight-500">
                              Agent Alert
                            </p>
                          </div>
                        </div>
                      )}
                      {isKontakAdminHere && (
                        <div className="col-lg-2 px-0">
                          <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                            <p className="mb-0 font-size-14 font-weight-500">
                              Include in the CIC report
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  id="container"
                  className="col-12 profile-category-wrap-move"
                >

                  {categories &&
                    categories.map((category) => (
                      <div key={"category_" + category.id} >
                        <div
                          className="modal modal-metric bigger fade"
                          id={"modalEdit_" + category.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="modalEdit"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="container-fluid">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                  <div className="row">
                                    <div className="col-12">
                                      <h5 className="mb-4 font-weight-700">
                                        Edit Category
                                    </h5>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-3 align-self-center">
                                      <p className="mb-2 mb-sm-0 font-weight-500">
                                        Name:
                                    </p>
                                    </div>
                                    <div className="col-sm-9">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-style border-0 small-40"
                                          placeholder="Add a question..."
                                          value={editedCategoryName}
                                          onChange={(event) => {
                                            setEditedCategoryName(
                                              event.target.value
                                            );
                                          }}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 text-center mt-4">
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1"
                                      >
                                        CANCEL
                                    </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1"
                                        onClick={() =>
                                          updateCategoryName(category.id)
                                        }
                                      >
                                        UPDATE
                                    </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={"widgetCategory_" + category.id}
                          style={{cursor:"auto"}}
                          className="section profile-category-wrap"
                        >
                          <div className="row">
                            <div
                              className={
                                isKontakAdminHere
                                  ? "col-xl-6 pr-xl-0"
                                  : "col-xl-10 pr-xl-0"
                              }
                            >
                              <div id={"category_" + category.id} style={{cursor: "auto"}} className="profile-box-white profile-box-white-bor-right justify-content-between">
                                <p  className="mb-0">{category.name}</p>
                                {isKontakAdminHere && (
                                  <button
                                    className="btn btn-blue btn-box-36 border-0"
                                    data-offset="0 5"
                                    data-placement="top"
                                    data-toggle="modal"
                                    data-target={"#modalEdit_" + category.id}
                                    onClick={() => setEditedCategoryName(category.name)}
                                    title="Change the category name"
                                  >
                                    <img
                                      src={require("../img/edit.svg")}
                                      alt=""
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                            {isKontakAdminHere && (
                              <div className="col-xl-2 px-xl-0" >
                                <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      id={"highlight-" + category.id}
                                      checked={category.isHighlighted}
                                      onChange={() =>
                                        changeIsHighlighted(category.id)
                                      }
                                    />
                                    <label
                                      className="checkbox left-35 mb-0"
                                      htmlFor={"highlight-" + category.id}
                                    >
                                      Highlight
                                  </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            {isKontakAdminHere && (
                              <div className="col-xl-2 px-xl-0">
                                <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      id={"include-" + category.id}
                                      checked={category.displayInCICReport}
                                      onChange={() =>
                                        changeVisibleInCICReport(category.id)
                                      }
                                    />
                                    <label
                                      className="checkbox left-35 mb-0"
                                      htmlFor={"include-" + category.id}
                                    >
                                      Include{" "}
                                      <span className="d-xl-none">
                                        in the CIC report
                                    </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-xl-2 pl-xl-0">
                              <div className="profile-box-white justify-content-xl-center">
                                <a
                                  href={"/profile/" + groupId + "/" + category.id}
                                  className="btn btn-blue btn-width-130 btn-height-36 border-0 font-size-14 mr-2 mt-2 mt-xl-0 animsition-link"
                                >
                                  Open Category
                              </a>
                                {isKontakAdminHere && (
                                  <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modalDelete"
                                    className="btn btn-red btn-box-36 border-0 mt-2 mt-xl-0"
                                    onClick={() => {
                                      setCategoryIdToDelete(category.id);
                                      setCategoryNameToDelete(category.name);
                                    }}
                                  >
                                    <img src={require("../img/bin.svg")} alt="" />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </>
  );
};

export default Profile;
