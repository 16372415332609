import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import {
  getAccessToken,
  getCurrentUserAvatar,
  getCurrentUserName,
  getCurrentUserEmail,
  getCurrentUserId,
  getCurrentUserRoleId,
} from "../authentification";
import Cookies from "js-cookie";
import $ from "jquery";

const UserProfile = (props) => {
  const [roleId] = useState(getCurrentUserRoleId());
  const [userName, setUserName] = useState(getCurrentUserName());
  const [userEmail, setUserEmail] = useState(getCurrentUserEmail());
  const [avatar, setAvatar] = useState(getCurrentUserAvatar());

  const [roles, setRoles] = useState(null);
  const getRoles = () => {
    ajaxCaller("Roles/GetRoles", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setRoles(response);
      }
    });
  };
  const getRole = (roleId) => {
    var role =
      roles && roles.find((x) => x.id.toString() === roleId.toString());

    if (role) {
      return role.name;
    }
  };
  useEffect(() => {
    getRoles();
  }, []);

  const getUser = () => {
    ajaxCaller("User/GetUser", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      if (!response.isError) {
        setAvatar(response.avatar);
      }
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  const uploadAvatar = (file) => {
    if (!file || file.size > 1000000) {
      return;
    }

    var data = new FormData();
    data.append("file", file);
    ajaxCaller("User/UploadAvatar", {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
      }),
      body: data,
    }).then((response) => {
      if (!response.isError) {
        Cookies.set("user_avatar", response.fileName);
        document.getElementById("nav-bar-avatar").setAttribute("src", "https://kontakstorage.blob.core.windows.net/avatars/" + response.fileName);
        setAvatar(response.fileName);
      }
    });
  };

  const updateUser = () => {
    ajaxCaller("User/EditUser", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        id: getCurrentUserId(),
        name: userName,
        email: userEmail,
        roleId: roleId,
      }),
    }).then((response) => {
      if (!response.isError) {
        getUser();
        document.getElementById("nav-bar-userName").textContent = response.name;
        Cookies.set("user_name", response.name);
        Cookies.set("user_email", response.email);
      }
    });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const extractPrivileges = (roleId) => {
    var role = roles && roles.find(x => x.id === roleId);
    return role && role.privileges;
  }

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle-second="tooltip"]').tooltip();
  });

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const changePassword = () => {
    ajaxCaller("User/ChangePassword", {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
    }).then((response) => {
      if (!response.isError) {
        setErrorMessage(null);
        setNewPassword(null);
        setNewPasswordConfirm(null);
        setOldPassword(null);
        $('#modalChangePass').modal('toggle');
      }
      else {
        setErrorMessage(response.message);
      }
    });
  };

  return (
    <div className="section sec-move-left">
      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4">
              <div className="col-12">
                <h5 className="mb-2 pb-1">User Profile</h5>
              </div>
              <div className="col-12">
                <div className="section background-white p-3 p-lg-4">
                  <div className="row">
                    <div className="col-md-2 col-xl-1 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">Image</p>
                    </div>
                    <div className="col-md">
                      <div className="section avatar-upload account-upload">
                        <div className="container-avatar">
                          <div className="imageWrapper">
                            <img
                              className="avatar-image"
                              src={
                                avatar === "" || avatar == null || avatar === " " || avatar === "null"
                                  ? ("https://kontakstorage.blob.core.windows.net/avatars/defaultLogo.svg")
                                  : ("https://kontakstorage.blob.core.windows.net/avatars/" + avatar)
                              }
                              alt="avatar"
                            />
                          </div>
                        </div>
                        <div className="file-upload align-self-center">
                          <input
                            onChange={(event) => {
                              uploadAvatar(event.target.files[0]);
                            }}
                            type="file"
                            className="file-input"
                          />
                          Choose File
                        </div>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-md offset-md-2 offset-xl-1 mt-1">
                      <p className="mb-0 font-size-13 line-height-18 opacity-70">
                        <i>
                          Ideal dimensions are 200px x 200px.
                          <br />
                          Maximum file size is 1mb.
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2 col-xl-1 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">Name</p>
                    </div>
                    <div className="col-md">
                      <div className="form-group max-260">
                        <input
                          type="text"
                          className="form-style border-0 small-40"
                          placeholder="Your Name..."
                          onChange={(event) => setUserName(event.target.value)}
                          value={userName}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2 col-xl-1 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">Email</p>
                    </div>
                    <div className="col-md">
                      <div className="form-group max-260">
                        <input
                          type="email"
                          className="form-style border-1 small-40"
                          placeholder="Your Email..."
                          onChange={(event) => setUserEmail(event.target.value)}
                          value={userEmail}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2 col-xl-1 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">Password</p>
                    </div>
                    <div className="col-md">
                      <p className="mb-0 font-size-14">
                        <span
                          style={{ color: "#19A3FC", cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#modalChangePass"
                        >
                          Change your password
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2 col-xl-1 align-self-center">
                      <p className="mb-2 mb-md-0 font-weight-500">Role</p>
                    </div>
                    <div className="col-md-auto">
                      <p className="mb-0 font-size-15">
                        {roleId > 1 ? getRole(roleId) : "Super Admin"}
                      </p>
                    </div>
                    <div className="col-xl-2 px-xl-0" style={{ marginTop: "4px" }}>
                      {roles && (<div
                        className="q-mark relative-pos"
                        data-toggle="tooltip"
                        data-offset="0 5"
                        data-placement="top"
                        title={extractPrivileges(roleId)}
                      >
                        ?
                      </div>)}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <button
                        onClick={() => updateUser()}
                        disabled={(!validateEmail(userEmail) || userName == null || userName === "" || userName === " ") || (getCurrentUserName() === userName && getCurrentUserEmail() === userEmail)}
                        className="btn btn-blue btn-width-130 btn-height-40 border-0 font-size-16"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modalChangePass" tabIndex="-1" role="dialog" aria-labelledby="modalChangePass" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="container-fluid">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                <div className="row justify-content-center">
                  {errorMessage && (<div className="alert alert-danger mt-4" role="alert">{errorMessage}</div>)}
                  <div className="col-12 text-center">
                    <h5 className="mb-4">
                      Change your password
									</h5>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input type="password" onChange={(event) => setOldPassword(event.target.value)} className="form-style small" placeholder="Current password..." autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 mt-2 pt-1">
                    <div className="form-group">
                      <input type="password" onChange={(event) => {
                        setNewPassword(event.target.value)
                      }} className="form-style small" placeholder="New password..." autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 mt-2 pt-1">
                    <div className="form-group">
                      <input type="password" onChange={(event) => {
                        setNewPasswordConfirm(event.target.value);
                      }} className="form-style small" placeholder="Conform new password..." autoComplete="off" />
                    </div>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <button type="button" data-dismiss="modal" onClick={() => {
                      setNewPassword(null);
                      setNewPasswordConfirm(null);
                      setOldPassword(null);
                    }} aria-label="Close" className="btn btn-grey btn-width-130 btn-height-40 border-2 font-size-14 mr-1">CANCEL</button>
                    <button type="button" onClick={() => changePassword()} disabled={newPassword !== newPasswordConfirm || newPassword === "" || newPassword === " "} className="btn btn-blue btn-width-130 btn-height-40 border-2 font-size-14 ml-1">UPDATE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
