import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken, isKontakAdmin } from "../authentification";
import RepeaterComponent from './components/RepeaterComponent';
import { PrivilegeChecker, Privileges } from "../privilegeChecker";
import ErrorModal from './components/errorModal';
import MessageModal from './components/MessageModal';

const EditDni = (props) => {

  const separatorStyle = {
    height: '2px',
    backgroundColor: 'lightgray',
  };

  const dniUrlId = props.match.params.dniUrlId;

  const [errorMessage, setErrorMessage] = useState(null);
  const clearError = () => {
    setErrorMessage("");
  };

  const [promptMessage, setPromptMessage] = useState(null);
  const clearMessage = () => {
    setPromptMessage("");
  };

  const updateDniUrl = () => {
    return new Promise((resolve, reject) => {
      clearError();
      const createJson = {
        name: dniUrlName,
        url: dniUrl,
      };

      ajaxCaller("DniUrl/" + dniUrlId, {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "put",
        body: JSON.stringify(createJson),
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {
          setUrlName(createJson.name);
          setUrl(createJson.url);
          resolve();
        }
      })
        .catch((error) => {
          console.error(error);
          setErrorMessage(error.message);
          reject(error);
        });
    });
  };

  const [items, setItems] = useState([]);
  const getDniReferrers = () => {
    return new Promise((resolve, reject) => {
      clearError();
      ajaxCaller("DniReferrer/Collection/" + props.match.params.dniUrlId, {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "get",
      }).then((response) => {
        if (response.isError) {
          setErrorMessage(response.message);
        } else {
          setErrorMessage(response.message);
          var dniItems = mapDniReferrerModel(items, response, "toUI")
          setItems(dniItems);
          resolve();

        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };


  const [referrerTypes, setRefferers] = useState([])
  const getDniReferrerTypes = () => {
    return new Promise((resolve, reject) => {
      clearError();
      ajaxCaller("DniReferrerType", {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "get",
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {
          const res = response.map(record => ({
            label: record.name,
            value: record.id
          }));
          setRefferers(res);
          resolve();

        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const [caches, setCaches] = useState([])
  const getDniCacheTypes = () => {
    return new Promise((resolve, reject) => {
      clearError();
      ajaxCaller("DniCacheType", {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "get",
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {

          const res = response.map(record => ({
            expirationdays: record.expirationDays,
            text: record.name,
            id: record.id
          }));
          setCaches(res);
          resolve();

        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const [formats, setFormats] = useState([])
  const getDniFormatTypes = () => {
    return new Promise((resolve, reject) => {
      clearError();
      ajaxCaller("DniFormatType", {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "get",
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {
          const res = response.map(record => ({
            format: record.format,
            text: record.name,
            id: record.id
          }));
          setFormats(res);
          resolve();

        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };




  const [dniUrlName, setUrlName] = useState('');
  const [dniUrl, setUrl] = useState('')
  const getDniUrl = () => {
    return new Promise((resolve, reject) => {
      clearError();
      ajaxCaller("DniUrl/" + dniUrlId, {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "get",
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {

          setUrlName(response.name);
          setUrl(response.url);
          resolve();
        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  async function getData() {
    try {
      await getDniReferrerTypes();
      await getDniFormatTypes();
      await getDniCacheTypes();
      await getDniReferrers();
      await getDniUrl();
      // The functions will be executed in order, and you can perform any additional actions here after all the functions complete.
    } catch (error) {
      console.error(error);
      // Handle any error that occurred during the execution
    }
  }

  const handleClearCache = (item) => {
    clearCache(item)
  }

  const getDniReferrersCallback = () => {
    getData()
      .then(() => {
        // Handle the completion of all the functions if needed
      })
      .catch((error) => {
        // Handle any error that occurred during the execution
        console.error(error);
      });
  };

  useEffect(getDniReferrersCallback, []);

  const mapDniReferrerModel = (items, models, direction) => {
    if (direction === 'toUI') {
      const mappedItems = models.map(model => ({
        id: model.id,
        dniUrlId: model.dniUrlId,
        dropdown_1: model.dniReferrerTypeId,
        inputMask_1: model.oldPhoneText,
        dropdown_phone_format_1: model.fromDniFormatId,
        inputMask_2: model.newPhoneText,
        dropdown_phone_format_2: model.toDniFormatId,
        explicit_replace: model.explicitReplace,
        url_param: model.urlCustomParameter,
        dropdown_cache: model.dniCacheTypeId,
        inputMask_2_flag: model.toDniFormatId === 1 ? model.newPhoneText : null,
        inputMask_1_flag: model.fromDniFormatId === 1 ? model.oldPhoneText : null,
        createdAt: model.createdAt,
        updatedAt: model.updatedAt

      }));

      return [...items, ...mappedItems];
    } else {

      const mappedModels = items.map(item => ({
        id: item.id,
        dniUrlId: item.dniUrlId,
        dniReferrerTypeId: item.dropdown_1,
        oldPhoneText: item.inputMask_1,
        fromDniFormatId: item.dropdown_phone_format_1,
        newPhoneText: item.inputMask_2,
        toDniFormatId: item.dropdown_phone_format_2,
        explicitReplace: item.explicit_replace,
        urlCustomParameter: item.url_param,
        dniCacheTypeId: item.dropdown_cache,
        createdAt: item.createdAt,
        updatedAt: new Date().toUTCString()
      }));

      return [...models, ...mappedModels];
    }
  };

  const handleReffererTypeAdd = (res) => {
    setRefferers((prev) => [...prev].concat(res));
  }


  const handleItemChange = (items, deletedItems) => {
    // Update the state variable with the updated items
    if (deletedItems.length !== 0) {
      const id = deletedItems[0].id;
      deleteDniReferrers(id);
    }
    setItems(items);
  };

  const deleteDniReferrers = (id) => {
    clearError();
    if (id === 0)
      return;
    ajaxCaller("DniReferrer/" + id, {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "delete"
    }).then((response) => {
      if (response.isError) {
        console.log(response);
        setErrorMessage(response.message);
      } else {
      }
    });
  }

  const clearCache = (item) => {
    return new Promise((resolve, reject) => {
      clearMessage();
      ajaxCaller("DniActiveCache/" + item.id, {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "delete",
      }).then((response) => {
        if (response.isError) {
          console.log(response);
        } else {
          setPromptMessage('Opration Clear Cache successful!');
          resolve();
        }
      })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const updateDniReferrers = () => {
    return new Promise((resolve, reject) => {
      clearMessage();
      clearError();
      updateDniUrl();
      var models = mapDniReferrerModel(items, [], "toAPI")
      ajaxCaller("DniReferrer/Collection/" + dniUrlId, {
        headers: new Headers({
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        method: "post",
        body: JSON.stringify(models),
      }).then((response) => {
        if (response.isError) {
          console.log(response);
          setErrorMessage(response.message);
        } else {
          window.location.reload();
        }
      }).then(
        ()=>{    
          setPromptMessage('Opration Save successful!');
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };



  async function handleSaveClick() {
    try {
      await updateDniUrl();
      await updateDniReferrers();
      setPromptMessage('Opration Save successful!')
      //window.location = "/settings/kontakDni";

      

    } catch (error) {
      console.error(error);
      // Handle any error that occurred during the execution
    }
  };

  return (
    <div className="section sec-move-left">
      <div className="section py-5 px-lg-2">
        <div className="section px-1">
          <div className="container-fluid z-bigger-10">
            <div className="row mt-3 mt-lg-4">
              <div className="col-auto align-self-center">
                <a
                  href="/settings/kontakDni"
                  className="mb-0 font-weight-500 animsition-link link-back-img"
                >
                  <img
                    src={require("../img/left-arrow.svg")}
                    alt=""
                    className="mr-1"
                  />
                  back
                </a>
              </div>
              <div className="col-auto align-self-center">
                <h5 className="mb-0">Edit DNI</h5>
              </div>
              <div className="container-fluid z-bigger-10">
                <div className="col-12">
                  <div className="section background-white p-2 p-lg-2">
                    <div className="row">
                      <div className="col-sm-2 col-md-3 mb-2 mb-md-0 align-self-center pr-md-0">
                        <div className="form-group">
                          <input
                            type="text"
                            value={dniUrlName}
                            className="form-style border-0 small-40"
                            onChange={(e) => setUrlName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-3 mb-2 mb-md-0 align-self-center pr-md-0">
                        <div className="form-group">
                          <input
                            type="text"
                            value={dniUrl}
                            className="form-style border-0 small-40"
                            onChange={(e) => setUrl(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {(isKontakAdmin() || PrivilegeChecker.hasPrivilege(Privileges.ViewEditClientValue)) && (<>
                <RepeaterComponent referrerTypes={referrerTypes} formats={formats} caches={caches} items={items} handleItemChange={handleItemChange} handleReffererTypeAdd={handleReffererTypeAdd} handleClearCache={handleClearCache} />
              </>)}
            </div>
          </div>
          <hr className="border-0" style={separatorStyle}></hr>
          {(isKontakAdmin() || PrivilegeChecker.hasPrivilege(Privileges.ViewEditClientValue)) && (<>
            <div className="row mt-4 mb-1">
              <div className="col-6 ml-4">
                <span
                  onClick={() => handleSaveClick()}
                  className="btn btn-blue btn-width-250 btn-height-36 border-1 font-size-14"
                >
                  Save
                </span>
              </div>
            </div>
          </>)}
        </div>
      </div>
      <ErrorModal errorMessage={errorMessage} clearError={clearError} />
      <div className="modal fade"
        id="modalError"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalError"
        aria-hidden="true">
      </div>

      <MessageModal promptMessage={promptMessage} clearMessage={clearMessage} />
      <div className="modal fade"
        id="modalMessage"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalMessage"
        aria-hidden="true">
      </div>
    </div >
  );
};

export default EditDni;
