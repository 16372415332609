import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ajaxCaller } from "../../ajaxCaller";
import { getAccessToken } from "../../authentification";
import { CSVLink } from "react-csv";
import { PrivilegeChecker, Privileges } from "../../privilegeChecker";
import _ from "lodash"


function cleanNumber(value) {
  if (value === "NaN" || _.isNaN(value)) {
    return 0
  } else {
    return value
  }
}

function findLineByLeastSquares(values_x, values_y) {
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let count = 0;

  /*
   * We'll use those variables for faster read/write access.
   */
  let x = 0;
  let y = 0;
  let values_length = values_x.length;

  if (values_length !== values_y.length) {
    throw new Error(
      "The parameters values_x and values_y need to have same size!"
    );
  }

  /*
   * Nothing to do.
   */
  if (values_length === 0) {
    return [[], []];
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = values_y[v];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  let m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  let b = sum_y / count - (m * sum_x) / count;

  /*
   * We will make the x and y result line now
   */
  let result_values_x = [];
  let result_values_y = [];

  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = x * m + b;
    result_values_x.push(x);
    result_values_y.push(y);
  }

  return [result_values_x, result_values_y];
}

const calculateDifference = (minuend, subtahend, reportType) => {
  let differenceData;
  //flip/reverse for some reports where the Total is NOT the main result/series but additional one
    if ([7].includes(reportType)) {
      differenceData = subtahend.map((point, index) => [
        point[0], // Assuming the first element in the data arrays is the period
        point[1] - (minuend[index] ? minuend[index][1] : 0), // Calculating the difference
      ]);
    }//for all others just this
    else {
      differenceData = minuend.map((point, index) => [
        point[0], // Assuming the first element in the data arrays is the period
        point[1] - (subtahend[index] ? subtahend[index][1] : 0), // Calculating the difference
      ]);
    }
    return differenceData
}


const ReportChart = (props) => {


  let entityType = 'Threads'
  let valueName = 'threadsNumber'
  if ([6, 7, 8, 14, 15, 17].includes(props.reportType)) {
    entityType = 'Calls'
    valueName = 'callsNumber'
  } else if ([16, 12].includes(props.reportType)) {
    entityType = 'Calls'
    valueName = 'averageDurationsNumber'
  } else if ([9, 10, 11, 13].includes(props.reportType)) {
    entityType = 'Appointments'
    valueName = 'appointmentsNumber'
  }

  const [chartVisible, setChartVisible] = useState(false);
  const [chart, setChart] = useState(null);
  const [columnsReferences] = useState([]);
  const [data, setData] = useState(null);
  const [exportData, setExportData] = useState([{}]);
  const [showAfterHours, setShowAfterHours] = useState(props.showAfterHours);
  const [showPEPeriod, setShowPEPeriod] = useState(props.showPEPeriod);
  const [showAdSeries, setShowAdSeries] = useState(props.showAdSeries);
  const [chartRangeType, setChartRangeType] = useState(props.chartRangeType || 2);
  const [isLineChart, setIsLineChart] = useState(props.isLineChart);
  const [reportType, setReportType] = useState(props.reportType);

  useEffect(() => {
    // Simulating loading delay with setTimeout (Replace this with your actual data fetching/loading)
    setTimeout(() => {
      setChartVisible(true); // Set chart visibility to true after loading or rendering
    }, 1000); // Adjust the delay time as needed
  }, []);

  const chartPointFormatter = function () {
    if (this.series.name === "Trends") {
      let value = convertToDecimalWithComma(this.y);
      return `<b>${value}</b>`;
    }
    if (reportType === 3) {
      return `Conv: <b>${this.y}%</b><br/>`;
    }
    else
      if (reportType === 4) {
        let value = "$" + Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Value: <b>  ${value} </b><br/>`;
      }
      else if (reportType === 1) {
        let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Current Chats: <b>  ${value} </b><br/>`;
      }
      else if (reportType === 2) {
        let value = this.y.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `New Appt. Requests: <b>  ${value} </b><br/>`;
      }
      else if (reportType === 16) {
        let value = this.y.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `Minutes: <b>  ${value} </b><br/>`;
      }
      else if (reportType >= 5 && reportType <= 17) {
        let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return `${this.series.name}: <b>  ${value} </b><br/>`;
      }
  }

  const [chartOptions, setChartOptions] = useState({
    tooltip: {
      backgroundColor: "rgba(64,66,85,0.95)",
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: '15px'
      },
      borderRadius: 0,
      headerFormat: '',
      pointFormatter: chartPointFormatter,
      footerFormat: '<span style="">{point.key}</span><br/>',
    },
    chart: {
      animation:false,
      height: "130px",
      events: {
        load() {
          this.showLoading();
          setTimeout(this.hideLoading.bind(this), 1000);
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: "",
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    series: [
      {
        name: "Total",
        lineColor: isLineChart ? "#1A2430" : "rgba(136, 206, 251, 0.55)",
        color: isLineChart ? "#1A2430" : "rgba(136, 206, 251, 0.55)",
        type: isLineChart ? "areaspline" : "column",
        fillColor: "rgba(119, 181, 223, 0.1)",
        lineWidth: 2,
        borderWidth: 1,
        animation:false,
      },

    ],
  });

  const applyWithAfterHours = () => {
    setShowAfterHours(props.showAfterHours);
    const chartOptionsNew = { ...chartOptions };
    if (
      showAfterHours &&
      chartOptionsNew.series
    ) {
      if (props.withAfterHours) {
        let seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "After Hours"
        );

        if (seriesIndex === -1) {
          chartOptionsNew.tooltip = null;
          refreshChart();
          return;
        }

        if (chartOptionsNew.series[seriesIndex] != null) {
          chartOptionsNew.series[
            seriesIndex
          ].data = data.afterHoursResults.map((a) => [
            a.period,
            a[valueName],
          ]);

          chartOptionsNew.tooltip = {
            pointFormatter: function () {
              let result = "";

              if (columnsReferences[this.x] != null) {
                columnsReferences[this.x].scrollIntoView({
                  block: "nearest",
                  alignToTop: false,
                });
              }

              if (
                chartOptionsNew == null ||
                chartOptionsNew.series[seriesIndex] == null ||
                chartOptionsNew.series[seriesIndex].data == null ||
                chartOptionsNew.series[seriesIndex].data.length === 0 || seriesIndex < 0 ||
                props.withAfterHoursDiff === false
              ) {
                let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                return `a ${chartOptionsNew.series[seriesIndex].name} : <b>  ${value} </b><br/>`;
              }
              const totalValue = chartOptionsNew.series[0].data[this.x][1];
              const afterHoursValue =
                chartOptionsNew.series[seriesIndex].data[this.x][1];
              const normalHoursValue =
                parseInt(totalValue) - parseInt(afterHoursValue);
              result =
                totalValue.toFixed(2) +
                " (Total) - " +
                afterHoursValue.toFixed(2) +
                " (After Hours) = " +
                normalHoursValue.toFixed(2) +
                " (Business Hours)\n";
              return result;
            },
          };
        }
        setChartOptions(chartOptionsNew);
      } else {
        var seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "After Hours"
        );
        if (
          chartOptionsNew.series[seriesIndex] &&
          chartOptionsNew.series[seriesIndex].data
        ) {
          chartOptionsNew.series[seriesIndex].data = [];
        }
        setChartOptions(chartOptionsNew);
      }
      refreshChart();
    }
  };

  const applyWithTrendLine = () => {
    const chartOptionsNew = { ...chartOptions };
    if (chartOptionsNew.series) {
      if (props.withTrendLine) {
        var seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (seriesIndex === -1) {
          refreshChart();
          return;
        }
        seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (chartOptionsNew.series[seriesIndex] != null) {
          const formattedResponse = data.results.map((a) => [
            a.period,
            a[valueName],
          ]);
          const xPoints = formattedResponse.map((value, index) => index);
          const yPoints = formattedResponse.map((a) => a[1]);
          const trendLineYPoints = findLineByLeastSquares(xPoints, yPoints)[1];
          if (chartOptionsNew.series[seriesIndex] != null) {
            chartOptionsNew.series[
              seriesIndex
            ].data = trendLineYPoints.map((value, index) => [
              xPoints[index],
              parseFloat(value.toFixed(2)),
            ]);
          }
        }
        setChartOptions(chartOptionsNew);
      } else {
        seriesIndex = chartOptionsNew.series.findIndex(
          (x) => x.name === "Trends"
        );
        if (
          chartOptionsNew.series[seriesIndex] &&
          chartOptionsNew.series[seriesIndex].data
        ) {
          chartOptionsNew.series[seriesIndex].data = [];
          setChartOptions(chartOptionsNew);
        }
      }
      refreshChart();
    }
  };

  const applyshowPEPeriod = () => {
    const chartOptionsNew = { ...chartOptions };
    if (props && chartOptionsNew.series && showPEPeriod !== props.showPEPeriod) {
      setChartOptions(chartOptionsNew);
      setShowPEPeriod(!showPEPeriod)
      refreshChart();
    }
  };

  const applyshowAdSeries = () => {
    const chartOptionsNew = { ...chartOptions };
    if (props && chartOptionsNew.series && showAdSeries !== props.showAdSeries) {
      setChartOptions(chartOptionsNew);
      setShowAdSeries(!showAdSeries)
      refreshChart();
    }
  };

  const showPEPeriodCallback = () => {
    applyshowPEPeriod();
  };
  useEffect(showPEPeriodCallback, [props.showPEPeriod]);

  const showAdSeriesCallback = () => {
    applyshowAdSeries();
  };
  useEffect(showAdSeriesCallback, [props.showAdSeries]);

  const afterHoursCallback = () => {
    applyWithAfterHours();
  };
  useEffect(afterHoursCallback, [props.withAfterHours, props.withAfterHoursDiff]);

  const trendLineCallback = () => {
    applyWithTrendLine();
  };
  useEffect(trendLineCallback, [props.withTrendLine]);




  const convertToInteger = (value) => {
    if (!value)
      return 0
    else
      return Math.round(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }


  const convertToDecimalWithComma = (value) => {
    if (!value)
      return 0
    else
      return value.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }


  const removeClassName = (element, classNameToRemove) => {
    if (element) {
      const classNames = element.className.split(' ');
      const filteredClassNames = classNames.filter((className) => className !== classNameToRemove);
      element.className = filteredClassNames.join(' ');
    }
  };

  const refreshChart = () => {
    chart && chart.showLoading();
    setReportType(props.reportType);
    const chartOptionsNew = {
      tooltip: {
        backgroundColor: "rgba(64,66,85,0.95)",
        borderWidth: 1,
        shadow: true,
        useHTML: true,
        style: {
          padding: "15px",
        },
        borderRadius: 15,
        headerFormat: "",
        footerFormat: '<span style="">{point.key}</span><br/>',
      },
      chart: {
        animation:false,
        height: "320px",
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          viewDistance: 25,
          depth: 40
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      loading: {
        hideDuration: 1000,
        showDuration: 1000,
      },
      xAxis: {
        tickInterval: 1,
        labels: {
          enabled: true,
          formatter: (obj) => {
            if (
              chartOptionsNew == null ||
              chartOptionsNew == null ||
              chartOptionsNew.series == null ||
              chartOptionsNew.series[0] == null ||
              chartOptionsNew.series[0].data[obj.value] == null ||
              chartOptionsNew.series[0].data[obj.value][0] == null
            ) {
              return "";
            } else {
              return chartOptionsNew.series[0].data[obj.value][0];
            }
          },
        },
      },
      yAxis: {
        title: "",
        labels: {
          enabled: true,
          formatter: (obj) => {
            if (props.reportType === 3) {
              return obj.value + "%"
            }
            else if (props.reportType === 4) {
              return "$" + convertToInteger(obj.value);
            }
            else {
              return convertToInteger(obj.value);
            }
          }
        },
        endOnTick: true,
        maxPadding: 0,
        max: null,
        tickInterval: 1,
        minorTickLength: 0,
        tickLength: 0,
        visible: true,
      },
      plotOptions: {
        column: {
          stacking: null,
          grouping: true,
          pointWidth: 25, // Set a fixed width for the columns in pixels
          pointPadding: 0, // Increase or decrease the padding between columns
          groupPadding: 0.05 // Set the padding between groups of columns
        },
        series: {
          animation:false,
          marker: {
            radius: 3,
            enabled: true,
            symbol: "circle"
          },
        },
      },
      series: [
        {
          name: props.reportType === 3
            ? "Conv"
            : props.reportType === 4
              ? "Value"
              : props.reportType === 1
                ? "Current Chats"
                : props.chartName,
          lineColor: isLineChart ? "#4C95E3" : "#3495E6",//"#1A2430" : "#3495E6",
          color: isLineChart ? "#4C95E3" : "#3495E6",
          type: isLineChart ? "areaspline" : "column",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(76, 149, 227, 0.33)'], // Start color at 100% opacity
              [1, 'rgba(76, 149, 227, 0.02)']  // End color at 0% opacity
            ]
          },
          lineWidth: 1,
          borderWidth: 0,
          tooltip: {
            backgroundColor: "rgba(64,66,85,0.95)",
            borderWidth: 1,
            shadow: true,
            useHTML: true,
            style: {
              padding: "15px",
            },
            borderRadius: 15,
            headerFormat: "",
            pointFormatter: function () {
              if (this.series.name === "Trends") {
                let value = convertToDecimalWithComma(this.y);
                return `<b>${value}</b>`;
              }
              if (props.reportType === 3) {
                return `Conv: <b>${this.y}%</b><br/>`;
              }
              else
                if (props.reportType === 4) {
                  let value = "$" + Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  return `Value: <b>  ${value} </b><br/>`;
                }
                else if (props.reportType === 1) {
                  let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  return `Current Chats: <b>  ${value} </b><br/>`;
                }
                else if (props.reportType === 2) {
                  let value = this.y.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  return `New Appt. Requests: <b>  ${value} </b><br/>`;
                }
                else if (props.reportType === 16) {
                  let value = this.y.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  return `Minutes: <b>  ${value} </b><br/>`;
                }
                else if (props.reportType >= 5 && props.reportType <= 17) {
                  let value = Math.round(this.y).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                  return `${this.series.name}: <b>  ${value} </b><br/>`;
                }
            },
            footerFormat: '<span style="">{point.key}</span><br/>',
          },
          point: {
            events: {
              mouseOver: function () {
                const columnElement = document.getElementById(`column-${this.x}`);
                if (columnElement) {
                  columnElement.className += ' selected'; // Apply 'highlighted' class to the column
                }
                const headerElement = document.getElementById(`column-header-${this.x}`);
                if (headerElement) {
                  headerElement.className += ' selected'; // Apply 'highlighted' class to the column
                }
              },
              mouseOut: function () {
                const columnElement = document.getElementById(`column-${this.x}`);
                if (columnElement) {
                  removeClassName(columnElement, 'selected');
                }

                const headerElement = document.getElementById(`column-header-${this.x}`);
                if (headerElement) {
                  removeClassName(headerElement, 'selected');
                }
              }
            }
          }
        },
      ],
    };


    if (props.additionalSeries && props.additionalSeries.length > 0 && props.showAdSeries) {
      const arrSeries = props.additionalSeries.split(/,\s+/).map(item => item.trim());
      var i = 0;
      arrSeries.forEach((item) => {
        i++;

        var lineCol = i === 1 ? "#E88C1E" : "#04BD81"
        var barCol = "rgba(136, 206, 251, 0.45)"
        var fillColor = i === 1 ?
          {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(232, 140, 30, 0.33)'], // Start color at 100% opacity
              [1, 'rgba(232, 140, 30, 0.02)']  // End color at 0% opacity
            ]
          }
          :
          {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(4, 189, 129, 0.33)'], // Start color at 100% opacity
              [1, 'rgba(4, 189, 129, 0.02)']  // End color at 0% opacity
            ]
          }
        chartOptionsNew.series.push({
          index: `series0${i}`,
          name: item,
          lineColor: isLineChart ? lineCol : barCol,
          color: isLineChart ? lineCol : barCol,
          type: isLineChart ? "areaspline" : "column",
          fillColor: fillColor,//barCol, //"rgba(136, 206, 251, 0.25)",
          lineWidth: 1,
          borderWidth: 0,
          marker: {
            radius: 4,
            enabled: true,
            symbol: "diamond"
          },
          tooltip: {
            backgroundColor: "rgba(64,66,85,0.95)",
            borderWidth: 1,
            shadow: true,
            useHTML: true,
            style: {
              padding: "15px",
            },
            borderRadius: 15,
            headerFormat: "",
            pointFormatter: chartPointFormatter,
            footerFormat: '<span style="">{point.key}</span><br/>',
          },
          point: {
            events: {
              mouseOver: function () {
                const columnElement = document.getElementById(`column-${this.x}`);
                if (columnElement) {
                  columnElement.className += ' selected'; // Apply 'highlighted' class to the column
                }
                const headerElement = document.getElementById(`column-header-${this.x}`);
                if (headerElement) {
                  headerElement.className += ' selected'; // Apply 'highlighted' class to the column
                }
              },
              mouseOut: function () {
                const columnElement = document.getElementById(`column-${this.x}`);
                if (columnElement) {
                  removeClassName(columnElement, 'selected');
                }
                const headerElement = document.getElementById(`column-header-${this.x}`);
                if (headerElement) {
                  removeClassName(headerElement, 'selected');
                }
              }
            }
          }
        });
      });
    }

    let enableAh = props.reportType === 1 ? true : props.showAfterHours && props.withAfterHours;
    if (enableAh) {

      chartOptionsNew.series.push({
        name: "After Hours",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgba(177, 179, 201, 0.45)'], // Start color at 100% opacity
            [1, 'rgba(177, 179, 201, 0)']  // End color at 0% opacity
          ]
        },
        lineColor: "#B1B3C9",
        color: "#B1B3C9",
        lineWidth: 1,
        borderWidth: 1,
        type: isLineChart ? "areaspline" : "column",
        marker: { enabled: true },
      });

    }

    let trendLineSeriesIndex = 1;
    if (props.withTrendLine) {
      chartOptionsNew.series.push({
        name: "Trends",
        lineColor: "rgba(249, 25, 25, 1)",
        color: "rgba(249, 25, 25, 1)",
        type: "line",
        lineWidth: 2,
        borderWidth: 1,
        marker: { enabled: false },
      });

      trendLineSeriesIndex = chartOptionsNew.series.findIndex(
        (x) => x.name === "Trends"
      );
    }

    ajaxCaller(`Dashboard/Get${entityType}Statistic`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      body: JSON.stringify({
        type: chartRangeType,
        dateFrom: props.dateRange.dateFrom,
        dateTo: props.dateRange.dateTo,
        isLeads: props.isLeads,
        reportType: props.reportType,
        withAfterHours: enableAh,
        showPEPeriod: props.showPEPeriod,
        groupIds: props.groupIds,
      }),
    }).then((response) => {
      if (response.results != null) {
        const formattedResponse = response.results.map((a) => [
          a.period,
          a[valueName],
        ]);
        chartOptionsNew.series[0].data = formattedResponse;


        let formattedResponse01;
        let series1Index = chartOptionsNew.series.findIndex(
          (x) => x.index === "series01"
        );
        if (series1Index > 0 && props.showAdSeries) {
          if (response.results01) {
            formattedResponse01 = response.results01.map((a) => [
              a.period,
              a[valueName],
            ]);
            chartOptionsNew.series[series1Index].data = formattedResponse01;
          }
        }

        let formattedResponse02;
        let series2Index = chartOptionsNew.series.findIndex(
          (x) => x.index === "series02"
        );
        if (series2Index > 0 && props.showAdSeries) {
          if (response.results02) {
            formattedResponse02 = response.results02.map((a) => [
              a.period,
              a[valueName],
            ]);
            chartOptionsNew.series[series2Index].data = formattedResponse02;
          }
          else {
            if (formattedResponse01 && formattedResponse) {
              const differenceData = calculateDifference(formattedResponse, formattedResponse01, props.reportType)
              chartOptionsNew.series[series2Index].data = differenceData;
            }
          }
        }
        //AfterHours filter by field
        if (props.withAfterHours && props.showAfterHours) {
          let seriesIndex = chartOptionsNew.series.findIndex(
            (x) => x.name === "After Hours"
          );
          if (chartOptionsNew.series[seriesIndex] != null) {
            chartOptionsNew.series[seriesIndex].data = response.afterHoursResults.map(
              (a) => [a.period, a[valueName]]
            );
          }
        }
        // Calculating Line Trend
        if (props.withTrendLine) {
          const xPoints = formattedResponse.map((value, index) => index);
          const yPoints = formattedResponse.map((a) => a[1]);
          const trendLineYPoints = findLineByLeastSquares(
            xPoints,
            yPoints
          )[1];
          if (chartOptionsNew.series[trendLineSeriesIndex] != null) {
            chartOptionsNew.series[
              trendLineSeriesIndex
            ].data = trendLineYPoints.map((value, index) => [
              xPoints[index],
              parseFloat(value.toFixed(2)),
            ]);
          }
        }
        setData(response);
        setExportData(getExportData(response));
        setChartOptions(chartOptionsNew);
        chart && chart.hideLoading();
      }
    });
  };

  const hoverColumn = (columnIndex) => {
    chart.tooltip.refresh(chart.series[0].data[columnIndex]);
  };

  const afterChartCreated = (chart) => {
    setChart(chart);
  };

  //Need to implement this as there were multiple calls to the refreshChart hookCallback for no reason
  const prevReportType = useRef(null);
  const prevDateRange = useRef(null);
  const prevGroupIds = useRef(null);
  const prevChartRangeType = useRef(null);
  const prevIsLineChart = useRef(null);
  const prevIsLineChartProp = useRef(null);

  const hookCallback = () => {
    //complex object comparison method, it doesn't work with !== ,=== even though values and the structure are the same
    const areGroupIdsEqual = JSON.stringify(props.groupIds) === JSON.stringify(prevGroupIds.current);

    // we don't want callback refreshChart more than once as it sends the request to the API mutliple times (2,3)
    if (
      props.reportType !== prevReportType.current ||
      props.dateRange !== prevDateRange.current ||
      !areGroupIdsEqual ||
      chartRangeType !== prevChartRangeType.current ||
      isLineChart !== prevIsLineChart.current ||
      props.isLineChart !== prevIsLineChartProp.current
    ) {
      refreshChart();
    }
    prevReportType.current = props.reportType;
    prevDateRange.current = props.dateRange;
    prevGroupIds.current = props.groupIds;
    prevChartRangeType.current = chartRangeType;
    prevIsLineChart.current = isLineChart;
    prevIsLineChartProp.current = props.isLineChart;
  };

  useEffect(hookCallback, [
    chartRangeType,
    isLineChart,
    props.isLineChart,
    props.reportType,
    props.dateRange,
    props.groupIds
  ]);


  // Set table rows in the separate function to maintain readability
  const setTableRows = () => {

    const renderContentBasedOnReportType = () => {

      if (![1, 6, 7, 8, 13, 16, 17].includes(props.reportType)) {
        return (
          <div className="white-box-table">
            <p className="mb-0 font-weight-500 font-size-14">
              % Change
            </p>
          </div>
        );
      }

      if (props.reportType === 1 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine)) {
        return (
          <>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                After-Hours Chats
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Cumulative AH Chats
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                AH Chats as % of All Chats
              </p>
            </div>
          </>
        );
      }
      if ([6].includes(props.reportType)) {
        if (props.withAfterHours && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine)) {
          return (
            <>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  New Prospect Call
                </p>
              </div>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  Existing Client Call
                </p>
              </div>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  Open Hours
                </p>
              </div>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  After Hours
                </p>
              </div>
            </>
          )
        }
        else {
          return (
            <>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  New Prospect Call
                </p>
              </div>
              <div className="white-box-table">
                <p className="mb-0 font-weight-500 font-size-14">
                  Existing Client Call
                </p>
              </div>
            </>
          )
        }
      }
      if ([7].includes(props.reportType)) {
        return (
          <>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Total Calls
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Answered Calls
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Missed Calls
              </p>
            </div>
          </>
        );
      }
      if ([8].includes(props.reportType)) {
        return (
          <>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Total Calls
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Answered Calls by VM
              </p>
            </div>
          </>
        );
      }
      if ([13].includes(props.reportType)) {
        return (
          <>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                New Client Calls to Apts
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Existing Client Calls to Apts
              </p>
            </div>
          </>
        );
      }
      if ([17].includes(props.reportType)) {
        return (
          <>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Missed Calls To VM
              </p>
            </div>
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Abandoned Calls
              </p>
            </div>
          </>
        );
      }
      return null; // Return null if no condition is met
    };

    //Main Rows
    const content = (
      <div className="col-5 col-md-3 col-lg-2 pr-0">
        <div className="section over-p">
          <div className="blue-box-table">
            <p className="mb-0 font-weight-500 font-size-14"></p>
          </div>
          {[7, 8].includes(props.reportType) ? null : (
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                {props.reportType === 3
                  ? "Conv"
                  : props.reportType === 4
                    ? "Value"
                    : props.reportType === 1
                      ? "Current Chats"
                      : props.chartName
                }
              </p>
            </div>
          )}
          {[3, 6, 7, 8, 13, 16, 17].includes(props.reportType) ? null : (
            <div className="white-box-table">
              <p className="mb-0 font-weight-500 font-size-14">
                Cumulative{" "}
                {props.reportType === 1
                  ? "Chats"
                  : props.reportType === 2
                    ? "Leads"
                    : props.reportType === 4
                      ? "Value"
                      : ""
                }
              </p>
            </div>
          )}
          {renderContentBasedOnReportType()}
        </div>
      </div>
    );
    return content;
  }
  // Set table rows DATA in the separate function to maintain readability
  const setTableRowsData = () => {
    let content = (
      <div className="col-7 col-md-9 col-lg-10 pl-0">
        <div className="section section-table-scroll-wrapper">
          <div className="section section-table-scroll-wrap" style={{ overflowX: "auto" }}>
            {data &&
              data.results.map((result, index) => {
                cumData += result[valueName];
                if (props.reportType === 1 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && data.afterHoursResults && data.afterHoursResults.length > 0) {
                  cumDataAfterHours += data.afterHoursResults[index][valueName];
                }
                return (
                  <div key={result.period}
                    id={`column-${index}`}
                    className={`section max-140 tab-reports-col`}
                    onMouseEnter={() => {
                      hoverColumn(index);
                    }}
                  >
                    <div
                      id={`column-header-${index}`}
                      className={`blue-box-table box-center-text`}
                    >
                      <p className="mb-0 font-weight-500 font-size-14 date-top">
                        {result.period}
                      </p>
                    </div>
                    {props.reportType === 1 ? (<>
                      <div className="white-box-table box-center-text">
                        <p className="mb-0 font-size-13">
                          {cleanNumber(convertToInteger(result[valueName]))}
                        </p>
                      </div>
                      <div className="white-box-table box-center-text">
                        <p className="mb-0 font-size-13 ">
                          {cleanNumber(convertToInteger(cumData))}
                        </p>
                      </div>
                      {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                        (<div className="white-box-table box-center-text">
                          <p className="mb-0 font-size-13">
                            {cleanNumber(convertToInteger(data.afterHoursResults[index][valueName]))}
                          </p>
                        </div>)}
                      <div className="white-box-table box-center-text">
                        <p className="mb-0 font-size-13">
                          {cleanNumber(convertToInteger(cumDataAfterHours))}
                        </p>
                      </div>
                      {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                        (<div className="white-box-table box-center-text">
                          <p className="mb-0 font-size-13">
                            {cleanNumber(((data.afterHoursResults[index][valueName] * 100) / result[valueName]).toFixed())}%
                          </p>
                        </div>)}
                    </>
                    ) :
                      props.reportType === 2 ? (<>
                        <div className="white-box-table box-center-text">
                          <p className="mb-0 font-size-13">
                            {cleanNumber(convertToInteger(result[valueName]))}
                          </p>
                        </div>
                        <div className="white-box-table box-center-text">
                          <p className="mb-0 font-size-13 ">
                            {cleanNumber(convertToInteger(cumData))}
                          </p>
                        </div>
                        <div className="white-box-table box-center-text">
                          <p
                            className={result.difference < 0
                              ? "mb-0 font-size-13 color-red"
                              : "mb-0 font-size-13 color-green"}
                          >
                            {isNaN(result.difference) ? 0 : result.difference}%
                          </p>
                        </div>
                      </>
                      ) :
                        props.reportType === 3 ? (<>
                          <div className="white-box-table box-center-text">
                            <p className="mb-0 font-size-13">
                              {result[valueName] + "%"}
                            </p>
                          </div>
                          <div className="white-box-table box-center-text">
                            <p
                              className={result.differencePercentage < 0
                                ? "mb-0 font-size-13 color-red"
                                : "mb-0 font-size-13 color-green"}
                            >
                              {cleanNumber(result.difference)}%
                            </p>
                          </div>
                        </>
                        ) :
                          props.reportType === 4 ? (<>
                            <div className="white-box-table box-center-text">
                              <p className="mb-0 font-size-13">
                                {"$" + convertToDecimalWithComma(result[valueName])}
                              </p>
                            </div>
                            <div className="white-box-table box-center-text">
                              <p
                                className={result.differencePercentage < 0
                                  ? "mb-0 font-size-13 color-red"
                                  : "mb-0 font-size-13 color-green"}
                              >
                                {cleanNumber(result.difference)}%
                              </p>
                            </div>
                          </>
                          ) :
                            props.reportType === 6 ?
                              (
                                <>
                                  <div className="white-box-table box-center-text">
                                    <p className="mb-0 font-size-13">
                                      {cleanNumber(convertToInteger(result[valueName]))}
                                    </p>
                                  </div>
                                  {data.results01 && data.results01.length > 0 && (
                                    <div className="white-box-table box-center-text">
                                      <p className="mb-0 font-size-13">
                                        {cleanNumber(data.results01[index][valueName])}
                                      </p>
                                    </div>
                                  )}
                                  {data.results01 && data.results01.length > 0 && (
                                    <div className="white-box-table box-center-text">
                                      <p className="mb-0 font-size-13">
                                        {cleanNumber((result[valueName] - data.results01[index][valueName]).toFixed())}
                                      </p>
                                    </div>
                                  )}
                                  {props.withAfterHours && data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && (<div className="white-box-table box-center-text">
                                    <p className="mb-0 font-size-13">
                                      {cleanNumber((result[valueName] - data.afterHoursResults[index][valueName]).toFixed())}
                                    </p>
                                  </div>)}
                                  {props.withAfterHours && data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                                    (<div className="white-box-table box-center-text">
                                      <p className="mb-0 font-size-13">
                                        {cleanNumber(convertToInteger(data.afterHoursResults[index][valueName]))}
                                      </p>
                                    </div>)}
                                </>)
                              :
                              props.reportType === 7 ?
                                (
                                  <>
                                    {data.results01 && data.results01.length > 0 && (
                                      <div className="white-box-table box-center-text">
                                        <p className="mb-0 font-size-13">
                                          {cleanNumber(convertToInteger(data.results01[index][valueName]))}
                                        </p>
                                      </div>
                                    )}
                                    <div className="white-box-table box-center-text">
                                      <p className="mb-0 font-size-13 color-blue">
                                        {cleanNumber(convertToInteger(result[valueName]))}
                                      </p>
                                    </div>

                                    {data.results01 && data.results01.length > 0 && (
                                      <div className="white-box-table box-center-text">
                                        <p className="mb-0 font-size-13">
                                          {cleanNumber((data.results01[index][valueName] - result[valueName]).toFixed())}
                                        </p>
                                      </div>
                                    )}
                                    {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                                      (<div className="white-box-table box-center-text">
                                        <p className="mb-0 font-size-13">
                                          {cleanNumber((result[valueName] - data.afterHoursResults[index][valueName]).toFixed())}
                                        </p>
                                      </div>)}
                                    {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                                      (<div className="white-box-table box-center-text">
                                        <p className="mb-0 font-size-13">
                                          {cleanNumber(convertToInteger(data.afterHoursResults[index][valueName]))}
                                        </p>
                                      </div>)}
                                  </>)
                                :
                                props.reportType === 8 ? (
                                  <>
                                    {data.results01 && data.results01.length > 0 ? (
                                      <>
                                        <div className="white-box-table box-center-text">
                                          <p className="mb-0 font-size-13">
                                            {cleanNumber((data.results01[index][valueName] - result[valueName]).toFixed())}
                                          </p>
                                        </div>
                                        <div className="white-box-table box-center-text">
                                          <p className="mb-0 font-size-13">
                                            {cleanNumber(convertToInteger(result[valueName]))}
                                          </p>
                                        </div>
                                      </>
                                    ) :
                                      (
                                        <>
                                          <div className="white-box-table box-center-text">
                                            <p className="mb-0 font-size-13">
                                              0
                                            </p>
                                          </div>
                                          <div className="white-box-table box-center-text">
                                            <p className="mb-0 font-size-13">
                                              {cleanNumber(convertToInteger(result[valueName]))}
                                            </p>
                                          </div>
                                        </>)
                                    }
                                  </>
                                ) :
                                  props.reportType === 13 ? (
                                    <>
                                      <div className="white-box-table box-center-text">
                                        <p className="mb-0 font-size-13">
                                          {cleanNumber(convertToInteger(result[valueName]))}
                                        </p>
                                      </div>
                                      {data.results01 && data.results01.length > 0 && (
                                        <>
                                          <div className="white-box-table box-center-text">
                                            <p className="mb-0 font-size-13">
                                              {cleanNumber(convertToInteger(data.results01[index][valueName]))}
                                            </p>
                                          </div>
                                          <div className="white-box-table box-center-text">
                                            <p className="mb-0 font-size-13">
                                              {cleanNumber((result[valueName] - data.results01[index][valueName]).toFixed())}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) :
                                    props.reportType === 16 ? (
                                      <>
                                        <div className="white-box-table box-center-text">
                                          <p className="mb-0 font-size-13">
                                            {cleanNumber(convertToDecimalWithComma(result[valueName]))}
                                          </p>
                                        </div>
                                      </>
                                    ) :
                                      props.reportType === 17 ?
                                        (
                                          <>
                                            <div className="white-box-table box-center-text">
                                              <p className="mb-0 font-size-13">
                                                {cleanNumber(convertToInteger(result[valueName]))}
                                              </p>
                                            </div>
                                            {data.results01 && data.results01.length > 0 && (
                                              <div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13">
                                                  {cleanNumber(convertToInteger(data.results01[index][valueName]))}
                                                </p>
                                              </div>
                                            )}
                                            {data.results01 && data.results01.length > 0 && (
                                              <div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13">
                                                  {cleanNumber((result[valueName] - data.results01[index][valueName]).toFixed())}
                                                </p>
                                              </div>
                                            )}
                                            {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                                              (<div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13">
                                                  {cleanNumber((result[valueName] - data.afterHoursResults[index][valueName]).toFixed())}
                                                </p>
                                              </div>)}
                                            {data.afterHoursResults && data.afterHoursResults.length > 0 && PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) &&
                                              (<div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13">
                                                  {cleanNumber(convertToInteger(data.afterHoursResults[index][valueName]))}
                                                </p>
                                              </div>)}
                                          </>) :
                                        [2, 4, 5, 9, 10, 11, 12, 14, 15].includes(props.reportType) ?
                                          (
                                            <>
                                              <div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13">
                                                  {props.reportType === 3 ? result[valueName] + "%" :
                                                    props.reportType === 4 ? "$" + convertToDecimalWithComma(result[valueName]) :
                                                      props.reportType === 16 ? convertToDecimalWithComma(result[valueName]) :
                                                        cleanNumber(convertToInteger(result[valueName]))}
                                                </p>
                                              </div>
                                              <div className="white-box-table box-center-text">
                                                <p className="mb-0 font-size-13 ">
                                                  {props.reportType === 4 ? ("$" + cleanNumber(convertToInteger(cumData.toString()))) : cleanNumber(convertToInteger(cumData))}
                                                </p>
                                              </div>
                                              <div className="white-box-table box-center-text">
                                                <p
                                                  className={result.difference < 0
                                                    ? "mb-0 font-size-13 color-red"
                                                    : "mb-0 font-size-13 color-green"}
                                                >
                                                  {isNaN(result.difference) ? 0 : result.difference}%
                                                </p>
                                              </div>
                                            </>
                                          ) : (<></>)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>);
    return content;
  }

  const getExportData = (data) => {
    let cumData = 0;
    let cumDataAfterHours = 0;
    if (props.reportType === 1) {
      return data.results.map((result, index) => {
        cumData += result[valueName];

        if (PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && data.afterHoursResults && data.afterHoursResults.length > 0) {
          cumDataAfterHours += data.afterHoursResults[index][valueName];
        }

        if (PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && data.afterHoursResults && data.afterHoursResults.length > 0) {
          return {
            date: result.period,
            total: result[valueName],
            cumData: cumData,
            afterHours: data.afterHoursResults[index][valueName],
            cumDataAfterHours: cumDataAfterHours,
            percentageAhToTotalChats: ((data.afterHoursResults[index][valueName] * 100) / result[valueName]).toFixed()
          };
        }
        else {
          return {
            date: result.period,
            total: result[valueName],
            cumData: cumData
          };
        }
      })
    }
    else if (props.reportType === 3) {
      return data.results.map((result) => {
        return {
          date: result.period,
          conv: result[valueName] + "%",
          change: result.differencePercentage + "%",
        };
      });
    }
    else if (props.reportType === 4) {
      return data.results.map((result) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: "$" + convertToInteger(result[valueName]),
          cumData: "$" + convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 6) {
      return data.results.map((result, index) => {
        let res = {};
        if (PrivilegeChecker.hasPrivilege(Privileges.ViewAfterHoursTrendLine) && data.afterHoursResults && data.afterHoursResults.length > 0 && showAfterHours) {
          res = {
            date: result.period,
            total: convertToInteger(result[valueName]),
            new: convertToInteger(data.results01[index][valueName]),
            existing: convertToInteger(result[valueName] - data.results01[index][valueName]),
            afterHours: convertToInteger(data.afterHoursResults[index][valueName]),
            openHours: (result[valueName] - data.afterHoursResults[index][valueName]).toFixed(),
          }
        }
        else if (data && data.results01 && data.results01.length > 0) {
          res = {
            date: result.period,
            total: convertToInteger(result[valueName]),
            new: convertToInteger(data.results01[index][valueName]),
            existing: convertToInteger(result[valueName] - data.results01[index][valueName]),
          }
        }
        return res;
      });
    }
    else if (props.reportType === 7) {
      return data.results.map((result, index) => {
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          answered: convertToInteger(data.results01[index][valueName]),
          missed: convertToInteger(result[valueName] - data.results01[index][valueName]),
        };
      });
    }
    else if (props.reportType === 8) {
      return data.results.map((result, index) => {
        return {
          date: result.period,
          total: convertToInteger(result[valueName])
        };
      });
    }
    else if (props.reportType === 9) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 10) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 11) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 13) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 14) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 15) {
      return data.results.map((result, index) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          cumData: convertToInteger(cumData),
          change: result.difference + "%",
        };
      });
    }
    else if (props.reportType === 17) {
      return data.results.map((result, index) => {
        return {
          date: result.period,
          total: convertToInteger(result[valueName]),
          missed_to_vm: convertToInteger(data.results01[index][valueName]),
          abandoned: convertToInteger(result[valueName] - data.results01[index][valueName]),
        }
      });
    }
    else {
      return data.results.map((result) => {
        cumData += result[valueName];
        return {
          date: result.period,
          total: result[valueName],
          cumData: cumData,
          change: result.difference + "%",
        };
      });
    }

  };

  const getExportHeaders = () => {

    if (props.reportType === 1) {
      return [
        { label: "Date", key: "date" },
        { label: "Current Chats", key: "total" },
        { label: "Cumulative Chats", key: "cumData" },
        { label: "After-Hours Chats", key: "afterHours" },
        { label: "Cumulative AH Chats", key: "cumDataAfterHours" },
        { label: "AH Chats as % of All Chats", key: "percentageAhToTotalChats" },
      ];
    }
    else if (props.reportType === 3) {
      return [
        { label: "Date", key: "date" },
        { label: "Conv", key: "conv" },
        { label: "% Change", key: "change" },
      ];
    }
    else if (props.reportType === 6) {
      return [
        { label: "Date", key: "date" },
        { label: "Total Calls", key: "total" },
        { label: "New Prospect Calls", key: "new" },
        { label: "Existing Client Calls", key: "existing" },
        { label: "After-Hours Calls", key: "afterHours" },
        { label: "Open-Hours Calls", key: "openHours" },
      ];
    }
    else if (props.reportType === 7) {
      return [
        { label: "Date", key: "date" },
        { label: "Total Calls", key: "total" },
        { label: "Answered Calls", key: "answered" },
        { label: "Missed Calls", key: "missed" },
      ];
    }
    else if (props.reportType === 9) {
      return [
        { label: "Date", key: "date" },
        { label: "Total Appointments", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 8) {
      return [
        { label: "Date", key: "date" },
        { label: "Calls to VM", key: "total" },
      ];
    }
    else if (props.reportType === 10) {
      return [
        { label: "Date", key: "date" },
        { label: "New Client Appointments", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 11) {
      return [
        { label: "Date", key: "date" },
        { label: "Returning Client Appointments", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 13) {
      return [
        { label: "Date", key: "date" },
        { label: "Total Calls to Apts", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 14) {
      return [
        { label: "Date", key: "date" },
        { label: "New Client Calls to Apts", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 15) {
      return [
        { label: "Date", key: "date" },
        { label: "Returning Client Calls to Apts", key: "total" },
        { label: "CumData", key: "cumData" },
        { label: "Change", key: "change" },
      ]
    }
    else if (props.reportType === 17) {
      return [
        { label: "Date", key: "date" },
        { label: "Missed Calls", key: "total" },
        { label: "Missed Calls to VM", key: "missed_to_vm" },
        { label: "Abandoned Calls", key: "abandoned" },
      ];
    }
    else {
      return [
        { label: "Date", key: "date" },
        {
          label:
            props.reportType === 3
              ? "Conv"
              : props.reportType === 4
                ? "Value"
                : props.chartName,
          key: "total",
        },
        {
          label: props.reportType === 2
            ? "Cumulative Leads"
            : props.reportType === 4
              ? "Cumulative Value"
              : "",
          key: "cumData",
        },
        { label: "% Change", key: "change" },
      ];
    }
  };

  let cumData = 0;
  let cumDataAfterHours = 0;
  return (
    <>
      <div className="row mt-2 pt-1">
        <div className="col-12">
          <div className="section background-white p-3 p-lg-4">
            <div className="row d-none d-md-flex">
              <div className="col">
                <p className="font-size-13">
                  <span
                    className={isLineChart === true
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setIsLineChart(true)}
                  >
                    Line
                  </span>
                  <span
                    className={isLineChart === false
                      ? "link-line active"
                      : "link-line"}
                    onClick={() => setIsLineChart(false)}
                  >
                    Bar
                  </span>
                </p>
              </div>
              <div id={"reports-calendar"} className="col-auto">
                <p className="font-size-13">
                  <span
                    className={chartRangeType === 1
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setChartRangeType(1)}
                  >
                    Day
                  </span>
                  <span
                    className={chartRangeType === 2
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setChartRangeType(2)}
                  >
                    Week
                  </span>
                  <span
                    className={chartRangeType === 3
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setChartRangeType(3)}
                  >
                    Month
                  </span>
                  <span
                    className={chartRangeType === 4
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setChartRangeType(4)}
                  >
                    Quarter
                  </span>
                  <span
                    className={chartRangeType === 5
                      ? "link-line active mr-2"
                      : "link-line mr-2"}
                    onClick={() => setChartRangeType(5)}
                  >
                    Year
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section">
                  <figure className="highcharts-figure">
                    <div
                      id="chat-chart-reports"
                      className="chart-report-height"
                    >  {
                      chartVisible && (
                      <HighchartsReact
                        ref={React.createRef()}
                        allowChartUpdate={true}
                        highcharts={Highcharts}
                        options={chartOptions}
                        callback={afterChartCreated} />)
                      }
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><div className="row mt-3">
        <div className="col-12">
          <div className="section background-white">
            <div className="row py-2">
              <div className="col align-self-center">
                <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                  Details
                </p>
              </div>
              <div className="col-auto">
                <CSVLink
                  filename={`${props.chartName} - [${props.dateRange.dateFrom.replace(/\W+/g, "-")}] - [${props.dateRange.dateTo.replace(/\W+/g, "-")}].csv`}
                  data={exportData}
                  headers={getExportHeaders()}>
                  <span
                    href="/#"
                    className="link-download font-size-13 mr-2 pr-1"
                  >
                    <img
                      src={require("../../img/download.svg")}
                      alt=""
                      className="mr-1" />
                    Export (CSV)
                  </span>
                </CSVLink>
              </div>
            </div>
            <div className="row">
              {(setTableRows())}
              {setTableRowsData(data)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportChart;