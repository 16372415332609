import React, { useState, useEffect } from "react";
import { ajaxCaller } from "../ajaxCaller";
import { getAccessToken } from "../authentification";
import Moment from "react-moment";
import SettingsPageNavBar from "./components/settingsPageNavBar";

const Billing = () => {
  const [billings, setBillings] = useState(null);
  const getBillings = () => {
    ajaxCaller("Billing/GetAll", {
      headers: new Headers({
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
      method: "get",
    }).then((response) => {
      if (!response.isError) {
        setBillings(response);
      } else {
      }
    });
  };
  useEffect(() => {
    getBillings();
  }, []);

  return (
    <>

      <SettingsPageNavBar pageName="billing" />

      <div className="section sec-move-left">
        <div className="section py-5 px-lg-2">
          <div className="section px-1">
            <div className="container-fluid z-bigger-10">
              <div className="row mt-3 mt-lg-4">
                <div className="col-12">
                  <h5 className="mb-2 pb-1">Billing</h5>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-12 chat-archive-table">
                  <div className="section background-white">
                    <div className="row py-2">
                      <div className="col align-self-center">
                        <p className="mb-0 font-size-14 line-height-18 pl-2 ml-1">
                        </p>
                      </div>
                      <div className="col-auto">
                        <a
                          href="#"
                          className="link-download font-size-13 mr-2 pr-1"
                        >
                          <img
                            src={require("../img/download.svg")}
                            alt=""
                            className="mr-1"
                          />
                          Export (PDF)
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 d-none d-xl-inline">
                  <div className="section background-blue-light-4">
                    <div className="row">
                      <div className="col-lg-2 pr-0">
                        <div className="profile-box-blue profile-box-blue-bor-right">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Invoice #
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">Date</p>
                        </div>
                      </div>
                      <div className="col-lg-2 px-0">
                        <div className="profile-box-blue profile-box-blue-bor-right justify-content-center">
                          <p className="mb-0 font-size-14 font-weight-500">
                            Total Amount
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {billings &&
                    billings.map((billing) => (
                      <div className="section background-white row-sec-border-top pt-2 pt-xl-0">
                        <div className="row">
                          <div className="col-xl-2 pr-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right">
                              <p className="mb-0 font-size-15">{billing.number}</p>
                            </div>
                          </div>
                          <div className="col-xl-3 px-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                              <p className="mb-0 font-size-15">
                                {" "}
                                <Moment
                                  format="MMMM Do, YYYY "
                                  date={billing.date}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-2 px-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-xl-center">
                              <p className="mb-0 font-size-15">
                                USD $ {billing.totalAmount.toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-5 pl-xl-0">
                            <div className="profile-box-white profile-box-white-bor-right justify-content-xl-end">
                              <span
                                className="link-download font-size-13 mr-3"
                              >
                                <img
                                  src={require("../img/view.svg")}
                                  alt=""
                                  className="mr-1"
                                />
                                View Invoice
                              </span>
                              <span className="link-download font-size-13">
                                <img
                                  src={require("../img/download.svg")}
                                  alt=""
                                  className="mr-1"
                                />
                                Download PDF
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
